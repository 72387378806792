import React from 'react';
import { Picker, Platform, TouchableOpacity, ScrollView, View, Text } from "react-native";
import Menu, { MenuItem, MenuDivider } from 'react-native-material-menu';

export default class DropdownXP extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            width: 120,
            opened: false
        }
    }

    setMenuRef = ref => {
        this._menu = ref;
    };

    measureall = () => {
        this.btn.measure((ox, oy, width, height, px, py) => {
            this.setState({ width });
        });
    }
    render() {
        return <View style={{ flexDirection: 'column', alignItems: 'stretch', alignSelf: 'stretch' }}
            ref={(c) => { this.btn = c; }} onLayout={this.measureall}>
            {Platform.OS == 'web' ?
                <Picker
                    selectedValue={this.props.selectedValue}
                    style={{ fontFamily: null, ...this.props.style }}
                    onValueChange={(itemValue) => this.props.onValueChange(itemValue)}>
                    {this.props.data.map(x => {
                        return <Picker.Item key={x.value} label={x.label} value={x.value} />
                    })}
                </Picker>
                :
                <Menu style={{ maxHeight: 400, minWidth: 100, width: this.state.width, alignSelf: 'stretch', flex: 1 }}
                    ref={this.setMenuRef}
                    button={
                        <View style={{ flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', ...this.props.style }}>
                            <Text onPress={() => this._menu.show()} style={{ flex: 1 }}>
                                {this.props.data.filter(z => z.value == this.props.selectedValue)[0].label}
                            </Text>
                            <Text>
                                ▼
                            </Text>
                        </View>
                    }>
                    <ScrollView showsVerticalScrollIndicator={true} style={{ width: this.state.width }}
                        contentContainerStyle={{ width: this.state.width, alignItems: 'stretch' }}
                    >
                        {this.props.data.map(x => {
                            return <MenuItem key={x.value} onPress={() => {
                                this._menu.hide();
                                this.props.onValueChange(x.value);
                            }} style={{ borderBottomWidth: 1, borderBottomColor: '#eee', width: this.state.width }}>
                                {x.label ? x.label : x.value ? x.value : x}
                            </MenuItem>
                        })}
                    </ScrollView>
                </Menu>
            }
        </View>
    }
}
import React from 'react';
import { StyleSheet, Text, View, Image, Platform, ViewPropTypes } from 'react-native';
import { Provider as PaperProvider, DefaultTheme, ActivityIndicator } from 'react-native-paper';
import { Provider } from 'react-redux'
import Products from './screens/Products';
import Home from './screens/Home';
import SelfService from './screens/SelfService';
import store from './store/store';
import Colors from './constants/Colors';
import {
  Router,
  Switch,
  Route,
  BackButton,
  Redirect,
  useHistory
} from './components/router';
import Api from './api/Api';
import * as Font from 'expo-font';
import { Analytics, Event } from 'expo-analytics';
import {
  setCustomTextInput,
  setCustomText,
} from 'react-native-global-props';
import Header from './components/Header';
import CheckoutStrip from './components/CheckoutStrip';
import { ModalCart } from "./components/ModalCart";
import { ModalProductWithGet } from './components/ModalProduct';
import Checkout from './components/Checkout';
import { setRoute } from './store/routeReducer';

const theme = {
  ...DefaultTheme,
  //roundness: 2,
  colors: {
    ...DefaultTheme.colors,
    primary: Colors.themeColor,
    accent: '#f1c40f',
  }
};

// ROUTER HISTORY HACK
const historyHack = {
  history: {}
}
export const GetHistoryHack = () => historyHack.history;
const MyHistoryHack = () => {
  let history = useHistory();
  historyHack.history = history;
  return null
}
export default class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      fontLoaded: false
    }
  }
  componentDidMount() {
    if (Api.BaseURL.indexOf('://homolog') > -1 || Api.BaseURL.indexOf('://10.0.0') > -1 || Api.BaseURL.indexOf('://192.168.') > -1) {
      // Debug
      Api.Analytics = {
        event: () => { }
      }
    } else {
      // Prod
      Api.Analytics = new Analytics('UA-9234405-15'); // ua('UA-9234405-15');
    }
    Api.Analytics.event(new Event("App", "Opened"));

    try {
      if (window && window.document) {
        // Your CSS as text
        var styles = `
        *:focus {
          outline: none;
      }
      .animateLeftPosition {
        transition: left 0.2s ease;
      }
      .animateRightPosition {
        transition: right 0.2s ease;
      }
      .myfooter {
        position: fixed; bottom: 0;
      }
      .coolScroll::-webkit-scrollbar{
        width: 5px;
        height: 5px;
      }
      .coolScroll::-webkit-scrollbar-track {
        border: 0px solid transparent;
        background-color: transparent;
        border-radius: 5px;
      }
      
      .coolScroll::-webkit-scrollbar-thumb {
        background: #fff;	
        border-radius: 6px;
        transition: background 0.25s linear;
      }
      .coolScroll:hover::-webkit-scrollbar-thumb {
        background: #ccc;	
      }
          `
        var styleSheet = document.createElement("style")
        styleSheet.type = "text/css"
        styleSheet.innerText = styles
        window.document.head.appendChild(styleSheet);

        // disable zoom on web:
        var vp = document.createElement("meta")
        vp.name = "viewport"
        vp.content = "width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=no";
        window.document.head.appendChild(vp);

        // `<!-- Facebook Pixel Code -->
        // <script>
        //   !function(f,b,e,v,n,t,s)
        //   {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
        //   n.callMethod.apply(n,arguments):n.queue.push(arguments)};
        //   if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
        //   n.queue=[];t=b.createElement(e);t.async=!0;
        //   t.src=v;s=b.getElementsByTagName(e)[0];
        //   s.parentNode.insertBefore(t,s)}(window, document,'script',
        //   'https://connect.facebook.net/en_US/fbevents.js');
        //   fbq('init', '909561212414993');
        //   fbq('track', 'PageView');
        // </script>
        // <noscript><img height="1" width="1" style="display:none"
        //   src="https://www.facebook.com/tr?id=909561212414993&ev=PageView&noscript=1"
        // /></noscript>
        // <!-- End Facebook Pixel Code -->`

      }
    } catch (e) { }

    this.preLoadFonts();
  }

  preLoadFonts = async () => {
    await Font.loadAsync({
      // 'Montserrat': require('./assets/fonts/Montserrat-Regular.ttf'),
      // 'MontserratBold': require('./assets/fonts/Montserrat-SemiBold.ttf'),
      // 'Montserrat': require('./assets/fonts/Barlow-Regular.ttf'),
      // 'MontserratBold': require('./assets/fonts/Barlow-Medium.ttf'),
      'Montserrat': require('./assets/fonts/SulSans-Light.ttf'),
      'MontserratBold': require('./assets/fonts/SulSans-Regular.ttf'),
    });

    this.setState({ fontLoaded: true });
  }

  render() {
    return (
      <Router>
        <MyHistoryHack />
        <Provider store={store}>
          <PaperProvider theme={theme}>
            {this.state.fontLoaded ?
              <View style={styles.container}>
                <View style={{
                  flex: 1, alignSelf: 'stretch', maxHeight: Platform.OS === 'web' ? '100vh' : null,
                }}>
                  {/* FB PIXEL STUFF */}
                  <Image style={{ position: 'absolute', top: -100, left: -100, height: 1, width: 1 }} source={{ uri: 'https://www.facebook.com/tr?id=909561212414993&ev=PageView&noscript=1' }} />
                  <Switch>
                    <Redirect exact from='/' to='/marketplace' />
                    <Route path={['/marketplace/categoria/:catId/produto/:prodId?',
                      '/marketplace/categoria/:catId?/cart',
                      '/marketplace/categoria/:catId?',
                      '/marketplace/produto/:prodId?',
                      '/marketplace/cart',
                      '/marketplace/checkout',
                      '/marketplace']}
                      render={({ match }) => {
                        store.dispatch(setRoute(match.url));
                        return <>
                          <Header />
                          <CheckoutStrip />
                          <ModalCart opened={match.url.indexOf('/cart') > -1}
                            onClose={() => { historyHack.history.push(match.params.catId ? ('/marketplace/categoria/' + match.params.catId) : '/marketplace') }}
                          />
                          <ModalProductWithGet // activeCartId={store.cart.activeCartId} 
                            prodId={match.params.prodId}
                            onClosed={() => { historyHack.history.push(match.params.catId ? ('/marketplace/categoria/' + match.params.catId) : '/marketplace') }}
                          />
                          <Home history={historyHack.history}
                            baseUrl={'/marketplace'}
                            url={match.url}
                            params={match.params} />
                        </>
                      }}
                    />
                    <Route exact path="/checkout" render={({ match }) => {
                      store.dispatch(setRoute(match.url));
                      return (<>
                        <Header />
                        <Checkout onClosed={() => {
                          historyHack.history.goBack();
                        }} />
                      </>)
                    }} />
                    <Route exact path="/products" render={({ match }) => {
                      store.dispatch(setRoute(match.url));
                      return <>
                        <Header />
                        <Products />
                      </>
                    }} />
                    <Route path={['/@:storeAlias/categoria/:catId/produto/:prodId?',
                      '/@:storeAlias/categoria/:catId?',
                      '/@:storeAlias/categoria/:catId?/cart',
                      '/@:storeAlias/produto/:prodId?',
                      '/@:storeAlias/cart',
                      '/@:storeAlias/checkout',
                      '/@:storeAlias']}
                      render={({ match }) => {
                        store.dispatch(setRoute(match.url));
                        return <>
                          <Header />
                          <CheckoutStrip />
                          <ModalCart opened={match.url.indexOf('/cart') > -1}
                            onClose={() => {
                              historyHack.history.push(match.params.catId ?
                                `/@${match.params.storeAlias}/categoria/${match.params.catId}` :
                                `/@${match.params.storeAlias}`)
                            }}
                          />
                          <ModalProductWithGet // activeCartId={store.cart.activeCartId} 
                            prodId={match.params.prodId}
                            storeId={match.params.storeAlias}
                            onClosed={() => {
                              historyHack.history.push(match.params.catId ?
                                `/@${match.params.storeAlias}/categoria/${match.params.catId}` :
                                `/@${match.params.storeAlias}`)
                            }}
                          />
                          <Home history={historyHack.history}
                            storeAlias={match.params.storeAlias}
                            baseUrl={'/@' + match.params.storeAlias}
                            url={match.url}
                            params={match.params} />
                        </>
                      }}
                    />
                    <Route path={['/selfservice/:mesaId?',
                      '/cardapio/:mesaId?',
                      '/selfservice',
                      '/cardapio']}
                      render={({ match }) => {
                        store.dispatch(setRoute(match.url));
                        return <>
                          <Header hideCartButton />
                          <SelfService history={historyHack.history}
                            url={match.url}
                            params={match.params}
                          />
                        </>
                      }}
                    />

                  </Switch>
                  <BackButton />
                </View>
              </View>
              :
              <View style={styles.container}>
                <Image source={require('./assets/LOGO_SOS_2.png')}
                  style={{ height: 100, width: 250, resizeMode: 'contain', marginBottom: 30 }} />
                <ActivityIndicator color={Colors.themeColor} style={{ margin: 15 }} />
              </View>
            }
          </PaperProvider>
        </Provider>
      </Router>
    );
  }
}

const Layout = (content) => {

}

const LojaRoutes = ({ match }) => {
  // 
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: '#fff',
    alignItems: 'center',
    justifyContent: 'center',
  },
});

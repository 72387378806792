import { Platform, Alert } from "react-native"

const alertPolyfill = (title, description, options, extra) => {
    if(!options) return window.alert(title + '\n\n' + description);
    const result = window.confirm([title, description].filter(Boolean).join('\n'))

    if (result) {
        const confirmOption = options.find(({ style }) => style !== 'cancel')
        confirmOption && confirmOption.onPress()
    } else {
        const cancelOption = options.find(({ style }) => style === 'cancel')
        cancelOption && cancelOption.onPress()
    }
}
const AlertXp = Platform.OS === 'web' ? { alert: alertPolyfill } : Alert;

export default AlertXp;
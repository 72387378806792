import { Dimensions } from 'react-native';
const { width: viewportWidth, height: viewportHeight } = Dimensions.get('window');

function percentW(percentage) {
  const value = (percentage * viewportWidth) / 100;
  return Math.round(value);
}
function percentH(percentage) {
  const value = (percentage * viewportHeight) / 100;
  return Math.round(value);
}

const NormalizeString = (str) => {
  var string = str;
  var mapaAcentosHex = {
    a: /[\xE0-\xE6]/g,
    A: /[\xC0-\xC6]/g,
    e: /[\xE8-\xEB]/g,
    E: /[\xC8-\xCB]/g,
    i: /[\xEC-\xEF]/g,
    I: /[\xCC-\xCF]/g,
    o: /[\xF2-\xF6]/g,
    O: /[\xD2-\xD6]/g,
    u: /[\xF9-\xFC]/g,
    U: /[\xD9-\xDC]/g,
    c: /\xE7/g,
    C: /\xC7/g,
    n: /\xF1/g,
    N: /\xD1/g
  };
  for (var letra in mapaAcentosHex) {
    var expressaoRegular = mapaAcentosHex[letra];
    string = string.replace(expressaoRegular, letra);
  }
  return string;
}

const objToArray = (obj) => Object.keys(obj).map(x => obj[x]);
const Helpers = {
  FloatToReais: (price) => {
    if (!price || price == null) {
      return null
    }
    if (typeof (price) == 'string') price = Number(price);
    return (
      'R$ ' +
      price
        .toFixed(2) //TO Decimal
        .replace(/\B(?=(\d{3})+(?!\d))/g, '.') //Set Dots
        .replace(/.([^.]*)$/, ',$1')
    ) // Replace last dot do comma
  },
  percentH: percentH,
  percentW: percentW,
  ObjToArray: objToArray,
  Any: (arr, key, val) => arr.filter(x => (key == null ? x : x[key]) && (val == null ? true : (key == null ? x : x[key]) == val)).length > 0,
  First: (arr, key, val) => arr.filter(x => (key == null ? x : x[key]) && (val == null ? true : (key == null ? x : x[key]) == val))[0],
  FirstOrDefault: (arr, lambda, default_) => arr.filter(lambda).length > 0 ? arr.filter(lambda)[0] : default_,
  Count: (arr, fcn) => arr.filter(fcn).length,
  Distinct: (arr) => {
    var ret = {}; arr.map(x => ret[x] = x);
    return objToArray(ret);
  },
  GroupBy: (arr, key) => {
    return arr.reduce(function (rv, x) {
      (rv[x[key]] = rv[x[key]] || []).push(x);
      return rv;
    }, {});
  },
  OrderBy: (arr, key, desc) => {
    var ret = arr.sort((a, b) => typeof a[key] == 'string' ?
      // string
      (NormalizeString(a[key]) < NormalizeString(b[key]) ? -1 : (NormalizeString(a[key]) > NormalizeString(b[key]) ? 1 : 0))
      : // int 
      (a[key] - b[key])
    );
    return desc ? ret.reverse() : ret;
  },
  Sum: function (items, prop) {
    return items.reduce(function (a, b) {
      return a + b[prop];
    }, 0)
  },
  MaxFromArray: function(arr,prop){
    var max = -9999999;
    arr.map(x=> { if(x[prop] > max) max = x[prop];});
    return max;
  },
  Max: function(a,b){
    return a > b ? a : b;
  },
  Min: function(a,b){
    return a < b ? a : b;
  },
  NewGuid: () => {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
      var r = Math.random() * 16 | 0, v = c == 'x' ? r : (r & 0x3 | 0x8);
      return v.toString(16);
    });
  },
  MaskBRPhone: (v) => {
    v = v.replace(/\D/g, "");
    v = v.replace(/^(\d{2})(\d)/g, "($1) $2");
    v = v.replace(/(\d)(\d{4})$/, "$1-$2");
    return v;
  },
  NumbersFromString: (v) => {
    v = v.replace(/\D/g, "");
    return v;
  },
  MaskCreditCard: function (str) {
    return str.replace(/\d(?=\d{4})/g, "●");
  },
  NormalizeString: NormalizeString,
  ToLowerSemAcentos: (str) => NormalizeString(str.toLowerCase())
}
export default Helpers;
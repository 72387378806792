import React from 'react';
import { Platform, ScrollView, Text, View, Image, TouchableOpacity, TextInput as RNTextInput } from 'react-native';
import { Button, Searchbar, TextInput, Appbar, TouchableRipple, ActivityIndicator } from 'react-native-paper';
import ResponsiveColumns, { FloatingColumn } from './../components/ResponsiveColumns';
import Api from './../api/Api';
import { connect } from 'react-redux'
import Helpers from '../utils/Helpers';
import Product from './../components/Product';
import Checkout from './../components/Checkout';
import { addToCart } from '../store/cartsReducer';
import MyAccordion from './../components/MyAccordion';
import FontAwesome from 'react-native-vector-icons/FontAwesome';
import MaterialIcons from 'react-native-vector-icons/MaterialIcons';
import MaterialCommunityIcons from 'react-native-vector-icons/MaterialCommunityIcons';
import Colors from '../constants/Colors';
import { TabletView, MobileView, DesktopView } from '../components/ResponsiveView';
import VMasker from './../utils/VanillaMasker';
import LinkXP from '../utils/LinkXP';
import { Event } from 'expo-analytics';
import { CartCountBadge } from '../components/CartCountBadge';
import DropdownXP from './../components/DropdownXP';
import Carousel from 'react-native-snap-carousel';
import Modal from 'react-native-modalbox';
import MyPanel from '../components/MyPanel';
import ModalProduct from '../components/ModalProduct';
import CheckoutStrip from '../components/CheckoutStrip';
import GlobalStyles from '../constants/GlobalStyles';
import MySearchInput from '../components/MySearchInput';
import { Video } from 'expo-av';

const defaultShowingProds = 40;
class Products extends React.Component {

  constructor(props) {
    super(props);
    var categories = null;
    if (props.catalog && props.catalog.products)
      categories = this.prepareCatalog(props.catalog.products);
    this.state = {
      categoriesShown: false,
      categories: categories,
      selectedCategory: categories && categories[0] || null,
      searchText: '',
      showOnlinePrice: true,
      showingProds: defaultShowingProds,
      orderBy: '-Priority',
      brands: [],
      selectedBrand: '0',
      loadingProducts: !categories || categories.length < 2,
      banners: [],
      products: [],
      storeId: 0
    }
  }

  componentDidMount() {
    let preventProdsLoad = false;
    //console.log('fetch products from PDV.js')
    Api.GetBanners().then(data => {
      console.log('fetch Banners: ', data);
      this.setState({ banners: data.data }, () => {
        // set first banner timeout 
        if (data && data.data && data.data[0] && data.data[0].length)
          this._tOut = setTimeout(() => this._carousel && this._carousel.snapToNext(), data.data[0].length);
      });

      // autoplay videos on ios
      if (Platform.OS == 'web') {
        setTimeout(function () {
          try {
            var vids = document.getElementsByTagName('video');
            for (let i = 0; i < vids.length; i++) {
              const vid = vids[i];
              vid.muted = true;
              vid.playsInline = true;
            }
          } catch (e) { }
        }, 1);
      }
    }).catch(e => {
      this.setState({ banners: null });
    });

    // URL detection! Hackermode! kkkkk
    if (Platform.OS == 'web') {
      //
      // macete pra abrir modal de produto:
      if (window.location && window.location.hash && window.location.hash.toLowerCase().indexOf('id=') > -1) {
        var prodId = window.location.hash.toLowerCase().split('id=')[1].split('&')[0];
        // pega prod na Api e abre modal
        Api.Products.getProducts(Number(prodId)).then(x => {
          if (x && x.data && x.data.length > 0 && x.data[0].Id == prodId) {
            this.setState({ modalProductOpened: x.data[0] });
          }
        })
      }
      //
      // filtro de loja:
      if (window.location && window.location.hash && window.location.hash.toLowerCase().indexOf('storeid=') > -1) {
        var storeId = window.location.hash.toLowerCase().split('storeid=')[1].split('&')[0];
        this.setState({ storeId: Number(storeId) }, this.getProducts);
        preventProdsLoad = true;
      }
    }

    // GET PRODS FROM API!
    if (!preventProdsLoad)
      this.getProducts(true);
  }

  replayAllVidsHack = () => {
    if (Platform.OS == 'web') {
      try {
        var vids = document.getElementsByTagName('video');
        for (let i = 0; i < vids.length; i++) {
          const vid = vids[i];
          vid.currentTime = 0;
          vid.play();
        }
      } catch (e) { }
    }
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.catalog && nextProps.catalog.products)
      this.setState({ categories: this.prepareCatalog(nextProps.catalog.products), loadingProducts: false },
        () => {
          //if (!this.state.selectedCategory)
          this.setState({ selectedCategory: this.state.categories[0] });
        })
  }

  getProducts = (clear = true) => {
    var pageSize = 50;
    if (clear) this.lastProductIndex = 0;
    this.setState({ loadingProducts: true })
    Api.Products.getProducts(0, this.state.storeId, 0, "", this.state.searchText, this.state.orderBy, this.lastProductIndex, pageSize).then(x => {
      this.lastProductIndex += x.data.length;
      this.setState({
        loadingProducts: false,
        hasMoreProdsToLoad: x.data.length == 50, // se voltar menos de 50 eh pq acabou a paginacao
        products: clear ? x.data : [...this.state.products, ...x.data]
      })
    }).catch(e => {

    });
  }

  prepareCatalog = (products) => {
    var cats = [
      {
        Id: 0, Name: 'Todos', Products: products
        // Products: categories.reduce((prev, curr, idx) => {
        //   return prev.concat(curr.Products);
        // }, [])
      }
    ];

    //divide categories:
    if (products && Helpers.Any(products, 'Category')) {
      var generatedCategories = Helpers.GroupBy(products, 'Category');
      var auxArr = Helpers.ObjToArray(generatedCategories);
      var auxCats = auxArr.map(x => ({ Id: x[0].CategoryId, Name: x[0].Category, Products: x.sort((a, b) => a.Order < b.Order) }));
      cats = cats.concat(auxCats.sort((a, b) => a.CategoryOrder < b.CategoryOrder));
    }

    //divide tags:
    if (products && Helpers.Any(products, 'Tags')) {
      var objTags = {};
      products.map(x => x.Tags && x.Tags.map(t => { t = '#' + t; objTags[t] = objTags[t] || []; objTags[t].push(x) }))
      var auxArr = Object.keys(objTags).map(x => ({ Id: x, Name: x, Products: objTags[x].sort((a, b) => a.Order < b.Order) }))
      cats = cats.concat(auxArr.sort((a, b) => {
        var aName = a.Name.replace('#', '').toLowerCase();
        var bName = b.Name.replace('#', '').toLowerCase();
        return aName < bName ? -1 : aName > bName ? 1 : 0;
      }));
    }

    // process brands
    var brands = Helpers.Distinct(products.filter(z => z.Brand).map(z => z.Brand));
    this.setState({ brands: brands.sort((a, b) => a < b ? -1 : a > b ? 1 : 0) });
    console.log('brands::', brands);
    //console.log('RETURNING CATEGORIESSS =>>> ', cats)

    // MACETE! ABRIR MODAL DE PRODUTO SE TIVER NA URL
    if (Platform.OS === 'web') {
      if (window.location && window.location.hash && window.location.hash.toLowerCase().indexOf('id=') > -1) {
        // se tiver o produto no catalog retorna ele daqui a pouco
        if (products.filter(p => p.Id == window.location.hash.toLowerCase().split('id=')[1]).length > 0) {
          setTimeout(() => {
            var prod = products.filter(p => p.Id == window.location.hash.toLowerCase().split('id=')[1])[0];
            this.setState({ modalProductOpened: prod });
          }, 150);
        }
      }
    }

    return cats;
  }

  filteredProducts = (prods) => {
    prods = prods.filter(p => /*!(p.QtyInStock <= 0 && p.TrackStock) &&*/(this.state.showOnlinePrice ? p.SellingOnline : p.SellingOnStore));
    if (this.state.searchText) {
      var tx = Helpers.NormalizeString(this.state.searchText.toLowerCase());
      prods = prods.filter(p => {
        return Helpers.NormalizeString(p.Name.toLowerCase()).indexOf(tx) > -1 // name search
          || // price search
          (p.Price.toFixed(2).toString().indexOf(tx.replace(',', '.')) > -1)
          ||  // sku search
          (p.SKU && p.SKU.toString().toLowerCase().indexOf(tx) > -1)
          ||  // tags search
          (p.Tags && p.Tags.filter(x => Helpers.NormalizeString('#' + x.toLowerCase()).indexOf(tx) > -1).length > 0)
      })
    }

    // price filter
    if (this.state.fromPrice && !isNaN(Helpers.NumbersFromString(this.state.fromPrice))) {
      var fromPrice = Helpers.NumbersFromString(this.state.fromPrice) / 100;
      prods = prods.filter(p => p.Price >= fromPrice);
    }
    if (this.state.toPrice && !isNaN(Helpers.NumbersFromString(this.state.toPrice))) {
      var toPrice = Helpers.NumbersFromString(this.state.toPrice) / 100;
      prods = prods.filter(p => p.Price <= toPrice);
    }

    // brands filter
    if (this.state.selectedBrand && this.state.selectedBrand != '0') {
      prods = prods.filter(p => p.Brand == this.state.selectedBrand);
    }

    return prods;
  }

  orderedProducts = (prods) => {
    var desc = this.state.orderBy.indexOf('-') == 0;
    var key = this.state.orderBy.replace('-', '');
    var ordered = Helpers.OrderBy(prods, key, desc);
    var orderedInStock = ordered.filter(x => x.QtyInStock > 0);
    var orderedOutOfStock = ordered.filter(x => x.QtyInStock <= 0);
    // if (key === 'TempSellsCount') { // maceta ordem inicial
    //   orderedInStock = Helpers.OrderBy(orderedInStock, 'Priority', true);
    //   orderedOutOfStock = Helpers.OrderBy(orderedOutOfStock, 'Priority', true);
    // }
    var ret = orderedInStock.concat(orderedOutOfStock);
    return ret;
  }

  renderAllProds = () => {
    return this.state.products.map(p => <Product key={p.Id} Product={p} onPress={() => {
      console.log(p)
      if (Platform.OS === 'web') window.location.hash = '#id=' + p.Id;
      return this.setState({ modalProductOpened: p });
    }} />)
  }

  renderProds = (selectedCategory) => {
    var prods = selectedCategory.Products;
    // filtering and ordering:
    prods = this.filteredProducts(prods);
    prods = this.orderedProducts(prods);

    // styles
    var defaultStyles = {
      display: 'flex', flex: 1, flexDirection: 'row', flexWrap: 'wrap',
      justifyContent: 'flex-start', alignItems: 'flex-start', alignContent: 'flex-start', placeContent: 'center'
    };

    var totallen = prods.length;
    prods = prods.filter((x, i) => i <= this.state.showingProds);
    var len = prods.length;

    prods = prods.map(x => { return { ...x, Price: this.state.showOnlinePrice ? x.PriceOnline : x.Price, SiteSale: this.state.showOnlinePrice } });
    if (!prods || prods.length == 0) return null;
    return [...(prods.map(p => <Product key={p.Id} Product={p} onPress={() => {
      console.log(p)
      if (Platform.OS === 'web') window.location.hash = '#id=' + p.Id;
      return this.setState({ modalProductOpened: p });
      if (p.QtyInStock <= 0 && prod.TrackStock) {

      } else {
        Api.Analytics.event(new Event("AddToCart", p.Id));
        this.props.dispatch(addToCart(p, this.props.activeCartId))
      }
    }} />))
      ,
    len < totallen ?
      <View style={{ flex: 1, alignSelf: 'stretch' }} key={-11}>
        <TouchableOpacity onPress={() => this.setState({ showingProds: this.state.showingProds + 40 })}>
          <View style={{
            flexDirection: 'column', backgroundColor: '#fff1f1',
            padding: 0, width: 160, maxWidth: 140, height: 240, maxHeight: 240, borderRadius: 5,
            boxShadow: '0px 1px 5px #ddd', margin: 10, marginTop: 0
          }}>
            <View style={{ width: 140, height: 150, justifyContent: 'center', alignItems: 'center' }}>
              <Text style={{ ...GlobalStyles.textBase, fontSize: 32 }}>
                <MaterialCommunityIcons name="plus" size={34} style={{ color: Colors.themeColor }} />🍫😍</Text>
            </View>
            <Text style={{
              ...GlobalStyles.textBase,
              textAlign: 'center', alignSelf: 'center', marginTop: 10, paddingHorizontal: 6,
              fontWeight: 'bold', fontFamily: 'MontserratBold', color: Colors.themeColor
            }}>
              Mostrar mais produtos
            </Text>
          </View>
        </TouchableOpacity>
      </View>
      : null];

    return (
      <View style={defaultStyles}>
        {prods.map(p => <Product Product={p} onPress={() => this.props.dispatch(addToCart(p, this.props.activeCartId))} />)}
        {len < totallen ?
          <View style={{ flex: 1, alignSelf: 'stretch' }}>
            <Button mode="contained" onPress={() => this.setState({ showingProds: this.state.showingProds + 40 })}>
              <Text>Mostrar mais produtos</Text>
            </Button>
          </View>
          : null}
      </View>);
  }

  renderCategoriesList = () => {
    if (!this.props.catalog) return;
    return this.state.categories.map(c => (
      <View key={c.Id} //className={`clickable ${this.state.selectedCategory && c.Id == this.state.selectedCategory.Id ? 'itemSelected' : ''}`} 
      style={{
        padding: 15, borderBottom: '1px solid #eee', minHeight: 60, alignSelf: 'stretch',
        flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', display: 'flex'
      }}
        onPress={() => { this.setState({ selectedCategory: c }) }}>
        <Text>{c.Name}</Text>
      </View>));
  }

  renderSideMenu = () => {
    var btnStyle = { ...GlobalStyles.textBase, display: 'flex', alignItems: 'center', fontSize: 15, paddingHorizontal: 8, paddingVertical: 10, color: Colors.themeColor };
    if (Platform.OS != 'web') { btnStyle.fontSize = 15; btnStyle.paddingHorizontal = 4; }
    return <View key='lol' style={{
      marginHorizontal: 0, borderRadius: 14, marginBottom: 15,
      alignSelf: 'stretch', flexDirection: 'column', alignItems: 'flex-start'
      //backgroundColor: Colors.bgColor, 
      //justifyContent: 'space-evenly', alignItems: 'center'
    }}>
      <TouchableRipple key={0} onPress={() => {
        LinkXP.OpenURL('https://www.google.com/maps/place/SOS+Chocolate/@-23.0008128,-43.3962244,15z/data=!4m5!3m4!1s0x0:0xa535e4dece617423!8m2!3d-23.0008128!4d-43.3962244', '_blank')
        Api.Analytics.event(new Event("OpenedLink", 'Maps'));
      }}>
        <Text style={btnStyle}>
          <MaterialCommunityIcons name="map-marker" size={22} />Av. Américas 8585, lj. 146
        </Text>
      </TouchableRipple>
      <TouchableRipple key={1} onPress={() => {
        LinkXP.OpenURL('https://api.whatsapp.com/send?phone=5521975005115', '_blank')
        Api.Analytics.event(new Event("OpenedLink", 'WhatsApp'));
      }}>
        <Text style={btnStyle}>
          <MaterialCommunityIcons name="whatsapp" size={22} /> (21)97500-5115
      </Text>
      </TouchableRipple>
      <TouchableRipple key={2} onPress={() => {
        LinkXP.OpenURL('https://www.instagram.com/soschocolate.com.br/', '_blank');
        Api.Analytics.event(new Event("OpenedLink", 'Instagram'));
      }}>
        <Text style={btnStyle}>
          <MaterialCommunityIcons name="instagram" size={22} /> @soschocolate.com.br
        </Text>
      </TouchableRipple>
      <TouchableRipple key={3} onPress={() => {
        LinkXP.OpenURL('https://www.facebook.com/soschocolate.com.br/', '_blank');
        Api.Analytics.event(new Event("OpenedLink", 'Facebook'));
      }}>
        <Text style={btnStyle}>
          <MaterialCommunityIcons name="facebook" size={22} /> /soschocolate.com.br
        </Text>
      </TouchableRipple>
      <TouchableRipple key={4} onPress={() => {
        LinkXP.OpenURL('mailto:atendimento@soschocolate.com.br', '_blank')
        Api.Analytics.event(new Event("OpenedLink", 'Email'));
      }}>
        <Text style={btnStyle}>
          <MaterialCommunityIcons name="email-outline" size={22} /> contato por e-mail
        </Text>
      </TouchableRipple>
    </View>
  }

  render() {
    var columnWidth = Helpers.Min(300, Helpers.percentW(70))
    return <>

      {/* PRODUCT MODAL */}
      <ModalProduct product={this.state.modalProductOpened}
        activeCartId={this.props.activeCartId}
        onClosed={() => this.setState({ modalProductOpened: null }, () => {
          if (Platform.OS === 'web') window.location.hash = '';
        })} />

      {/* CHECKOUT STRIP */}
      <CheckoutStrip forceMobileViewport />

      <ScrollView stickyHeaderIndices={[1]} style={{ flex: 1 }} contentContainerStyle={{ backgroundColor: '#efefef' }}>
        {/* BANNERS */}
        {this.state.banners !== null &&
          <View style={{
            maxWidth: Helpers.percentW(100), width: 420, alignSelf: 'center',
            padding: 0, marginTop: 15, marginBottom: 15, backgroundColor: 'transparent', alignItems: 'center',
            flexDirection: 'row', justifyContent: 'center'
          }}>
            {this.state.banners && this.state.banners.length ? <>
              <Carousel loop={true} layout={'tinder'} layoutCardOffset={9}
                ref={(c) => { this._carousel = c; }}
                onBeforeSnapToItem={async (index) => {
                  //console.log('SLIDE WILL CHANGE:', index, this['Slide' + index])
                  // timeout
                  if (this._tOut) clearTimeout(this._tOut);
                  if (this.state.banners[index] && this.state.banners[index].length)
                    this._tOut = setTimeout(() => this._carousel.snapToNext(), this.state.banners[index].length);
                  // try replay video
                  if (this.state.banners[index] && this.state.banners[index].type === 'video') {
                    this.replayAllVidsHack();

                    var vidRef = this['Slide' + index];
                    //await vidRef.unloadAsync();
                    //await vidRef.loadAsync({ uri: this.state.banners[index].url + '?v=' + Helpers.NewGuid() });
                    await vidRef.setIsLoopingAsync(false);
                    await vidRef.stopAsync(false);
                    await vidRef.playFromPositionAsync(0);
                    await vidRef.setIsLoopingAsync(true);
                  }
                }}
                data={this.state.banners}
                renderItem={({ item, index }) => item.type == 'video' ? <View key={index} style={{ marginBottom: 15, height: 210, backgroundColor: '#000' }}>
                  <Video ref={(r) => this['Slide' + index] = r}
                    // source={ Platform.OS === 'web' ? 
                    //     { uri: 'https://eventcoin.s3-sa-east-1.amazonaws.com/eventcoinintro.mp4' } 
                    //     : require('../assets/videos/eventcoinintro.mp4')}
                    //source={require('../assets/videos/big_buck_bunny.mp4')}
                    source={{ uri: item.url }}
                    rate={1.0}
                    volume={0}
                    isMuted={true}
                    resizeMode="contain"
                    shouldPlay
                    isLooping
                    //onPlaybackStatusUpdate={this._onPlaybackStatusUpdate} 
                    style={{
                      width: Helpers.Min(420, Helpers.percentW(90)) - Helpers.percentW(10),
                      height: 210,
                    }}
                    onLoad={(dat) => console.log('onLoad!', dat)}
                    onLoadStart={(dat) => console.log('onLoadStart!', dat)}
                    onError={(err) => console.log('ERROR!', err)}
                  />
                </View>
                  :
                  <View key={index} style={{
                    width: Helpers.Min(420, Helpers.percentW(90)) - Helpers.percentW(10),
                    height: 210, backgroundColor: '#fff'
                  }}>
                    <Image source={{ uri: item.url }}
                      style={{
                        width: Helpers.Min(420, Helpers.percentW(90)) - Helpers.percentW(10),
                        height: 210, resizeMode: 'contain'
                      }} />
                  </View>
                }
                style={{ maxWidth: Helpers.percentW(100), width: 420 }}
                sliderWidth={Helpers.Min(420, Helpers.percentW(100))}
                itemWidth={Helpers.Min(420, Helpers.percentW(90)) - Helpers.percentW(10)}
              />
              <TouchableOpacity style={{ padding: 8, borderRadius: 30, position: 'absolute', left: 0 }} onPress={() => this._carousel.snapToPrev()}>
                <MaterialCommunityIcons name="chevron-left" size={34} style={{ color: Colors.themeColor }} />
              </TouchableOpacity>
              <TouchableOpacity style={{ padding: 8, borderRadius: 30, position: 'absolute', right: 0 }} onPress={() => this._carousel.snapToNext()}>
                <MaterialCommunityIcons name="chevron-right" size={34} style={{ color: Colors.themeColor }} />
              </TouchableOpacity>
            </>
              :
              this.state.banners === null ?
                <View></View>
                :
                <>
                  <ActivityIndicator color={Colors.themeColor} style={{ margin: 15 }} />
                  <Text>Carregando destaques...</Text>
                </>
            }
          </View>
        }

        {/* BUSCA TEXTO, FILTROS E ORDENACAO */}
        <View style={{ padding: 8, backgroundColor: '#fff', marginBottom: 8 }}>
          {/* TEXT SEARCH */}
          <View style={{ alignItems: 'center', alignSelf: 'center', maxWidth: Helpers.percentW(94), width: 420 }}>
            <MySearchInput onChangeText={(q) => this.setState({ searchText: q }, this.getProducts)}
              delay={250} style={{
                maxWidth: Helpers.percentW(94), width: 420, flex: 1, marginHorizontal: 18, paddingHorizontal: 6, borderWidth: 1, borderColor: Colors.darkLines,
                paddingVertical: 2, backgroundColor: '#fff', flexDirection: 'row', alignItems: 'center', borderRadius: 3, height: 38
              }}
            />
          </View>
          {/* BRANDS AND ORDERING */}
          <View style={{ paddingTop: 8, flexDirection: 'row', justifyContent: 'space-between', alignSelf: 'center', maxWidth: Helpers.percentW(94), width: 420 }}>
            {/* BRANDS FILTER */}
            <View style={{
              maxWidth: Helpers.percentW(47), width: 200,
              flexDirection: 'column', justifyContent: 'center', alignItems: 'center',
              backgroundColor: '#fff', borderRadius: 5,
            }}>
              <DropdownXP selectedValue={this.state.selectedBrand}
                style={{
                  ...GlobalStyles.textBase,
                  height: 40, flex: 1, display: 'flex', alignSelf: 'stretch',
                  borderWidth: 1, marginRight: 5, borderStyle: 'solid', borderRadius: 4, borderColor: Colors.darkLines, padding: 8
                }}
                onValueChange={(itemValue) => this.setState({
                  selectedBrand: itemValue,
                  showingProds: defaultShowingProds
                })}
                data={[
                  { label: "Todas as marcas", value: "0" },
                  ...this.state.brands.map(x => x ? { value: x, label: x } : null)
                ]} />
            </View>

            {/* ORDER BY */}
            <View style={{
              maxWidth: Helpers.percentW(47), width: 200,
              flexDirection: 'column', justifyContent: 'center', alignItems: 'center',
              backgroundColor: '#fff', borderRadius: 5,
            }}>
              <DropdownXP selectedValue={this.state.orderBy}
                style={{
                  ...GlobalStyles.textBase,
                  height: 40, flex: 1, display: 'flex', flexDirection: 'row', borderWidth: 1, marginLeft: 5,
                  borderStyle: 'solid', borderRadius: 4, borderColor: Colors.darkLines, padding: 8
                }}
                onValueChange={(itemValue) => this.setState({
                  orderBy: itemValue, showingProds: defaultShowingProds
                }
                  , () => this.getProducts())}
                data={[
                  { label: "Destaques", value: "-Priority" },
                  { label: "Nome A-Z", value: "Name" },
                  { label: "Nome Z-A", value: "-Name" },
                  { label: "Menor preço", value: "Price" },
                  { label: "Maior preço", value: "-Price" },
                ]} />
            </View>

          </View>
        </View>

        {/* PRODUCTS */}
        <View style={{
          display: 'flex',
          flexDirection: 'row', flexWrap: 'wrap', //placeContent: 'center',
          justifyContent: 'center', alignItems: 'flex-start', alignContent: 'flex-start',
          backgroundColor: '#efefef', marginBottom: 45
        }}
        >
          {this.state.loadingProducts &&
            <View style={{ justifyContent: 'center', alignItems: 'center', width: Helpers.percentW(90), marginTop: 45, marginBottom: 45 }}>
              <ActivityIndicator color={Colors.themeColor} style={{ margin: 15 }} />
              <Text>Carregando produtos...</Text>
            </View>}
          {this.renderAllProds()}

          {/* BOTAO CARREGAR MAIS PRODUTOS */}
          {this.state.hasMoreProdsToLoad && <View style={{ flex: 1, alignItems: 'center', alignSelf: 'stretch', minWidth: Helpers.percentW(92) }} key={-11}>
            <TouchableOpacity onPress={() => {
              //this.setState({ showingProds: this.state.showingProds + 40 });
              this.getProducts(false);
            }}>
              <View style={{
                flexDirection: 'column', backgroundColor: '#fff1f1',
                padding: 0, width: Helpers.Min(Helpers.percentW(100), 420), height: 40, borderRadius: 5,
                boxShadow: '0px 1px 5px #ddd', margin: 10, marginTop: 0
              }}>
                <Text style={{
                  ...GlobalStyles.textBase,
                  textAlign: 'center', alignSelf: 'center', marginTop: 10, paddingHorizontal: 6,
                  fontWeight: 'bold', fontFamily: 'MontserratBold', color: Colors.themeColor
                }}>
                  Carregar mais produtos
            </Text>
              </View>
            </TouchableOpacity>
          </View>}
          {/* {this.props.catalog && this.state.selectedCategory ?
            this.renderProds(this.state.selectedCategory) : null
          } */}
        </View>
      </ScrollView>
    </>
  }
}

export default connect(
  (state) => ({
    catalog: state.catalog,
    activeCartId: state.cart.activeCartId,
    //cartCount: getCart(state.cart.carts, state.cart.activeCartId).qtd,
  }),
  null)(Products);




import React, { Component } from 'react';
import { View, Text, TouchableOpacity, Platform, Image } from 'react-native';
import GlobalStyles from '../constants/GlobalStyles';
import Colors from '../constants/Colors';

export default class MyPanel extends Component {
    render() {
        var backgroundColor = this.props.backgroundColor || '#fff';
        return <View style={{
            marginTop: 15, marginBottom: 5,
            backgroundColor: backgroundColor, paddingTop: 10,
            //borderWidth: this.props.borderWidth || 1,
            borderColor: this.props.borderColor || '#ccc', borderRadius: 5,
            ...GlobalStyles.shadow3,
            //...(this.props.bottomWidthOnly ? { borderWidth: 0, borderBottomWidth: this.props.borderWidth || 2, borderRadius: 0 } : {}),
            ...(this.props.style),
        }}>
            <View style={{ flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}>
                {this.props.icon || this.props.title ?
                    <Text style={{
                        ...GlobalStyles.textBase,
                        //position: 'absolute', top: -14, 
                        padding: 5, marginLeft: 5,
                        backgroundColor: backgroundColor,
                        fontWeight: 'bold', fontFamily: 'MontserratBold', fontSize: 16,
                        color: this.props.color || '#7e7e7e'
                    }}>
                        {this.props.icon ? this.props.icon : null}
                        {this.props.title}
                    </Text> : null}
                {this.props.rightContent && <View style={{ marginRight: 6 }}>
                    {this.props.rightContent}
                </View>}
            </View>
            <View style={{ padding: 10, borderTopWidth: 1, borderTopColor: Colors.lines, marginTop: 6 }}>
                {this.props.children}
            </View>
        </View>

        return <View style={{
            marginTop: 25, marginBottom: 5,
            backgroundColor: backgroundColor, paddingTop: 10, borderWidth: this.props.borderWidth || 2,
            borderColor: this.props.borderColor || '#ccc', borderRadius: 5,
            ...(this.props.bottomWidthOnly ? { borderWidth: 0, borderBottomWidth: this.props.borderWidth || 2, borderRadius: 0 } : {}),
            ...(this.props.style)
        }}>
            {this.props.rightContent && <View style={{ position: 'absolute', right: 6, top: -10 }}>
                {this.props.rightContent}
            </View>}
            {this.props.icon || this.props.title ?
                <Text style={{
                    ...GlobalStyles.textBase,
                    position: 'absolute', top: -14, padding: 5, marginLeft: 5,
                    backgroundColor: backgroundColor,
                    fontWeight: 'bold', fontFamily: 'MontserratBold', fontSize: 16,
                    color: this.props.color || '#7e7e7e'
                }}>
                    {this.props.icon ? this.props.icon : null}
                    {this.props.title}
                </Text> : null}
            <View style={{ padding: 10, paddingTop: 6 }}>
                {this.props.children}
            </View>
        </View>
    }
    render2() {
        var backgroundColor = this.props.backgroundColor || '#fff';
        return <View style={{
            marginTop: 25, marginBottom: 5,
            backgroundColor: backgroundColor, paddingTop: 10, borderWidth: this.props.borderWidth || 2,
            borderColor: this.props.borderColor || '#ccc', borderRadius: 5,
            ...(this.props.bottomWidthOnly ? { borderWidth: 0, borderBottomWidth: this.props.borderWidth || 2, borderRadius: 0 } : {}),
            ...(this.props.style)
        }}>
            {this.props.rightContent && <View style={{ position: 'absolute', right: 6, top: -10 }}>
                {this.props.rightContent}
            </View>}
            {this.props.icon || this.props.title ?
                <Text style={{
                    ...GlobalStyles.textBase,
                    position: 'absolute', top: -14, padding: 5, marginLeft: 5,
                    backgroundColor: backgroundColor,
                    fontWeight: 'bold', fontFamily: 'MontserratBold', fontSize: 16,
                    color: this.props.color || '#7e7e7e'
                }}>
                    {this.props.icon ? this.props.icon : null}
                    {this.props.title}
                </Text> : null}
            <View style={{ padding: 10, paddingTop: 6 }}>
                {this.props.children}
            </View>
        </View>
    }
}
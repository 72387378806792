import React, { useRef, useState } from 'react';
import { ScrollView, StyleSheet, Dimensions } from 'react-native';

export const ProductsScrollView = ({ ...props }) => {
  const scrollRef = useRef(null)
  const [currentX, setCurrentX] = useState(0)

  const scrollX = (px) => scrollRef.current.scrollTo({ x: currentX + px })
  let scrollPad = Dimensions.get('window').width / 4;
  if (scrollPad < 200) scrollPad = 200;
  return <>
    <style>
      {`
    .hoverer {
      position: relative;
      display: flex;
      align-items: center;
    }

    .hoverer .hovering,
    .hoverer:hover .not-hovering {
      opacity: 0;
      transition: opacity 0.25s linear;
    }

    .hoverer:hover .hovering {
      opacity: 0.5;
      display: flex;
      align-items: center;
    }
    
    .arrow {
      position: absolute;
      top: calc(50% - 50px);
      height: 100px;
      background-color: ${props.arrowBgColor || '#bababa88'};
      padding: 0 15px;
      cursor: pointer;
      user-select: none;
      opacity: 0.5;
      display: flex;
      align-items: center;
    }
    .arrow:hover {
      opacity:0.9 !important;
    }

    .arrow.left {
      left: 0;
      border-top-right-radius: 150px;
      border-bottom-right-radius: 150px;
      padding-left: 10px;
    }
    .arrow.left b {
      border-color: rgba(0, 0, 0, 0) rgb(255, 255, 255) rgba(0, 0, 0, 0) rgba(0, 0, 0, 0); 
      border-width: 8px 12px 8px 0px; border-style: solid; height: 0px; margin: 5px; width: 0px;
    }

    .arrow.right {
      right: 0;
      border-top-left-radius: 150px;
      border-bottom-left-radius: 150px;
      padding-right: 10px;
    }
    .arrow.right b {
      border-color: rgba(0, 0, 0, 0) rgba(0, 0, 0, 0) rgba(0, 0, 0, 0) rgb(255, 255, 255); 
      border-width: 8px 0px 8px 12px; border-style: solid; height: 0px; margin: 5px; width: 0px;
    }
    `}
    </style>
    <div className="hoverer coolScrollWrapper">
      <ScrollView ref={scrollRef} onScroll={({ nativeEvent }) => setCurrentX(nativeEvent.contentOffset.x)}
        horizontal contentContainerStyle={styles.innerList} {...props}/>
      <a className='hovering arrow left' onClick={() => scrollX(-scrollPad)}>
        <b></b>
      </a>
      <a className='hovering arrow right' onClick={() => scrollX(scrollPad)}>
        <b></b>
      </a>
    </div>
  </>;
}

const styles = StyleSheet.create({
  innerList: {
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
  }
});

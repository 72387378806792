import store from './../store/store';
import { catalogReducer, setProducts } from './../store/catalogReducer';

import axios from 'axios'
import { setSale } from '../store/salesReducer';
var moment = require('moment');

const CompanyName = 'SOS Chocolate';
const BaseURL = "https://www.soschocolate.com.br";
//const BaseURL = "https://homolog.soschocolate.com.br";
//const BaseURL = "http://localhost:64649";
//const BaseURL = "http://10.0.0.104:64650";
//const BaseURL = "http://192.168.0.6:64650";
//const BaseURL = "http://10.0.0.101:64650";
const ApiURL = BaseURL + "/api/v1";
const AdminURL = BaseURL + "/admin";

const SaleOrigins = { Site: 1, App: 2, POS: 3, iFood: 4 }

var Api = {
    CompanyName,
    BaseURL,
    ApiURL,
    AdminURL,
    SaleOrigins,
    Login: (user, pass) => {
        return axios.post(ApiURL + '/user/login', { email: user, password: pass })
    },
    AddressSuggestions: (text) => {
        return axios.get(ApiURL + '/public/places?address=' + text);
    },
    Geocode: (text) => {
        return axios.get(ApiURL + '/public/geocode?address=' + text);
    },
    GooglePlaceDetails: (placeId) => {
        return axios.get(ApiURL + '/public/placeDetails?placeId=' + placeId);
    },
    GetBanners: () => {
        return axios.get(ApiURL + '/public/getBanners');
    },
    GetBrands: () => {
        return axios.get(ApiURL + '/public/getBrands');
    },
    GetShipping: (address, storeId, deliveryMethod, scheduledDate, scheduledPeriod, productsJson) => {
        var addrQS = Object.keys(address).reduce(function (a, k) { a.push(k + '=' + encodeURIComponent(address[k])); return a }, []).join('&');
        return axios.get(ApiURL + '/checkout/GetShipping?storeId=' + storeId
            + '&method=' + deliveryMethod
            + '&scheduledDate=' + (scheduledDate || '')
            + '&scheduledPeriod=' + (scheduledPeriod || '')
            + '&productsJson=' + productsJson + '&' + addrQS);
    },
    Products: {
        getAllCategories: (storeId = 0) => {
            return axios.get(ApiURL + '/public/getCategoriesOnline?storeId=' + storeId)
        },
        getProducts: (id = 0, storeId = 0, categoryId = 0, brandId = 0, text = "", orderBy = "", skip = 0, take = 50) => {
            return axios.get(ApiURL + '/public/GetProductsOnline?id=' + id + '&storeId=' + storeId
                + '&categoryId=' + categoryId
                + '&brandId=' + brandId
                + '&text=' + text
                + '&orderBy=' + orderBy
                + '&skip=' + skip
                + '&take=' + take)
            // .then(x => {
            //     //console.log('Setting products:', x.data);
            //     store.dispatch(setProducts(x.data));
            // }).catch(e => {
            //     console.error(e);
            // })
        },
        getProductsSelfService: (id = 0, storeId = 0, categoryId = 0, brandId = 0, text = "", orderBy = "", skip = 0, take = 50) => {
            return axios.get(ApiURL + '/public/GetProductsSelfService?id=' + id + '&storeId=' + storeId
                + '&categoryId=' + categoryId
                + '&brandId=' + brandId
                + '&text=' + text
                + '&orderBy=' + orderBy
                + '&skip=' + skip
                + '&take=' + take)
            // .then(x => {
            //     //console.log('Setting products:', x.data);
            //     store.dispatch(setProducts(x.data));
            // }).catch(e => {
            //     console.error(e);
            // })
        },
    },
    Customers: {
        getCustomer: (document = "", telephone = "", email = "") => {
            return axios.get(ApiURL + '/customer/find?document=' + document + '&telephone=' + telephone + '&email=' + email)
                .then(x => {
                    console.log('Got customer:', x.data);
                    return x.data;
                }).catch(e => {
                    console.error(e);
                })
        }
    },
    GetCategoryInfo: (catId) => {
        return axios.get(ApiURL + '/public/getCategoryInfo?id=' + catId);
    },
    GetStoreByAlias: (alias) => {
        return axios.get(ApiURL + '/public/getStoreByAlias?alias=' + alias);
    },
    GetStoreById: (id) => {
        return axios.get(ApiURL + '/public/getStoreById?Id=' + id);
    },
    GetSaleStatus: (saleId) => {
        return axios.get(ApiURL + '/public/getSaleStatus?Id=' + saleId);
    },
    GetMarketPlaceSaleStatus: (saleId) => {
        return axios.get(ApiURL + '/checkout/getMarketPlaceSaleStatus?Id=' + saleId);
    },
    Checkout: (sale) => {
        return axios.post(ApiURL + '/public/checkout', { ...sale })
    },
    CheckoutMarketPlace: (sale) => {
        return axios.post(ApiURL + '/checkout/marketplace', { ...sale })
    },
    CheckoutSelfService: (sale) => {
        return axios.post(ApiURL + '/public/SaveSelfServiceOrder', { ...sale })
    },
    // GetCheckoutStatus: (saleId) => {
    //     return axios.post(ApiURL + '/public/getCheckoutStatus', { email: user, password: pass })
    // },
    // Sales: {
    //     syncSale: (sale) => {
    //         if (Api.Sales.syncing) return;
    //         Api.Sales.syncing = true;
    //         store.dispatch(setSale(sale.guid, { ...sale, loading: true }));
    //         var sSale = { ...sale, soldDate: moment(sale.soldDate).utc().format() }
    //         return axios.post(ApiURL + '/sale/save', sale)
    //             .then(function (response) {
    //                 console.log(response);
    //                 Api.Sales.syncing = false;
    //                 var serverSaleId = response.data.id;
    //                 sale.loading = false;
    //                 sale.id = serverSaleId;
    //                 sale.dateSync = new Date();
    //                 sale.synced = true;
    //                 store.dispatch(setSale(sale.guid, sale));
    //             })
    //             .catch(function (error) {
    //                 Api.Sales.syncing = false;
    //                 sale.loading = false;
    //                 store.dispatch(setSale(sale.guid, sale));
    //                 console.log(error);
    //             });
    //     }
    // }
}
export default Api;
import React, { Component } from 'react';
import { View, Text, TextInput } from 'react-native';
import Colors from '../constants/Colors';
import GlobalStyles from '../constants/GlobalStyles';
import Helpers from '../utils/Helpers';
import MaterialIcons from 'react-native-vector-icons/MaterialIcons';
export default class MyTextInput extends React.Component {
    constructor(props){
        super(props);
        this.state = { 
            searchText: ''
        }
    }

    onChangeTextDelayed = () => {
        if (this.timeOut > 0) clearTimeout(this.timeOut);
        this.timeOut = setTimeout(() => {
          this.props.onChangeText(this.state.searchText);
        }, this.props.delay || 250);
      }

    render() {
        return <View style={{
            maxWidth: Helpers.percentW(94), width: 420, flex: 1, marginHorizontal: 18, paddingHorizontal: 6, borderWidth: 1, borderColor: Colors.darkLines,
            paddingVertical: 2, backgroundColor: '#fff', flexDirection: 'row', alignItems: 'center', borderRadius: 3, height: 38,
            ...this.props.style
          }}>
            <TextInput style={{
              ...GlobalStyles.textBase,
              backgroundColor: '#fff', height: 32, fontSize: 16, flex: 1
            }}
              onChangeText={query => {
                this.setState({
                  searchText: query,
                }, this.onChangeTextDelayed);
              }} placeholder="Busque seu chocolate..."
              value={this.state.searchText} />
            <MaterialIcons style={{ color: Colors.themeColor }} size={32} name="search" />
          </View>
    }
}
'use strict';
import React, { PropTypes, useState } from 'react';
import {
    StyleSheet,
    TouchableOpacity,
    View,
    Text,
    LayoutAnimation
} from 'react-native';
import Collapsible from './Collapsible';

const CustomLayoutLinear = {
    duration: 200,
    create: {
        type: LayoutAnimation.Types.linear,
        property: LayoutAnimation.Properties.opacity,
    },
    update: {
        type: LayoutAnimation.Types.linear,
    },
};

class MyAccordion extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            is_visible: true,
            height: 0,
            content_height: 0,
            inited: false
        }
    }

    componentDidMount() {
        // Gets content height when component mounts
        // without setTimeout, measure returns 0 for every value.
        // See https://github.com/facebook/react-native/issues/953
        setTimeout(() => this._getContentHeight(() => this.setState({ height: this.props.expanded ? this.state.content_height : 0 })), 1);
    }

    componentWillReceiveProps(nextProps) {
        LayoutAnimation.configureNext(CustomLayoutLinear);
        this.setState({ height: nextProps.expanded ? this.state.content_height : 0 })
    }

    close = () => {
        this.state.is_visible && this.toggle();
    }

    open = () => {
        !this.state.is_visible && this.toggle();
    }

    toggle = () => {
        LayoutAnimation.configureNext(CustomLayoutLinear);
        console.log('Toggle MyAccordion: Height => ', this.state.height === 0 ? this.state.content_height : 0);
        this.setState({
            is_visible: !this.state.is_visible,
            height: this.state.height === 0 ? this.state.content_height : 0
        }, () => console.log(this.state))
    }

    _onPress = () => {
        this.toggle();
        if (this.props.onPress) {
            this.props.onPress.call(this);
        }
    }

    _getContentHeight = (cb) => {
        if (this.AccordionContent) {
            this.AccordionContent.measure((ox, oy, width, height, px, py) => {
                console.log('measure (ox, oy, width, height, px, py) ', ox, oy, width, height, px, py)
                // Sets content height in state
                this.setState({
                    inited: true,
                    height: this.props.expanded ? height : 0,
                    content_height: height
                }, () => {
                    //console.log('myaccordion state:',this.state)
                    if (cb) cb();
                });
            });
        }
    }

    render() {
        return (
            /*jshint ignore:start */
            <View
                style={{
                    overflow: 'hidden'
                }}
            >
                <TouchableOpacity
                    ref="AccordionHeader"
                    onPress={this._onPress}
                    underlayColor={this.props.underlayColor || '#fff'}
                    style={this.props.style}
                    activeOpacity={0.25}
                >
                    {this.props.header}
                </TouchableOpacity>
                <View
                    ref={(r) => this.AccordionContentWrapper = r}
                    style={[this.state.inited ? { height: this.state.height } : {}]}
                >
                    <View ref={(r) => this.AccordionContent = r}>
                        {this.props.children}
                    </View>
                </View>
            </View>
            /*jshint ignore:end */
        );
    }
};
MyAccordion.defaultProps = {
    activeOpacity: 1,
    animationDuration: 300,
    easing: 'linear',
    expanded: true,
    underlayColor: '#000',
    style: {}
}
export default MyAccordion;

export const MyAccordionXP = (props) => {
    const [opened, setOpened] = useState(false);
    return <View>
        <TouchableOpacity onPress={() => setOpened(!opened)}>
            {typeof(props.header) === 'function' ? props.header(opened) : props.header}
        </TouchableOpacity>
        <Collapsible collapsed={!opened}>
            {props.children}
        </Collapsible>
    </View>
}
import React, { useEffect, useState } from 'react';
import { Platform, Text, View, Image, TouchableOpacity, TextInput, ActivityIndicator, ScrollView } from 'react-native';
import Api from './../api/Api';
import Helpers from '../utils/Helpers';
import { addToCart, removeFromCart, getCart } from '../store/cartsReducer';
import MaterialIcons from 'react-native-vector-icons/MaterialIcons';
import Entypo from 'react-native-vector-icons/Entypo';
import MaterialCommunityIcons from 'react-native-vector-icons/MaterialCommunityIcons';
import Colors from '../constants/Colors';
import { Event } from 'expo-analytics';
import Modal from 'react-native-modalbox';
import store from '../store/store';
import GlobalStyles from '../constants/GlobalStyles';
import MyQtdInput from './MyQtdInput';
import { setProductToActiveCart } from './../store/cartsReducer';
import { connect } from 'react-redux';
import SosHelpers from '../utils/SosHelpers';
import { ProductLeadTimeComponent } from './Product';
var Big = require('big.js');

class ModalProduct extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            qtdNow: props.productOnCart ? props.productOnCart.Quantity : props.product.QtyToDisplayPrice,
            comments: props.productOnCart && props.productOnCart.Comments ? props.productOnCart.Comments : ''
        }
    }

    componentWillReceiveProps(nextProps) {
        this.setState({ comments: nextProps.productOnCart && nextProps.productOnCart.Comments ? nextProps.productOnCart.Comments : '' })
        this.setState({ qtdNow: nextProps.productOnCart && nextProps.productOnCart.Quantity ? nextProps.productOnCart.Quantity : nextProps.product.QtyToDisplayPrice });
    }

    addToCartPress = () => {
        // nao tava no carrinho e quer add zero? nao pode
        if (!this.props.productOnCart && this.props.product.QtyInStock <= 0 && this.props.product.TrackStock) {
            return;
        } else {
            // ja estava no carrinho?
            if (this.props.productOnCart) {
                // mudou pra zero eh pra remover
                if (this.state.qtdNow == 0) {
                    Api.Analytics.event(new Event("RemoveCart", this.props.product.Id));
                    this.props.dispatch(removeFromCart(this.props.product, this.props.activeCartId));
                } else {
                    Api.Analytics.event(new Event("ChangeProductOnCart", this.props.product.Id, 'qtd', this.state.qtdNow));
                    Api.Analytics.event(new Event("ChangeProductOnCart", this.props.product.Id, 'comments', this.state.comments));
                    this.props.dispatch(setProductToActiveCart({ ...this.props.product, Quantity: this.state.qtdNow, Comments: this.state.comments }));
                }
            }
            else // novo no carrinho
            {
                Api.Analytics.event(new Event("AddToCart", this.props.product.Id, 'qtd', this.state.qtdNow));
                Api.Analytics.event(new Event("ChangeProductOnCart", this.props.product.Id, 'comments', this.state.comments));
                this.props.dispatch(setProductToActiveCart({ ...this.props.product, Quantity: this.state.qtdNow, Comments: this.state.comments }));
            }
            // ok, close
            this.props.onClosed();
        }
    }

    render() {
        let outOfStock = this.props.product.QtyInStock <= 0 && this.props.product.TrackStock;
        var BContent = <TouchableOpacity onPress={() => {
            this.props.onClosed()
        }} style={{
            margin: 10,
            backgroundColor: "#3B5998",
            padding: 10,
            position: "absolute",
            top: 0,
            right: 0,
            width: 50,
            height: 50,
            backgroundColor: "transparent",
            overflow: 'hidden'
        }}></TouchableOpacity>;
        return <Modal isOpen={!!this.props.product} swipeToClose={false} backdropPressToClose={false}
            onClosed={() => {
                this.props.onClosed();
            }}
            style={ModalStyle} position={"center"} backdropContent={BContent}>
            {this.props.product &&
                <ScrollView style={{ alignSelf: 'stretch', padding: 8, paddingHorizontal: 12 }}>
                    {/* CLOSE BTN */}
                    <TouchableOpacity style={{ padding: 15, position: 'absolute', right: 0, top: 0, zIndex: 9 }}
                        onPress={() => this.props.onClosed()}>
                        <MaterialCommunityIcons name="close" size={26} color={Colors.themeColor} />
                    </TouchableOpacity>
                    {/* FOTO DO PRODUTO */}
                    <View style={{
                        alignSelf: 'center',
                        width: 220, height: 220, backgroundImage: `url('${this.props.product.PhotoURL}')`, display: 'flex', borderRadius: 5, overflow: 'hidden',
                        backgroundRepeat: 'no-repeat', backgroundPosition: 'center center', backgroundSize: 'contain', margin: 4
                    }}>
                        {Platform.OS != 'web' && <Image style={{ width: 220, height: 220, resizeMode: 'contain' }} source={{ uri: this.props.product.PhotoURL }} />}
                    </View>
                    {/* NOME E PRECO */}
                    <View style={{ flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', paddingVertical: 10 }}>
                        <Text style={{
                            ...GlobalStyles.textBase, color: Colors.darkText, fontWeight: 'bold',
                            fontFamily: 'MontserratBold', fontSize: 16
                        }}>{this.props.product.Name}</Text>
                        <Text style={{
                            ...GlobalStyles.textBase, color: Colors.secondaryText, fontWeight: 'bold',
                            fontFamily: 'Montserrat', fontSize: 16, marginLeft: 10, minWidth: 80, textAlign: 'right'
                        }}>
                            {SosHelpers.getProductPriceToDisplay(this.props.product)}
                        </Text>
                    </View>
                    {/* DESCRICAO */}
                    {this.props.product.Description &&
                        <View style={{ paddingVertical: 10, paddingTop: 5 }}>
                            <Text style={{ ...GlobalStyles.textBase, color: Colors.secondaryText, textAlign: 'justify' }}>
                                {this.props.product.Description}
                            </Text>
                        </View>
                    }

                    {/* INGREDIENTES */}
                    {!!this.props.product.Ingredients ?
                        <Text style={{ ...GlobalStyles.textBase, paddingVertical: 10, fontSize: 10, color: Colors.darkText, textAlign: 'justify' }}>Ingredientes: {this.props.product.Ingredients}</Text>
                        : null}


                    {/* SELLER E LEAD TIME*/}
                    <View style={{ flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', paddingVertical: 10 }}>
                        {!!this.props.product.StoreName ?
                            <View style={{ flexDirection: 'row', alignItems: 'center', justifyContent: 'flex-start', paddingVertical: 5, paddingBottom: 10 }}>
                                <Entypo style={{ color: Colors.secondaryTextColor, marginRight: 4 }} size={14} name="shop" />
                                <Text style={{ ...GlobalStyles.textBase, color: Colors.secondaryTextColor, fontSize: 14 }}> {this.props.product.StoreName}</Text>
                            </View>
                            : null}
                        {this.props.product.LeadTime == -1 ? null :
                            <ProductLeadTimeComponent leadTimeInHours={this.props.product.LeadTimeInHours} />
                        }
                    </View>

                    {/* OBSERVACOES */}
                    <View style={{}}>
                        <View style={{ flexDirection: 'row', justifyContent: 'space-between' }}>
                            <Text style={{ ...GlobalStyles.titleText, color: Colors.secondaryText, fontSize: 14 }}>
                                Observações
                            </Text>
                            <Text style={{ ...GlobalStyles.titleText, color: Colors.secondaryText, fontSize: 11 }}>
                                {this.state.comments.length}/200
                            </Text>
                        </View>
                        <TextInput numberOfLines={2} multiline value={this.state.comments}
                            onChangeText={(t) => this.setState({ comments: t })}
                            placeholder={'Algum comentário?'} maxLength={200}
                            style={{
                                paddingHorizontal: 8, paddingVertical: 8, //height: 60,
                                borderRadius: 5, borderWidth: 2, borderColor: Colors.lines
                            }} />
                    </View>

                    {/* BOTAO DE COMPRAR */}
                    <View style={{ flexDirection: 'row', justifyContent: 'flex-end', alignItems: 'center', marginVertical: 10 }}>
                        {/* INPUT DE QTD */}
                        <MyQtdInput onChangeValue={(v) => {
                            this.setState({ qtdNow: v > 0 ? v : (this.props.productOnCart ? 0 : this.props.product.MinQtyPerSale) })
                        }}
                            style={{ borderWidth: 1, borderColor: Colors.lines, borderRadius: 5, height: 42, padding: 5 }}
                            value={this.state.qtdNow}
                            appendText={this.props.product.QtyName}
                            min={this.props.product.MinQtyPerSale}
                            step={this.props.product.QtyStep} />
                        {/* BOTAO */}
                        <TouchableOpacity style={{
                            flexDirection: 'row', minWidth: 165, marginLeft: 10, maxWidth: Helpers.percentW(100) - 200,
                            borderRadius: 5, paddingVertical: 5, paddingHorizontal: 12,
                            backgroundColor: Colors.themeColor, height: 42,
                            justifyContent: (this.props.productOnCart && this.state.qtdNow == 0) ? 'center' : 'space-between',
                            alignItems: 'center',
                            opacity: outOfStock ? 0.5 : 1
                        }}
                            onPress={() => this.addToCartPress()}>
                            {/* <MaterialIcons style={{ color: Colors.buttonText, marginRight: 6 }} size={14} name="shopping-cart" /> */}
                            <Text style={{ ...GlobalStyles.textBase, fontSize: 14, fontWeight: 'bold', fontFamily: 'MontserratBold', color: Colors.buttonText }}>
                                {outOfStock ? 'Esgotado' :
                                    this.props.productOnCart ?
                                        (this.state.qtdNow == 0 ? 'Remover' : 'Atualizar')
                                        : 'Adicionar'}
                            </Text>
                            {this.state.qtdNow > 0 &&
                                <Text style={{ ...GlobalStyles.textBase, fontSize: 14, fontWeight: 'bold', fontFamily: 'MontserratBold', color: Colors.buttonText }}>
                                    {Helpers.FloatToReais(Number(new Big(this.props.product.Price).times(this.state.qtdNow)))}
                                </Text>
                            }
                        </TouchableOpacity>
                    </View>

                    {/* <MyPanel title="Descrição">
                <Text>{this.props.product.Description}</Text>
              </MyPanel>
              <MyPanel title="Ingredientes">
                <Text>{this.props.product.Description}</Text>
              </MyPanel> */}
                    {/* <Text>{JSON.stringify(this.props.product)}</Text> */}
                </ScrollView>
            }
        </Modal>
    }
}

const BgModalStyle = {
    position: 'absolute',
    zIndex: 999,
    margin: 0,
    backgroundColor: "rgba(0,0,0,0.1)",
    flex: 10,
    justifyContent: 'center',
    alignItems: 'center',
    top: 0,
    right: 0,
    left: 0,
    bottom: 0,
};
const ModalStyle = {
    justifyContent: 'flex-start', alignItems: 'center',
    //minHeight: 250,
    //height: Helpers.percentH(46),
    height: null,
    //width: Helpers.percentW(85),
    maxHeight: Helpers.percentH(85),
    maxWidth: Helpers.Min(480, Helpers.percentW(100) - 15),
    borderRadius: 10,
    overflow: 'hidden',
    backgroundColor: '#fff'
};

const ConnectedModalProduct = connect(
    (state, ownProps) => {
        let activeCartProducts = getCart(state.cart.carts, state.cart.activeCartId).products;
        var productOnCart = ownProps.product &&
            Object.keys(activeCartProducts).filter(x => x == ownProps.product.Id).length > 0 ?
            activeCartProducts[ownProps.product.Id] : null;
        return {
            activeCartId: state.cart.activeCartId,
            productOnCart
        }
    },
    null)(ModalProduct);

export default ConnectedModalProduct;

// componente que wrappa o modal, recebe só o prodId nos props 
// (ele baixa na API e dps mostra o modal de produto)
export const ModalProductWithGet = (props) => {
    const [prod, setProd] = useState(null);
    useEffect(() => {
        if (props.prodId) Api.Products.getProducts(Number(props.prodId), props.storeId).then(x => {
            if (x && x.data && x.data.length > 0 && x.data[0].Id == props.prodId) {
                setProd(x.data[0]);
            }
        })
        else setProd(null);
    }, [props.prodId])
    return !prod && props.prodId ? // nao tem produto completo mas ja tem Id, então mostra o modal de loading
        <View style={BgModalStyle}><View style={ModalStyle}><ActivityIndicator size={'large'} color={Colors.themeColor} style={{ margin: 15 }} /></View></View>
        : prod ? // ja tem produto completo, mostra modal
            <View style={BgModalStyle}><ConnectedModalProduct product={prod} onClosed={() => props.onClosed()} /></View>
            : null; // modal escondido
}
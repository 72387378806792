import * as React from 'react';
import { View, TouchableOpacity, Text, ScrollView } from 'react-native';
import { Button, Dialog, Portal, ActivityIndicator } from 'react-native-paper';
import axios from 'axios';
import Api from './../api/Api';
import Colors from '../constants/Colors';
import Helpers from '../utils/Helpers';
import { Linking } from 'expo';
import { Platform } from '@unimodules/core';
import MaterialCommunityIcons from 'react-native-vector-icons/MaterialCommunityIcons';
import Feather from 'react-native-vector-icons/Feather';
import { Event } from 'expo-analytics';
import GlobalStyles from '../constants/GlobalStyles';
import Cart, { ConnectedTotalsComponent } from './Cart';


export default class ConfirmSaleDialog extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      visible: this.props.opened,
      saleId: this.props.saleId,
      message: '...'
    }
  }

  componentWillReceiveProps(nextProps) {
    this.setState({ visible: nextProps.opened, saleId: nextProps.saleId });
    this.taimaut = setTimeout(this.getSaleStatus, 3000);
  }
  _showDialog = () => this.setState({ visible: true });
  _hideDialog = () => {
    this.setState({ visible: false });
    clearTimeout(this.taimaut);
    if (this.props.onHideDialog)
      this.props.onHideDialog(this.state.status == 'Confirmed');
  }

  componentDidMount() {
    setTimeout(this.getSaleStatus, 3000)
  }

  getSaleStatus = () => {
    if (!this.state.saleId) return;
    this.setState({ loading: true })
    Api.GetMarketPlaceSaleStatus(this.state.saleId)
      .then(data => {
        console.log('got sale status', data)
        if (data.data && data.data.status) {
          Api.Analytics.event(new Event("Confirmation", 'Received_' + data.data.status));
          this.setState({ message: data.data.msg, status: data.data.status, loading: false })
        } else {
          setTimeout(this.getSaleStatus, 3000)
        }
      })
      .catch(() => {
        console.log('post Request canceled');
        setTimeout(this.getSaleStatus, 3000)
        //this.setState({ loading: false })
      });
  }

  render() {
    const whatsappBtn = <Button mode="contained" style={{ marginBottom: 10, marginTop: 10, backgroundColor: '#1ebea5' }}
      onPress={() => {
        if (Platform.OS == 'web') {
          window.open('https://api.whatsapp.com/send?phone=5521975005115', '_blank')
        } else {
          Linking.openURL('https://api.whatsapp.com/send?phone=5521975005115')
        }
      }
      }>
      <MaterialCommunityIcons name={'whatsapp'} size={16} style={{ color: 'white', padding: 3 }} />
      WhatsApp (21)97500-5115
    </Button>;

    return (
      <Portal>
        <Dialog
          style={{ width: 420, alignSelf: 'center', maxWidth: Helpers.percentW(95), position: 'absolute', top: Helpers.percentH(8) }}
          dismissable={false}
          visible={this.state.visible}
          onDismiss={this._hideDialog}>
          <Dialog.Title>Confirmação de entrega</Dialog.Title>
          <Dialog.Content style={{ paddingBottom: 0, paddingLeft: 0, paddingRight: 0 }}>
            <TouchableOpacity style={{ position: 'absolute', right: 22, top: -42 }} onPress={this._hideDialog}>
              <Feather name="x" size={22} color={Colors.themeColor} />
            </TouchableOpacity>

            <ScrollView style={{ minHeight: 155, maxHeight: Helpers.percentH(78) }} contentContainerStyle={{ paddingHorizontal: 10 }}>
              {
                this.state.status == 'Confirmed' // 🛵🆘🍫👏😃😔
                  ?
                  <View style={{ flexDirection: 'column', justifyContent: 'flex-start' }}>
                    <Text style={{ ...GlobalStyles.textBase, textAlign: 'center', paddingBottom: 10 }}>Pagamento autorizado! Pedido confirmado!</Text>
                    {/* <Text style={{ ...GlobalStyles.textBase, textAlign: 'center', fontSize: 18 }}>🛵🍫😃</Text> */}
                    <Feather name="check-circle" size={32} color={Colors.success} style={{ alignSelf: 'center' }} />
                    <Text style={{ ...GlobalStyles.textBase, textAlign: 'center', paddingTop: 10 }}>{this.state.message}</Text>
                    {whatsappBtn}
                    <Text style={{ ...GlobalStyles.textBase, textAlign: 'center', paddingTop: 10 }}>Veja o resumo do seu pedido:</Text>
                    <Cart reviewMode forcedShippingInfo={this.props.forcedShippingInfoForReview} />
                    <ConnectedTotalsComponent />
                    <Text style={{ ...GlobalStyles.textBase, textAlign: 'center', paddingTop: 10 }}>Obrigado por comprar na SOS Chocolate!</Text>
                    <TouchableOpacity onPress={() => this._hideDialog()} style={{ marginVertical: 20, alignItems: 'center', ...GlobalStyles.defaultLinkButton }}>
                      <Text style={{ ...GlobalStyles.defaultLinkButtonText }}>Voltar para a loja</Text>
                    </TouchableOpacity>
                  </View>
                  :
                  this.state.status == 'Denied'
                    ?
                    <View>
                      <Text style={{ ...GlobalStyles.textBase, textAlign: 'center', paddingBottom: 10 }}>Pedido não confirmado! O pagamento será estornado em seu cartão!</Text>
                      {/* <Text style={{ ...GlobalStyles.textBase, textAlign: 'center', fontSize: 18 }}>😔</Text> */}
                      <Feather name="check-circle" size={32} color={Colors.secondaryText} style={{ alignSelf: 'center' }} />
                      <Text style={{ ...GlobalStyles.textBase, textAlign: 'center', paddingTop: 10, paddingBottom: 10 }}>{this.state.message}</Text>
                      {whatsappBtn}
                      <TouchableOpacity onPress={() => this._hideDialog()} style={{ marginVertical: 20, alignItems: 'center', ...GlobalStyles.defaultLinkButton }}>
                        <Text style={{ ...GlobalStyles.defaultLinkButtonText }}>Voltar para a loja</Text>
                      </TouchableOpacity>
                    </View>
                    :
                    <View style={{ flexDirection: 'column', justifyContent: 'flex-start' }}>
                      <Text style={{ ...GlobalStyles.textBase, textAlign: 'center', paddingBottom: 15 }}>Pagamento autorizado! {'\n'}Aguardando confirmação da equipe de entrega:</Text>
                      <ActivityIndicator color={Colors.themeColor} />
                      <Text style={{ ...GlobalStyles.textBase, textAlign: 'center', paddingTop: 15 }}>Não feche esta janela.</Text>
                      <Text style={{ ...GlobalStyles.textBase, textAlign: 'center', paddingTop: 15, fontSize: 9 }}>Se a entrega não for confirmada o pagamento será estornado em seu cartão.</Text>
                    </View>
              }
            </ScrollView>
          </Dialog.Content>
          {/* <Dialog.Actions style={{ justifyContent: 'space-between' }}>
            <Button onPress={this._hideDialog}>Voltar</Button>
          </Dialog.Actions> */}
        </Dialog>
      </Portal >
    );
  }
}
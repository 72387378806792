import React from 'react';
import { View, Text, Dimensions, TouchableOpacity, ScrollView, Animated } from 'react-native';

const ViewportNames = {
    Desktop: 'desktop',
    Tablet: 'tablet',
    Mobile: 'mobile',
}

export default class ResponsiveColumns extends React.Component {

    constructor(props) {
        super(props);
        var _window = Dimensions.get('window');
        var cview = this.getViewportName(_window.width);
        this.state = {
            ...this.getScreenStateFromViewport(cview),
            shadowOn: true,
            contentHeight: _window.height,
            contentWidth: _window.width,
            lastWindowWidth: _window.width
        };
        Dimensions.addEventListener('change', (e) => {
            const { width, height } = e.window;
            if (this.state.lastWindowWidth != width) {
                this.setState(this.getScreenStateFromViewport(this.getViewportName(width)));
            }
        });
        if (props.myref) props.myref(this);
    }

    getViewportName = (width) => width < 768 ? ViewportNames.Mobile : width < 992 ? ViewportNames.Tablet : ViewportNames.Desktop;

    getScreenStateFromViewport = (currentViewport) => {
        var stt = {
            currentViewport: currentViewport,
            rightOpened: currentViewport == ViewportNames.Mobile || currentViewport == ViewportNames.Tablet ? false : true,
            leftOpened: currentViewport == ViewportNames.Mobile ? false : true,
        }
        return {
            ...stt,
            shadowOn: stt.currentViewport == ViewportNames.Mobile && stt.leftOpened ? true :
                stt.currentViewport == ViewportNames.Mobile || stt.currentViewport == ViewportNames.Tablet && stt.rightOpened ? true
                    : false
        }
    }
    isShadowNeeded = (stt) => {
        return stt.currentViewport == ViewportNames.Mobile && stt.leftOpened ? true :
            (stt.currentViewport == ViewportNames.Mobile || stt.currentViewport == ViewportNames.Tablet) && stt.rightOpened ? true
                : false
    }

    measureContent = () => {
        this.mycontent.measure((ox, oy, width, height, px, py) => this.setState({ contentWidth: width, contentHeight: height }));
    }

    render() {
        var columnWidth = this.props.columnWidth || (this.state.currentViewport == ViewportNames.Mobile ? 280 : this.state.currentViewport == ViewportNames.Tablet ? 300 : 320);
        var ww = Dimensions.get('window').width;
        var toggleLeftScreen = () => this.setState({ leftOpened: !this.state.leftOpened })
        var toggleRightScreen = () => this.setState({ rightOpened: !this.state.rightOpened })
        var viewState = {
            ...this.state,
            toggleLeftScreen: toggleLeftScreen,
            toggleRightScreen: toggleRightScreen,
        };
        return <View style={[styles.container, { maxWidth: ww, overflow: 'hidden' }, { ...this.props.style }]}
            onLayout={this.measureContent} ref={(c) => { this.mycontent = c }}>

            {this.props.renderLeft &&
                <FloatingColumn side='left' style={this.props.leftStyle} width={columnWidth}
                    windowWidth={ww} windowHeight={this.state.contentHeight}
                    fixed={this.state.currentViewport == ViewportNames.Desktop || this.state.currentViewport == ViewportNames.Tablet}
                    opened={this.state.leftOpened} onClose={() => this.setState({ leftOpened: false })}>
                    {this.props.renderLeft(viewState)}
                </FloatingColumn>
                // <View style={[{ width: columnWidth, zIndex: 9, height: '100%', maxHeight: '100%' },
                // { ...this.props.leftStyle },
                // !this.state.leftOpened ?
                //     { position: 'absolute', right: ww }
                //     :
                //     // aberto:
                //     (this.state.currentViewport == ViewportNames.Mobile)
                //         ? // no mobile essa coluna vem voando
                //         { position: 'absolute', right: ww - columnWidth }
                //         : // no desktop e tablet fica fixa mesmo
                //         { zIndex: 2 }
                // ]}>
                //     {this.props.renderLeft(viewState)}
                // </View>
            }
            <View style={{ flex: 3 }}>
                {this.props.renderCenter(viewState)}
            </View>
            {this.props.renderRight &&
                <FloatingColumn side='right' style={this.props.rightStyle} width={columnWidth}
                    windowWidth={ww} windowHeight={this.state.contentHeight}
                    fixed={this.state.currentViewport == ViewportNames.Desktop}
                    opened={this.state.rightOpened} onClose={() => this.setState({ rightOpened: false })}>
                    {this.props.renderRight(viewState)}
                </FloatingColumn>
            }
            {/* {this.isShadowNeeded(this.state) ?
                <View onPress={() => { this.setState({ shadowOn: false }); console.log('!!!!!!!!'); }}
                    style={{
                        display: 'flex', position: 'absolute', width: ww, height: this.state.contentHeight, alignItems: 'stretch',
                        backgroundColor: 'rgba(0,0,0,0.0)', zIndex: 3, overflow: 'hidden'
                    }}>
                    <TouchableOpacity mode="contained"
                        onPress={() => {
                            this.setState({
                                leftOpened: this.state.currentViewport == ViewportNames.Mobile ? false : true,
                                rightOpened: this.state.currentViewport == ViewportNames.Tablet || this.state.currentViewport == ViewportNames.Mobile ? false : true
                            });
                        }}
                        style={{
                            position: 'relative', width: ww, height: this.state.contentHeight, backgroundColor: 'rgba(0,0,0,0.2)',
                            zIndex: 4, justifyContent: 'center', alignItems: 'center'
                        }}
                    >
                        <View style={{ width: ww, height: this.state.contentHeight }}><Text></Text></View>
                    </TouchableOpacity>
                </View>
                : null
            } */}
        </View>
    }
}

export class FloatingColumn extends React.Component {
    constructor(props) {
        super(props);
        //if (!props.fixed) {
            this.animVal = new Animated.Value(this.getDesiredPosition(props.side, props.opened, props.width, props.windowWidth));
            this.animValShadow = new Animated.Value(props.opened ? 1 : 0);
        //}
    }
    getDesiredPosition = (side, opened, width, windowWidth) => {
        if (side == 'right') {
            if (opened) return windowWidth - width;
            else return windowWidth;
        } else {
            if (opened) return windowWidth - width;
            else return windowWidth;
        }
        return side == 'left' ? (opened ? (windowWidth - width) : windowWidth)
            : (opened ? (windowWidth - width) : width)
    }
    componentWillReceiveProps(nextProps) {
        if (!nextProps.fixed && this.props.opened != nextProps.opened) {
            Animated.timing(this.animVal, {
                toValue: this.getDesiredPosition(nextProps.side, nextProps.opened, nextProps.width, nextProps.windowWidth),
                duration: 350,
            }).start();
            Animated.timing(this.animValShadow, {
                toValue: nextProps.opened ? 1 : 0,
                duration: 350,
            }).start();
        }
    }

    render() {
        var { side, style, width, windowWidth, windowHeight, fixed, opened } = this.props;
        if (fixed) {
            return <View key={'fuckReact'} style={[{ width: width, zIndex: side == 'left' ? 6 : 9, height: '100%', maxHeight: '100%' },style]}>
                {this.props.children}
            </View>
        }
        return [<Animated.View key={'fuckReact'} style={[{ width: width, zIndex: side == 'left' ? 6 : 9, height: '100%', maxHeight: '100%' },
            style,
        // fixed
        fixed ? {} :
            {
                position: 'absolute',
            },
        side == 'left' ? { right: this.animVal } : { left: this.animVal }
        ]}>
            {this.props.children}
        </Animated.View>,
        !this.props.fixed && this.props.opened ?
            <Animated.View key={'fuckReact2'} style={{
                display: 'flex', position: 'absolute', width: windowWidth, height: windowHeight, alignItems: 'stretch',
                backgroundColor: 'rgba(0,0,0,0.2)', zIndex: side == 'left' ? 5 : 8, overflow: 'hidden', opacity: this.animValShadow
            }}>
                <TouchableOpacity mode="contained"
                    onPress={() => { this.props.onClose() }}
                    style={{
                        position: 'relative', width: windowWidth, height: windowHeight, backgroundColor: 'rgba(0,0,0,0)',
                        zIndex: 4, justifyContent: 'center', alignItems: 'center'
                    }}
                >
                    <View style={{ width: windowWidth, height: windowHeight }}><Text></Text></View>
                </TouchableOpacity>
            </Animated.View>
            : null
        ]
    }
}

const styles = {
    container: {
        flex: 1,
        backgroundColor: '#fff',
        flexDirection: 'row',
        alignItems: 'stretch',
        justifyContent: 'center',
        alignSelf: 'stretch'
    },
};


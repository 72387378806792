import AsyncStorage from '@react-native-async-storage/async-storage';
import { createStore, combineReducers, compose, applyMiddleware } from 'redux'
import { persistStore, autoRehydrate } from 'redux-persist'
import thunk from 'redux-thunk'
import { logger } from 'redux-logger'
import localForage from 'localforage'
import { catalogReducer } from './catalogReducer'
import { salesReducer } from './salesReducer'
import { cartsReducer } from './cartsReducer'
import { authReducer } from './authReducer'
import { usersReducer } from './usersReducer';
import { customerUserReducer } from './customerUserReducer';
import { cardsReducer } from './cardsReducer';
import { Platform } from 'react-native';
import { routeReducer } from './routeReducer'

const initStore = () => {
    const store = createStore(
      combineReducers({
        cart: cartsReducer,
        catalog: catalogReducer,
        creditcards: cardsReducer,
        sales: salesReducer,
        auth: authReducer,
        route: routeReducer,
        users: usersReducer,
        customerUser: customerUserReducer,
        //connectivity: connectivityReducer
      }),
      {},
      compose(autoRehydrate(), applyMiddleware(thunk/* ,logger*/))
    )
  
    const MyStorage = Platform.OS === 'web' ? localForage : AsyncStorage;
    var persistor = persistStore(store, { storage: MyStorage}, (a) => {
      //console.log('restored store:',JSON.stringify(a));
      //console.log('localForage:',localForage);
    })
  
    return store;
  }

  export default initStore();  
import * as React from 'react';
import { View, TouchableOpacity, Text, TextInput } from 'react-native';
import { Button, Dialog, Portal, ActivityIndicator } from 'react-native-paper';
import axios from 'axios';
import Api from './../api/Api';
import Colors from '../constants/Colors';
import Helpers from '../utils/Helpers';
import MyTextInput from './MyTextInput';
import GlobalStyles from './../constants/GlobalStyles';
import MaterialCommunityIcons from 'react-native-vector-icons/MaterialCommunityIcons';
import DropdownXP from './DropdownXP';

export default class AdressDialog extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      visible: this.props.opened,
      suggestions: [],
      query: ''
    }
  }

  componentWillReceiveProps(nextProps) {
    this.setState({ visible: nextProps.opened })
  }
  _showDialog = () => this.setState({ visible: true });
  _hideDialog = () => { this.setState({ visible: false }); if (this.props.onHideDialog) this.props.onHideDialog(); }

  getFilteredSuggestions = () => {
    return this.state.suggestions;
  }

  _timeout = 0;
  fetchSuggestions = () => {
    if (this._timeout > 0) clearTimeout(this._timeout);
    this._timeout = setTimeout(this.reallyFetch, 250);
  }
  cancelReq = null;
  reallyFetch = () => {
    if (this.state.query.length < 5) return;
    this.setState({ loading: true, outOfReach: false, address: null })
    Api.AddressSuggestions(this.state.query + ', Rio de Janeiro, Brasil')
      .then(data => {
        //console.log('data is back!', data);
        this.setState({ suggestions: data.data.predictions, noItemsFound: data.data.predictions.length === 0, loading: false })
      })
      .catch(() => {
        console.log('post Request canceled');
        this.setState({ loading: false })
      });
  }

  getAddressDetails = () => {
    this.setState({ loadingDetails: true });
    Api.GooglePlaceDetails(this.state.selectedAddress.place_id)
      .then(data => {
        var addr = this.getAddressFromGoogleResult(data.data.result);
        this.setState({ loadingDetails: false, address: addr, query: addr.nomeRua + ' ' + addr.numero });
      }).
      catch(() => {
        this.setState({ loadingDetails: false });
      })
  }

  getAddressFromGoogleResult = (result) => {
    var endereco = { resumo: '' };
    if (result && result.address_components) {
      var addr = result.address_components;
      endereco.numero = Helpers.FirstOrDefault(addr, x => Helpers.Any(x.types, null, 'street_number'), { short_name: '' }).short_name;
      if (!endereco.numero) endereco.veioSemNumero = true;
      endereco.nomeRua = Helpers.FirstOrDefault(addr, x => Helpers.Any(x.types, null, 'route'), { short_name: '' }).short_name;
      endereco.bairro = Helpers.FirstOrDefault(addr, x => Helpers.Any(x.types, null, 'sublocality_level_1'), { short_name: '' }).short_name;
      endereco.cidade = Helpers.FirstOrDefault(addr, x => Helpers.Any(x.types, null, 'administrative_area_level_2'), { short_name: '' }).short_name;
      endereco.estado = Helpers.FirstOrDefault(addr, x => Helpers.Any(x.types, null, 'administrative_area_level_1'), { short_name: '' }).short_name;
    }
    if (result.geometry && result.geometry.location) {
      endereco.lat = result.geometry.location.lat;
      endereco.lon = result.geometry.location.lng;
    }
    if (this.state.address) {
      endereco.complemento = this.state.address.complemento;
      endereco.referencia = this.state.address.referencia;
    }
    console.log('address:', endereco);
    endereco.resumo = endereco.nomeRua + ' ' + (endereco.numero.toString())
      + (endereco.complemento ? ', ' + endereco.complemento : '')
      + (endereco.referencia ? ' (' + endereco.referencia + ')' : '')
      + ', ' + endereco.cidade + ', ' + endereco.estado
    return endereco;
  }

  // getShippingRate = () => {
  //   Api.GetShipping(this.state.formattedAddress)
  //     .then(data => {
  //       console.log(data);
  //       this.setState({ address: this.state.formattedAddress, shipping: data, loadingDetails: false });
  //     }).catch(e => {
  //       this.setState({ outOfReach: true, loadingDetails: false })
  //     })
  // }

  geocodeAndConfirm = (forceNumeroValidation) => {
    var addr = this.state.address;
    this.setState({ loading: true })
    Api.Geocode(addr.nomeRua + ' ' + addr.numero + ', ' + addr.cidade + ', ' + addr.estado)
      .then(data => {
        debugger;
        if (data.data.results && data.data.results.length > 0) {
          var addr = this.getAddressFromGoogleResult(data.data.results[0]);
          if (forceNumeroValidation && this.state.address.numero != addr.numero) {
            return this.setState({ loading: false, feedbackMsg: 'Ops, o Google não encontrou um endereço com este número.' })
          }
          this.setState({ loading: false, address: addr }, this.confirmAddress);
        } else {
          this.setState({ loading: false, feedbackMsg: 'Ops, o Google não encontrou um endereço com este número.' });
        }
      })
  }

  confirmAddress = () => {
    if (!this.state.address.numero) return this.setState({ feedbackMsg: 'Ops, faltou o número do endereço.' });
    if (this.state.address.veioSemNumero) {
      // se ele veio sem numero da busca google e agora ja tem um numero é pq o user digitou na mão, 
      // então vamos geocodar o endereço e depois fechar o modal (pra pegar um lat lon correto)
      return this.geocodeAndConfirm(true);
    }
    if (this.props.onAddressSelect) {
      this.props.onAddressSelect({ address: this.state.address, shipping: this.state.shipping });
      this._hideDialog();
    }
  }

  geocodeThenConfirm = () => {

  }

  render() {
    var suggestions = this.getFilteredSuggestions();
    return (
      <Portal>
        <Dialog
          style={{ width: 420, alignSelf: 'center', maxWidth: Helpers.percentW(95), position: 'absolute', top: Helpers.percentH(8), marginHorizontal: 0 }}
          visible={this.state.visible}
          onDismiss={this._hideDialog}>
          {/* <Dialog.Title>Endereço de entrega</Dialog.Title> */}
          <View style={{ flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between' }}>
            <Text style={{ ...GlobalStyles.titleText, paddingLeft: 12 }}>Endereço de entrega</Text>
            <TouchableOpacity onPress={this._hideDialog} style={{ padding: 12 }}>
              <MaterialCommunityIcons name="close" color={Colors.themeColor} size={24} />
            </TouchableOpacity>
          </View>
          <Dialog.Content style={{ paddingBottom: 0 }}>
            <View style={{ height: 205 }}>
              {/* CIDADE E ESTADO */}
              <DropdownXP
                selectedValue={'Rio de Janeiro, RJ'}
                style={{ minHeight: 40, flex: 1, display: 'flex', flexDirection: 'row', marginBottom: 10,
                  borderWidth: 1, borderStyle: 'solid', borderRadius: 4, borderColor: '#ccc', padding: 8 }}
                onValueChange={(itemValue) => this.setState({ CidadeEstadoLOL: itemValue })}
                data={[
                  { label: "Rio de Janeiro, RJ", value: "1" },
                ]}
              />

              {/* QUERY / NOME RUA */}
              <TextInput style={{ fontSize: 16, borderBottomColor: Colors.themeColor, borderBottomWidth: 2, padding: 4, paddingVertical: 8 }}
                value={this.state.query} placeholder="Digite sua rua e número"
                onChangeText={text => this.setState({ query: text, noItemsFound: false, selectedAddress: null }, this.fetchSuggestions)} />

              {/* MSG ADDRESS NOT FOUND */}
              {this.state.noItemsFound &&
                <View style={{ alignItems: 'center', padding: 25 }}>
                  <Text style={{ ...GlobalStyles.textBase, color: Colors.secondaryText }}>Endereço não encontrado</Text>
                </View>}

              {/* LISTA DE SUGESTOES */}
              {this.state.address == null ?
                <View>
                  {suggestions && suggestions.slice(0, 3).map(item => (
                    <TouchableOpacity onPress={() => this.setState({ query: item.description, selectedAddress: item }, this.getAddressDetails)}>
                      <Text numberOfLines={1} style={{
                        padding: 10, backgroundColor: '#fafafa',
                        borderBottomWidth: 1, borderBottomColor: '#ccc'
                      }}>
                        📍 {item.description}
                      </Text>
                    </TouchableOpacity>
                  ))}
                </View>
                :
                // LOADING
                this.state.loadingDetails ?
                  <View style={{ paddingTop: 10, flexDirection: 'column', justifyContent: 'flex-start' }}>
                    <ActivityIndicator color={Colors.themeColor} />
                  </View>
                  // 
                  // ENDERECO SELECIONADO, form para resto dos dados
                  :
                  <View style={{ paddingTop: 10, display: 'flex', flex: 1, flexDirection: 'column', justifyContent: 'flex-start' }}>
                    <View style={{ flexDirection: 'row', flex: 1 }}>
                      {this.state.address.veioSemNumero &&
                        <MyTextInput style={{ flex: 1, marginRight: 10 }} label={'Número'} value={this.state.address && this.state.address.numero}
                          onChangeText={(t) => this.setState({ address: { ...this.state.address, numero: t } })} />
                      }
                      <MyTextInput style={{ flex: 2, marginRight: 10 }} label={'Complemento'} value={this.state.address && this.state.address.complemento}
                        onChangeText={(t) => this.setState({ address: { ...this.state.address, complemento: t } })} />
                      <MyTextInput style={{ flex: 2, }} label={'Ponto de referência'} value={this.state.address && this.state.address.referencia}
                        onChangeText={(t) => this.setState({ address: { ...this.state.address, referencia: t } })} />
                    </View>
                    {/* <Paragraph>Seus dados:</Paragraph>
                    <View style={{ flexDirection: 'row', flex: 1 }}>
                      <MyTextInput style={{ flex: 1, marginLeft: 10 }} label={'Seu nome'} value={this.state.address && this.state.address.nome}
                        onChangeText={(t) => this.setState({ address: { ...this.state.address, nome: t } })} />
                      <MyTextInput style={{ flex: 1, marginLeft: 10 }} label={'Tel. p/ contato'} value={this.state.address && this.state.address.celular}
                        onChangeText={(t) => this.setState({ address: { ...this.state.address, celular: t } })} />
                    </View> */}
                  </View>
              }
            </View>
          </Dialog.Content>
          {this.state.feedbackMsg && <Text style={{ padding: 12, ...GlobalStyles.textBase, color: Colors.red }}>{this.state.feedbackMsg}</Text>}
          <Dialog.Actions style={{ justifyContent: 'flex-end' }}>
            {/* <Button onPress={this._hideDialog}>Voltar</Button> */}
            {this.state.loading || this.state.loadingDetails ?
              <ActivityIndicator color={Colors.themeColor} size="small" style={{ marginRight: 15 }} />
              :
              this.state.address ?
                <Button mode="contained" onPress={() => this.confirmAddress()}>Confirmar</Button> : null
            }
          </Dialog.Actions>
        </Dialog>
      </Portal >
    );
  }
}
const SET_ROUTE = 'SET_ROUTE';

export const setRoute = (route) => {
    return { type: SET_ROUTE, payload: route }
}

const initialState = {
    route: '/'
}

export const routeReducer = (state = initialState, action) => {
    const { type, payload } = action

    switch (type) {
        case SET_ROUTE: {
            //console.log('SET_ROUTE:', payload)
            return {
                route: payload
            }
        }
        default:
            return state
    }
}
import React from 'react';
import { Platform, Text, View, Image, TouchableOpacity } from 'react-native';
import { TouchableRipple, ActivityIndicator } from 'react-native-paper';
import Api from './../api/Api';
import { connect } from 'react-redux'
import Helpers from '../utils/Helpers';
import { getCart, setProductToActiveCart, removeFromCart, setCart, sumProducts, getCartFullTotal, getCartFullShippingTotal } from '../store/cartsReducer';
import MyAccordion from './../components/MyAccordion';
import AddressDialog from './../components/AddressDialog';
import MaterialCommunityIcons from 'react-native-vector-icons/MaterialCommunityIcons';
import Colors from '../constants/Colors';
import GlobalStyles from '../constants/GlobalStyles';
import { setCustomerUser } from '../store/customerUserReducer';
import LinkXP from '../utils/LinkXP';
import { Link } from './router';
import MyPanel from './MyPanel';
import Entypo from 'react-native-vector-icons/Entypo';
import MyQtdInput from './MyQtdInput';
import { ProductLeadTimeComponent } from './Product';
var loggi = require('../assets/loggi.png')
//const isImageUrl = require('is-image-url');
var Big = require('big.js');
var moment = require('moment');
import 'moment/locale/pt';
import Feather from 'react-native-vector-icons/Feather';
import store from '../store/store';
import DropdownXP from './DropdownXP';
import MyTextInput from './MyTextInput';
import ProductRow from './ProductRow';
import { Masker } from './MyInputMask';

const cachedShippings = {};

class Cart extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            addressDialogOpened: false,
            address: props.customerUser ? props.customerUser.address : null
        }
        if (props.myref) props.myref(this);
    }

    componentDidMount() {
        this.getShippingRate()//this.state.address);
        if (this.props.forcedShippingInfo) {
            this.setForcedShippingInfo(this.props.forcedShippingInfo);
        }
    }

    componentWillReceiveProps(nextProps) {
        //console.log('will receive props', this.props, nextProps);
        if ((nextProps.customerUser && nextProps.customerUser.address && nextProps.customerUser.address != this.props.customerUser.address)
            ||
            (!this.props.activeCartShippingTotal == -1)
            ||
            this.props.activeCartTotalProds != nextProps.activeCartTotalProds
        ) {
            //if(this.props.customerUser.address != nextProps.customerUser.address || )
            if (nextProps.customerUser)
                this.setState({ address: nextProps.customerUser.address },
                    this.getShippingRate()//nextProps.customerUser ? nextProps.customerUser.address : null);
                );
        }

        // recebeu forced shipping info? (normalmente usado para mostrar o Cart como resumo do pedido, ai forçamos o shipping info para mostrar as mesmas estimativas de frete e etc que o user view na hora q estava comprando)
        if (nextProps.forcedShippingInfo && (this.props.forcedShippingInfo != nextProps.forcedShippingInfo)) {
            this.setForcedShippingInfo(nextProps.forcedShippingInfo);
        }
    }

    setProductQty = (prod) => {
        if (prod.Quantity == 0) {
            this.props.dispatch(removeFromCart(prod, this.props.activeCartId));
        } else {
            this.props.dispatch(setProductToActiveCart(prod));
        }
    }

    getShippingRate = () => {
        let address = this.state.address;
        // if(this.lastCheckedAddress == address)
        //     return;
        this.lastCheckedAddress = address;
        var prods = Helpers.ObjToArray(this.props.activeCart.products);
        var stt = this.state;
        this.getProdsPerStore(prods).map(s => {
            let deliveryMethod = stt['deliveryMethod' + s.StoreId];
            let scheduledDate = stt['scheduledDate' + s.StoreId];
            let scheduledPeriod = stt['scheduledPeriod' + s.StoreId];
            this.getShippingRateForStore(address, s.StoreId,
                s.Products.map(x => ({
                    ProductId: x.Id,
                    Quantity: x.Quantity
                })),
                deliveryMethod, scheduledDate, scheduledPeriod,
            );
        });
        // console.log('getShippingRate::', address);
        // if (!address) return;
        // this.setState({ loadingShipping: true });
        // Api.GetShipping(address)
        //     .then(data => {
        //         console.log(data);
        //         if (!isNaN(data.data)) {
        //             this.props.dispatch(setCart(this.props.activeCartId, { ...this.props.activeCart, shipping: data.data }));
        //             this.setState({ loadingShipping: false });
        //         }
        //     }).catch(() => {
        //         this.setState({ outOfReach: true, loadingShipping: false })
        //     })
    }

    getShippingRateForStore = (address, storeId, products, deliveryMethod, scheduledDate, scheduledPeriod) => {
        console.log('getShippingRateForStore::', address, storeId);
        if (!address || !storeId || this.props.forcedShippingInfo) return;
        this.setState({ ['loadingShipping' + storeId]: true });
        Api.GetShipping(address, storeId, deliveryMethod, scheduledDate, scheduledPeriod, JSON.stringify(products))
            .then(data => {
                //console.log(data);
                if (data.data && !isNaN(data.data.price)) {
                    this.props.dispatch(setCart(this.props.activeCartId, {
                        ...this.props.activeCart,
                        ['shippingFor' + storeId]: data.data
                    }));
                    this.setState({
                        ['loadingShipping' + storeId]: false,
                        ['outOfReach' + storeId]: false,
                    });
                    if (!this.state['deliveryMethod' + storeId]) {
                        this.setState({
                            ['deliveryMethod' + storeId]: data.data && data.data.deliveryMethods && data.data.deliveryMethods.length > 0 ? data.data.deliveryMethods[0].Name : 'Immediate'
                        }, () => {
                            if (this.state['deliveryMethod' + storeId] == 'Scheduled' && !this.state['scheduledDate' + storeId]) {
                                this.setState({ ['scheduledDate' + storeId]: moment(new Date()).add(1, 'days').format("DD/MM/YY") },
                                    () => { setTimeout(this.getShippingRate,1000) })
                            }
                        });
                    }
                }
            }).catch((e) => {
                console.log(e);
                // seta na store a info de shipping com outOfReach se der erro
                this.props.dispatch(setCart(this.props.activeCartId, {
                    ...this.props.activeCart,
                    ['shippingFor' + storeId]: {
                        price: 0,
                        //outOfReach: true,
                        ok: false,
                        message: 'Ocorreu um erro'
                    }
                }));
                // seta no state tambem, porque eh usado aqui no state tb!
                this.setState({
                    //['outOfReach' + storeId]: true,
                    ['loadingShipping' + storeId]: false
                })
            })
    }

    getShippingInfo = () => {
        var allShippingInfos = [];
        var stt = this.state;
        var prps = this.props;
        this.getProdsPerStore(this.props.prods).map(x => {
            debugger;
            allShippingInfos.push({
                StoreId: x.StoreId,
                // dados de preferencia do usuario
                DeliveryMethod: stt['deliveryMethod' + x.StoreId] ||
                    (prps.activeCart['shippingFor' + x.StoreId].deliveryMethods ?
                        prps.activeCart['shippingFor' + x.StoreId].deliveryMethods[0].Name
                        : 'Immediate'),
                ScheduledDate: stt['scheduledDate' + x.StoreId],
                ScheduledPeriod: stt['scheduledPeriod' + x.StoreId],
                // retorno da API para as preferencias do usuario
                ShippingReturn: prps.activeCart['shippingFor' + x.StoreId]
            })
        });
        return allShippingInfos;
    }
    // macete para injetar shipping info (ja calculada se quiser) nesse componente Cart
    setForcedShippingInfo = (shinfo) => {
        if (shinfo && shinfo.map) shinfo.map(x => {
            debugger
            this.setState({
                StoreId: x.StoreId,
                // dados de preferencia do usuario
                ['deliveryMethod' + x.StoreId]: x.DeliveryMethod,
                ['scheduledDate' + x.StoreId]: x.ScheduledDate,
                ['scheduledPeriod' + x.StoreId]: x.ScheduledPeriod,
                // retorno da API para as preferencias do usuario
                ['shippingFor' + x.StoreId]: x.ShippingReturn

            })
        })
    }

    getInnerCart = (storeWithProducts) => {
        let prods = storeWithProducts.Products;
        let storeId = storeWithProducts.StoreId;
        let storeName = storeWithProducts.StoreName;
        let maxLeadTimeInHours = Helpers.MaxFromArray(prods, 'LeadTimeInHours');
        let myShippingInfo = this.props.activeCart['shippingFor' + storeId];
        let isScheduled = this.state['deliveryMethod' + storeId] == 'Scheduled';
        let canChangeDeliveryMethod = myShippingInfo && myShippingInfo.deliveryMethods && myShippingInfo.deliveryMethods.length > 1;
        let notOk = !(myShippingInfo) || myShippingInfo.ok == false;
        return <View key={storeId}>

            {/* NOME DA LOJA QUE VENDE E EMPRESA QUE ENTREGA */}
            {
                storeName &&
                <View style={{ ...styles.rowStyle, paddingTop: 0, flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', alignSelf: 'stretch' }}>
                    <View style={{ flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'center', alignSelf: 'stretch', flexWrap: 'wrap', width: '100%' }}>
                        <Text style={{ ...GlobalStyles.textBase, color: Colors.secondaryTextColor }}>Vendido por </Text>
                        <Text style={{ ...GlobalStyles.textBaseBold, color: Colors.secondaryTextColor, fontWeight: 'bold' }}>{storeName}</Text>
                        {!this.props.productsOnly && myShippingInfo && <View style={{ flexDirection: 'row', alignItems: 'center' }}>
                            <Text style={{ ...GlobalStyles.textBase, color: Colors.secondaryTextColor }}> e entregue por </Text>
                            <TouchableRipple onPress={() => LinkXP.OpenURL('https://www.loggi.com.br', '_blank')}>
                                {myShippingInfo && myShippingInfo.companyName ?
                                    (myShippingInfo.companyName.toLowerCase() == 'loggi' ?
                                        <Image source={loggi} style={{ width: 46, height: 22, marginLeft: 4, resizeMode: 'contain' }} />
                                        :
                                        <Text style={{ ...GlobalStyles.textBaseBold, color: Colors.secondaryTextColor, fontWeight: 'bold' }}>
                                            {myShippingInfo.companyName}</Text>
                                    )
                                    :
                                    <Text style={{ ...GlobalStyles.textBaseBold, color: Colors.secondaryTextColor, fontWeight: 'bold' }}>{storeName}</Text>
                                }
                            </TouchableRipple>
                        </View>
                        }
                    </View>
                </View>
            }

            {/* BOX DE PRODUTOS DO CARRINHO DA LOJA */}
            {/* {!this.props.productsOnly &&

                <View style={{ position: 'absolute', right: -3, top: -30, backgroundColor: Colors.bgColor, padding: 5 }}>
                    <ProductLeadTimeComponent leadTimeInHours={maxLeadTimeInHours} />
                </View>
            } */}
            {
                prods.map(prod => <ProductRow key={prod.Id}
                    onOpenProduct={this.props.onOpenProduct}
                    setProductQty={this.setProductQty}
                    reviewMode={this.props.reviewMode}
                    prod={prod} />)
            }

            {/* TIPO DE ENTREGA */}
            {
                // !this.props.productsOnly && prods.length > 0 && this.props.activeCart
                // &&
                // <View style={{ ...styles.rowStyle, flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', alignSelf: 'stretch', paddingBottom: 10, paddingTop: 10 }}>
                //     <View style={{ flexDirection: 'row', alignItems: 'center' }}>
                //         <Text style={{ ...GlobalStyles.textBase }}>Forma de envio:</Text>
                //         <DropdownXP
                //             selectedValue={this.state['deliveryMethod' + storeId]}
                //             style={{ minHeight: 40, flex: 1, display: 'flex', flexDirection: 'row', borderWidth: 1, borderStyle: 'solid', borderRadius: 4, borderColor: '#888', padding: 8 }}
                //             onValueChange={(itemValue) => this.setState({ ['deliveryMethod' + storeId]: itemValue })}
                //             data={[
                //                 { value: "Immediate", label: "Imediata" },
                //                 { value: "Scheduled", label: "Agendada" },
                //             ]}
                //         />
                //     </View>
                //     <View style={{ flexDirection: 'column', alignItems: 'flex-end', justifyContent: 'center' }}>
                //         {isScheduled ?
                //             <View style={{ flex: 1, flexDirection: 'row', minHeight: 72, }}>
                //                 <MyTextInput style={{ flex: 1, marginRight: 10 }} label={'Data da entrega'} value={this.state['scheduledDate' + storeId]}
                //                     onChangeText={(t) => this.setState({ ['scheduledDate' + storeId]: Masker.toPattern(t, '99/99/99') })} placeholder={'dd/mm/aa'} />
                //                 {/* <View style={{ flexDirection: 'column', minHeight: 60, flex: 1, }}>
                //                     <Text style={{ ...GlobalStyles.textBase, padding: 4 }}>Período do dia</Text>
                //                     <View style={{
                //                         flexDirection: 'column', minHeight: 40
                //                     }}>
                //                         <DropdownXP
                //                             selectedValue={this.state['scheduledPeriod' + storeId]}
                //                             style={{ minHeight: 40, flex: 1, display: 'flex', flexDirection: 'row', borderWidth: 1, borderStyle: 'solid', borderRadius: 4, borderColor: '#888', padding: 8 }}
                //                             onValueChange={(itemValue) => this.setState({ ['scheduledPeriod' + storeId]: itemValue })}
                //                             data={[
                //                                 { label: "Manhã", value: "1" },
                //                                 { label: "Tarde", value: "2" },
                //                                 { label: "Noite", value: "3" },
                //                             ]}
                //                         />
                //                     </View>
                //                 </View> */}
                //             </View>
                //             : null}
                //     </View>
                // </View>
            }

            {/* VALOR DO FRETE ou FEEDBACK SOBRE FORA DE AREA */}
            {!this.props.productsOnly &&
                prods.length > 0 &&
                <View style={{ ...styles.rowStyle, borderBottomWidth: 1, flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', alignSelf: 'stretch' }}>
                    <View style={{ flexDirection: 'row', alignItems: 'center' }}>
                        <Text style={{ ...GlobalStyles.textBase }}>Frete:</Text>
                        {/* <TouchableRipple onPress={() => LinkXP.OpenURL('https://www.loggi.com.br', '_blank')}>
                            <Image source={loggi} style={{ width: 46, height: 22, marginLeft: 4, resizeMode: 'contain' }} />
                        </TouchableRipple>
                        <Text style={{ ...GlobalStyles.textBase, color: '#0078a3' }}>:</Text> */}
                    </View>
                    {this.state['loadingShipping' + storeId] ?
                        <ActivityIndicator size="small" color={Colors.themeColor} />
                        :
                        this.state['outOfReach' + storeId]
                            ?
                            <Text style={{ ...GlobalStyles.textBase, fontSize: 13, textAlign: 'right' }}>
                                {'Fora da área de cobertura'}
                            </Text>
                            :
                            this.props.activeCart && myShippingInfo && (myShippingInfo != -1) // se tem algum valor no frete mostra o preco (ate 0 pode, eh frete gratis)
                                ?
                                <Text style={{ ...GlobalStyles.textBase, fontSize: 16, width: 120, textAlign: 'center' }}>
                                    {notOk ? '-' : myShippingInfo.price == 0 ? 'Frete grátis!' : Helpers.FloatToReais(myShippingInfo.price)}
                                </Text>
                                :
                                // sem endereco preenchido
                                <TouchableRipple onPress={() => { this.setState({ addressDialogOpened: true }) }}>
                                    <Text style={{ ...GlobalStyles.textBase, textDecorationLine: 'underline', color: Colors.themeColor }}>
                                        Informar endereço
                                    </Text>
                                </TouchableRipple>

                    }
                </View>
            }

            {/* INFORMAÇÕES SOBRE O ENVIO */}
            {!this.props.productsOnly &&
                prods.length > 0 && this.props.activeCart && myShippingInfo &&
                <View style={{ ...styles.rowStyle, paddingVertical: 0, flexDirection: 'column', borderBottomWidth: 0 }}>
                    <View style={{ flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', alignSelf: 'stretch', paddingBottom: 0, paddingTop: 10 }}>

                        {/* ENVIO ( e botão de agendar) */}
                        <View style={{ flexDirection: 'row', alignItems: 'center' }}>
                            <View style={{ flexDirection: 'row', alignItems: 'center' }}>
                                <Text style={{ ...GlobalStyles.textBase }}>Envio:</Text>
                                {!this.props.reviewMode && <>
                                    {isScheduled ? // --- ENTREGA IMEDIATA
                                        !canChangeDeliveryMethod ? // se só tem um delivery method nao mostra como botão, e sim como text
                                            <Text style={{ ...GlobalStyles.textBase, color: Colors.themeColor, }}>(agendado)</Text>
                                            : // mostrando como botão a opção de alterar a forma de entrega
                                            <TouchableOpacity style={{ ...GlobalStyles.defaultLinkButton }}
                                                onPress={() => this.setState({ ['deliveryMethod' + storeId]: 'Immediate' }, () => this.getShippingRate())}>
                                                <Text style={{ ...GlobalStyles.textBase, color: Colors.themeColor, }}>(<Text style={{ ...GlobalStyles.defaultLinkButtonText }}>alterar</Text>)</Text>
                                            </TouchableOpacity>
                                        : // --- ENTREGA AGENDADA
                                        !canChangeDeliveryMethod ? // se só tem um delivery method nao mostra como botão, e sim como text
                                            <Text style={{ ...GlobalStyles.textBase, color: Colors.themeColor, }}>(agendado)</Text>
                                            : // mostrand botao pra alterar forma de entrega
                                            <TouchableOpacity style={{ ...GlobalStyles.defaultLinkButton }}
                                                onPress={() => this.setState({ ['deliveryMethod' + storeId]: 'Scheduled', ['scheduledDate' + storeId]: moment(new Date()).add(1, 'days').format("DD/MM/YY") },
                                                    () => this.getShippingRate())}>
                                                <Text style={{ ...GlobalStyles.textBase, color: Colors.themeColor, }}>(<Text style={{ ...GlobalStyles.defaultLinkButtonText }}>agendar</Text>)</Text>
                                            </TouchableOpacity>
                                    }
                                </>}
                            </View>
                        </View>
                        {
                            // DEU ALGUM PROBLEMA?
                            notOk ?
                                <Text style={{ ...GlobalStyles.textBase, fontSize: 13 }}>
                                    {!(myShippingInfo) ? "..."
                                        : myShippingInfo.message}
                                </Text>
                                :
                                // Envio agendado? Mostra input de data
                                (isScheduled ?
                                    <MyTextInput style={{ marginLeft: 5, maxWidth: 120 }} label={'Data da entrega'} value={this.state['scheduledDate' + storeId]} inputStyle={{ textAlign: 'center' }}
                                        onChangeText={(t) => {
                                            // readonly?
                                            if (this.props.reviewMode) return;
                                            this.setState({ ['scheduledDate' + storeId]: Masker.toPattern(t, '99/99/99') },
                                                // se tem length == 6 no input ja parece data entao chama getShipping na API
                                                () => { if (Helpers.NumbersFromString(this.state['scheduledDate' + storeId]).length == 6) this.getShippingRate() })
                                        }
                                        }
                                        placeholder={'dd/mm/aa'} />
                                    :
                                    // se não mostra a previsão da entrega
                                    <View style={{ width: 120, alignItems: 'center' }}>
                                        {myShippingInfo.shipsNow == true ? // entrega imediata?
                                            <ProductLeadTimeComponent leadTimeInHours={0} />
                                            : // se nao mostra previsao do envio
                                            <View style={{ flexDirection: 'row', alignItems: 'center' }}>
                                                <MaterialCommunityIcons size={15} name="calendar-clock" style={{ color: Colors.secondaryTextColor, paddingRight: 2 }} />
                                                <Text style={{ ...GlobalStyles.textBase, fontSize: 13 }}>
                                                    {moment(myShippingInfo.shipsBy).fromNow()}
                                                </Text>
                                            </View>
                                        }
                                    </View>)
                        }
                    </View>

                    {   // MENSAGENS SOBRE PREVISAO DE ENVIO
                        // ships now && leadtime = 0: ⚡️ entrega imediata
                        // loja fechada e tudo eh leadTime = 0 => "Esta loja esta fechada. Seu pedido será despachado assim que a loja abrir (dd/mm/yy)""
                        // loja aberta e pedido encomenda => "Seu pedido será despachado assim que a sua encomenda estiver pronta (dd/mm/yy)"
                        // ljoa fechada e pedido encomenda => 
                    }
                    <View style={[{
                        marginTop: 10, //backgroundColor: Colors.lines, 
                        paddingVertical: 3, paddingHorizontal: 10,
                        borderRadius: 4, alignSelf: 'stretch', alignItems: 'center'
                    },
                    (isScheduled ? { backgroundColor: Colors.lines } : {})]}>
                        {(myShippingInfo && maxLeadTimeInHours > 0 || isScheduled)
                            ? // Pedido agendado ou encomenda?
                            <Text style={{ ...GlobalStyles.textBase, color: Colors.secondaryText, fontSize: 13 }}>
                                Seu pedido será enviado assim que a sua encomenda estiver pronta e a loja estiver aberta ({moment(myShippingInfo.shipsBy).format("dddd")}{isScheduled ? ' ' + moment(myShippingInfo.shipsBy).format("DD/MM") : ''} a partir das {moment(myShippingInfo.shipsBy).format("h:mmA")})
                            </Text>
                            :
                            <>
                                {myShippingInfo && myShippingInfo.storeIsOpen == true && maxLeadTimeInHours == 0
                                    && // loja aberta e pedido não tem nada de encomenda: Envia assim q pagar
                                    <Text style={{ ...GlobalStyles.textBase, color: Colors.secondaryText, fontSize: 13 }}>
                                        {  // mas aqui se o cara ta vendo o Cart como um review da venda ele ja pagou, entao mudamos a mensagem
                                            this.props.reviewMode
                                                ?
                                                'Seu pedido será enviado pela loja daqui a pouco.'
                                                :
                                                'Seu pedido será enviado pela loja assim que o pagamento for concluído.'
                                        }

                                    </Text>
                                }
                                {myShippingInfo && myShippingInfo.storeIsOpen == false && maxLeadTimeInHours == 0
                                    && // loja fech:mmAda e produtos são de envio imediato
                                    <Text style={{ ...GlobalStyles.textBase, color: Colors.secondaryText, fontSize: 13 }}>
                                        Esta loja está fechada no momento. Seu pedido será enviado assim que a loja abrir ({moment(myShippingInfo.shipsBy).format("dddd")} a partir das {moment(myShippingInfo.shipsBy).format("hA")})
                                    </Text>
                                }
                            </>
                        }
                    </View>
                </View>
            }

            {
                false && prods.length > 0 &&
                <View style={{ ...styles.rowStyle, justifyContent: 'space-between', borderBottomWidth: 0 }}>
                    <Text style={{ ...GlobalStyles.textBase }}>SubTotal:</Text>
                    <Text style={{ ...GlobalStyles.textBase, fontSize: 16, fontWeight: 'bold', fontFamily: 'MontserratBold' }}>
                        {Helpers.FloatToReais(sumProducts(prods) + (myShippingInfo.price ? myShippingInfo.price : 0))}
                    </Text>
                </View>
            }

            <View style={{
                paddingHorizontal: 12, paddingVertical: 8, backgroundColor: '#fec001',
                borderRadius: 3, textAlign: 'center', marginTop: 5,
            }}>
                <Text style={{ ...GlobalStyles.textBase, fontSize: 13 }}>
                    Devido a pandemia, o prazo de entrega dos pedidos é de até 24h
                </Text>
            </View>
        </View>
    }

    getProdsPerStore = (prods) => {
        var stores = [];
        prods.map(x => {
            let myStore;
            if (stores.filter(z => z.StoreId == x.StoreId).length == 0) {
                myStore = { StoreName: x.StoreName, StoreId: x.StoreId, Products: [] }
                stores.push(myStore);
            } else {
                myStore = stores.filter(z => z.StoreId == x.StoreId)[0]
            }
            myStore.Products.push(x);
        });
        return stores;
    }

    render() {
        var prods = this.props.prods;
        var prodsPerStore = this.getProdsPerStore(prods);
        // 🛵 📍 
        return <View>

            {/* ENDERECO */}
            {this.props.productsOnly || prods.length == 0 ? null :
                <MyPanel //bottomWidthOnly
                    //icon={<MaterialCommunityIcons name="map-marker" size={16} color={this.props.customerUser && this.props.customerUser.address ? Colors.secondaryText : Colors.themeColor} />}
                    title={'Endereço de entrega'} color={Colors.darkText}
                    borderColor={Colors.lines} rightContent={this.props.customerUser && this.props.customerUser.address && !this.props.reviewMode &&
                        <TouchableOpacity style={[GlobalStyles.defaultButton, { paddingVertical: 5, paddingHorizontal: 10, marginRight: 4 }]}
                            onPress={() => { this.setState({ addressDialogOpened: true }) }}>
                            <MaterialCommunityIcons name="pencil" size={13} color={GlobalStyles.defaultButtonText.color} />
                            <Text style={[GlobalStyles.defaultButtonText, { marginLeft: 3 }]}>
                                alterar
                            </Text>
                        </TouchableOpacity> || null}>
                    <View style={{ backgroundColor: '#fff', borderRadius: 5 }}>
                        <View style={{ alignItems: 'center' }}>
                            {this.props.customerUser && this.props.customerUser.address ?
                                <Text style={{ ...GlobalStyles.textBase, padding: 8, color: Colors.darkText, fontSize: 15 }}>
                                    {/* {`${this.props.customerUser.address.resumo} ${this.props.customerUser.address.numero} ${this.props.customerUser.address.complemento ? ', ' + this.props.customerUser.address.complemento : ''} ${this.props.customerUser.address.referencia ? ', ' + this.props.customerUser.address.referencia : ''}`} */}
                                    {this.props.customerUser.address.resumo}
                                </Text>
                                :
                                <TouchableOpacity style={GlobalStyles.defaultButton} onPress={() => { this.setState({ addressDialogOpened: true }) }}>
                                    <MaterialCommunityIcons name="map-marker" size={16} color={GlobalStyles.defaultButtonText.color} />
                                    <Text style={[GlobalStyles.defaultButtonText, { marginLeft: 5 }]}>
                                        Informe seu endereço
                                </Text>
                                </TouchableOpacity>
                            }
                        </View>
                    </View>
                </MyPanel>
            }
            {/* {'Seu pedido em ' + (prodsPerStore.length == 1 ? prodsPerStore[0].StoreName : (prodsPerStore.length) + ' lojas')} */}

            {/* PRODUTOS */}
            <View style={{ backgroundColor: '#fff' }}>

                {/* EMPTY CART */}
                {prods.length == 0 &&
                    <View style={{ padding: 25, alignItems: 'center' }}>
                        <Feather name="shopping-cart" size={26} color={Colors.secondaryTextColor} />
                        <Text style={{ ...GlobalStyles.textBase, fontSize: 16, textAlign: 'center', marginVertical: 25 }}>Seu carrinho está vazio</Text>
                        <Link to={`/`} style={{ color: 'transparent', ...GlobalStyles.cleanLink, flex: 1 }}>
                            <View style={{ ...GlobalStyles.defaultButton, ...GlobalStyles.bigButton }}>
                                <Text style={{ ...GlobalStyles.defaultButtonText, ...GlobalStyles.bigButtonText }}>
                                    Voltar pra loja
                                </Text>
                            </View>
                        </Link>
                    </View>
                }

                {/*prodsPerStore.length == 1 ?
                        this.getInnerCart(prodsPerStore[0])
                        :*/
                    prodsPerStore.map((x, i) => (
                        <MyPanel key={x.StoreId} //icon={<Entypo name="shop" size={16} color={Colors.secondaryText} />}
                            // se for productsonly o carrinho não motra o panel de endereco acima dos panels de produtos
                            // entao removemos a marginTop do primeiro panel nesse caso:
                            style={this.props.productsOnly && i == 0 ? { marginTop: 0 } /*primeiro sem marginTop*/ : {}}
                            title={`Entrega ${i + 1} de ${prodsPerStore.length}` /*x.StoreName*/}
                            borderColor={Colors.lines} color={Colors.darkText} borderWidth={2}>
                            {this.getInnerCart(x)}
                        </MyPanel>)
                    )
                }

                <AddressDialog opened={this.state.addressDialogOpened}
                    onHideDialog={() => this.setState({ addressDialogOpened: false })}
                    onAddressSelect={(data) => {
                        this.setState({ address: data.address, shipping: data.shipping, addressDialogOpened: false }, () => {
                            this.props.dispatch(setCustomerUser({ address: data.address }))
                        });
                    }} />
            </View>


        </View>
    }
}

const styles = {
    rowStyle: {
        flexDirection: 'row',
        alignItems: 'center',
        marginHorizontal: 3,
        marginHorizontal: 3,
        paddingVertical: 10,
        borderBottomWidth: 1,
        borderBottomColor: '#e6e6e6'
    }
}

export default connect(
    (state) => ({
        carts: state.cart.carts,
        prods: Helpers.ObjToArray(getCart(state.cart.carts, state.cart.activeCartId).products),
        activeCartId: state.cart.activeCartId,
        activeCart: getCart(state.cart.carts, state.cart.activeCartId),
        activeCartTotalProds: getCart(state.cart.carts, state.cart.activeCartId) && getCart(state.cart.carts, state.cart.activeCartId).sum || 0,
        activeCartShippingTotal: getCartFullShippingTotal(getCart(state.cart.carts, state.cart.activeCartId)) || -1,
        customerUser: state.customerUser,
    }),
    null)(Cart);




const TotalsComponent = (props) => {
    const prods = props.prods;
    return <View>
        {/* TOTAL EM PRODUTOS */}
        {prods.length > 0 &&
            <View style={{ ...styles.rowStyle, justifyContent: 'space-between', borderBottomWidth: 0 }}>
                <Text style={{ ...GlobalStyles.titleText, color: Colors.darkText }}>Total em produtos:</Text>
                <Text style={{ ...GlobalStyles.titleText, color: Colors.darkText }}>
                    {Helpers.FloatToReais(sumProducts(prods))}
                </Text>
            </View>}

        {/* TOTAL DE FRETE */}
        {!props.productsOnly && prods.length > 0 &&
            <View style={{ ...styles.rowStyle, paddingVertical: 0, justifyContent: 'space-between', borderBottomWidth: 0 }}>
                <Text style={{ ...GlobalStyles.titleText, color: Colors.darkText }}>Total de frete:</Text>
                <Text style={{ ...GlobalStyles.titleText, color: Colors.darkText }}>
                    {props.activeCartShippingTotal > -1 ?
                        Helpers.FloatToReais(props.activeCartShippingTotal) :
                        '?'}
                </Text>
            </View>}

        {!props.productsOnly && prods.length > 0 &&
            <View style={{ ...styles.rowStyle, justifyContent: 'space-between', borderBottomWidth: 0 }}>
                <Text style={{ ...GlobalStyles.titleText, color: Colors.darkText }}>Total:</Text>
                <Text style={{ ...GlobalStyles.titleText, color: Colors.darkText }}>
                    {Helpers.FloatToReais(getCartFullTotal(props.activeCart))}
                </Text>
            </View>}
    </View>
}
export const ConnectedTotalsComponent = connect(
    (state) => ({
        prods: Helpers.ObjToArray(getCart(state.cart.carts, state.cart.activeCartId).products),
        activeCart: getCart(state.cart.carts, state.cart.activeCartId),
        activeCartTotalProds: getCart(state.cart.carts, state.cart.activeCartId) && getCart(state.cart.carts, state.cart.activeCartId).sum || 0,
        activeCartShippingTotal: getCartFullShippingTotal(getCart(state.cart.carts, state.cart.activeCartId)) || -1,
    }),
    null)(TotalsComponent);
import React from 'react';
import { TouchableOpacity, View, Text } from 'react-native';
import Icon from 'react-native-vector-icons/FontAwesome';
import { TextInput, Button } from 'react-native-paper';
import Colors from '../constants/Colors';
import GlobalStyles from '../constants/GlobalStyles';
import MyTextInput from './MyTextInput';

export default class CreditCardPaperComponent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            cardNumber: props.cardNumber || '',
            cardHolder: props.cardHolder || '',
            cardExp: props.cardExp || '',
            cardFlag: props.cardFlag || '',
        }
    }

    render() {
        const a = { ...this.state };
        var iconName = this.state.cardFlag == cyberCashFlags.visa ? 'cc-visa' :
            this.state.cardFlag == cyberCashFlags.electron ? 'cc-visa' :
                this.state.cardFlag == cyberCashFlags.mastercard ? 'cc-mastercard' :
                    this.state.cardFlag == cyberCashFlags.maestro ? 'cc-mastercard' :
                        this.state.cardFlag == cyberCashFlags.diners ? 'cc-diners' :
                            this.state.cardFlag == cyberCashFlags.amex ? 'cc-amex' :
                                this.state.cardFlag == cyberCashFlags.discover ? 'cc-discover' :
                                    'credit-card';
        return <View style={{
            backgroundColor: '#f2f2f2',
            //borderRadius: 20, padding: 20, width: 420, maxWidth: '100%', alignSelf: 'center' ,
            ...this.props.style
        }}>
            {/* <View style={{ height: 400, width: '100%', position: 'absolute', alignItems: 'center' }}>
                                <Image source={require('./../assets/card-front.png')}
                                    style={{ width: 360, height: 240, minWidth: 252, marginBottom: 25 }} />
                            </View> */}

            <View style={{ marginTop: 0, flexDirection: 'row', justifyContent: 'space-evenly', alignItems: 'center' }}>
                <MyTextInput mode='flat'
                    editable={false}
                    label='Nome do portador'
                    style={{ backgroundColor: 'transparent', width: 200 }}
                    className="inputBig"
                    value={this.state.cardHolder}
                //onChangeText={text => this.setState({ cardHolder: text })}
                />
                <Text style={{ ...GlobalStyles.textBase, color: Colors.tintColor }}>
                    <Icon name={iconName} size={32} />
                </Text>
            </View>
            <View style={{ marginTop: this.props.slim ? 5 : 15, flexDirection: 'row', justifyContent: 'space-evenly' }}>
                <MyTextInput mode='flat'
                    editable={false}
                    label={'Número do cartão'} //●
                    style={{ backgroundColor: 'transparent', paddingLeft: 3, paddingRight: 3, width: 184 }}
                    value={this.state.cardNumber}
                //onChangeText={text => this.setState({ cardNumber: formatCardNumber(text) })}
                />
                <MyTextInput mode='flat' style={{ backgroundColor: 'transparent', maxWidth: 74 }}
                    editable={false}
                    label='MM/AA'
                    value={this.state.cardExp}
                //onChangeText={text => this.setState({ cardExp: Masker.toPattern(text, '99/99') })}
                />
            </View>
            <View style={{ marginTop: this.props.slim ? 5 : 15, flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }}>
                <TouchableOpacity style={{ ...GlobalStyles.defaultLinkButton }} onPress={() => this.props.onRemoveCard()}>
                    <Text style={{ ...GlobalStyles.defaultLinkButtonText }}>Remover cartão</Text>
                </TouchableOpacity>
            </View>
        </View>
    }
}

export const cyberCashFlags = {
    'visa': 1,
    'electron': 1,
    'maestro': 2,
    'mastercard': 2,
    'diners': 3,
    'discover': 4,
    'elo': 5,
    'amex': 6,
};
import React from 'react';
import { Platform, Text, View, Image, TouchableOpacity } from 'react-native';
import Helpers from '../utils/Helpers';
import MaterialCommunityIcons from 'react-native-vector-icons/MaterialCommunityIcons';
import Colors from '../constants/Colors';
import GlobalStyles from '../constants/GlobalStyles';
import MyQtdInput from './MyQtdInput';
import { ProductLeadTimeComponent } from './Product';
//const isImageUrl = require('is-image-url');
var Big = require('big.js');
import 'moment/locale/pt';

const styles = {
    rowStyle: {
        flexDirection: 'row',
        alignItems: 'center',
        marginHorizontal: 3,
        marginHorizontal: 3,
        paddingVertical: 10,
        borderBottomWidth: 1,
        borderBottomColor: '#e6e6e6'
    }
}

export default (props) => {
    return (<View key={props.prod.Id} style={{
        ...styles.rowStyle,
        ...props.style
    }}>
        { props.hideImage ? null :
            <TouchableOpacity activeOpacity={0.8} onPress={() => {
                if (props.onOpenProduct) props.onOpenProduct(props.prod);
            }} style={{
                width: 44,
                height: 44,
                backgroundImage: `url('${props.prod.PhotoURL}')`,
                display: 'flex',
                borderRadius: 5,
                backgroundRepeat: 'no-repeat',
                backgroundPosition: 'center center',
                backgroundSize: 'contain',
                margin: 4
            }}>
                {
                    props.prod.showImage ?
                        <View style={{
                            backgroundColor: '#fff',
                            width: 80, height: 80, backgroundImage: `url('${props.prod.PhotoURL}')`,
                            display: 'flex', borderRadius: 4, overflow: 'hidden',
                            backgroundRepeat: 'no-repeat', backgroundPosition: 'center center', backgroundSize: 'contain', margin: 5
                        }}>
                            {Platform.OS != 'web' && <Image style={{ width: 80, height: 80, resizeMode: 'contain' }}
                                source={{ uri: props.prod.PhotoURL }} />}
                        </View>
                        : null
                }
            </TouchableOpacity>
        }
        <TouchableOpacity activeOpacity={0.8} onPress={() => {
            if (props.onOpenProduct) props.onOpenProduct(props.prod);
        }} style={{
            flex: 1
        }}>
            <View style={{ flexDirection: 'row', alignItems: 'center' }}>
                <Text style={{
                    ...GlobalStyles.textBase,
                    fontSize: 16
                }}>
                    {props.prod.Name}
                </Text>
                {
                    props.outOfStock &&
                    <Text style={{
                        ...GlobalStyles.textBase,
                        fontSize: 12, marginLeft: 4,
                        paddingVertical: 3, paddingHorizontal: 5, backgroundColor: Colors.lines, borderRadius: 4,
                    }}>
                        Esgotado
                    </Text>
                }
            </View>
            {
                /* PRODUCT HAS LEAD TIME > 0 */
            }
            {props.prod.LeadTimeInHours > 0 ? <ProductLeadTimeComponent leadTimeInHours={props.prod.LeadTimeInHours} iconSize={13} fontSize={13} style={{
                paddingTop: 5
            }} /> : null}
            {
                /* PRODUCT COMMENTS */
            }
            {props.prod.Comments && props.prod.Comments.length > 0 ? <Text style={{
                ...GlobalStyles.textBase,
                color: Colors.secondaryText,
                fontSize: 13,
                paddingTop: 5
            }}>
                <MaterialCommunityIcons name='comment-text-outline' size={13} /> {props.prod.Comments}
            </Text> : null}

            {
                // PRODUCT PRICE ON EVERY ROW?
            }
            {
                props.showPriceOnEveryRow &&
                <Text style={{ ...GlobalStyles.textBase, fontSize: 14, paddingTop: 4 }}>
                    {Helpers.FloatToReais(Number(props.prod.Price))}
                </Text>
            }
        </TouchableOpacity>
        {
            /* INPUT DE QTD DE PRODUTO */
        }
        <View style={{
            flexDirection: 'column',
            alignItems: 'center'
        }}>
            <MyQtdInput onChangeValue={v => props.setProductQty({
                ...props.prod,
                Quantity: v
            })} readOnly={props.reviewMode || props.outOfStock} value={props.prod.Quantity} appendText={props.prod.QtyName} step={props.prod.QtyStep} />
            <Text style={{
                ...GlobalStyles.textBase,
                fontSize: 15
            }}>
                {props.prod.Bulk || true ?
                    Helpers.FloatToReais(Number(new Big(props.prod.Price).times(props.prod.Quantity)))
                    :
                    Helpers.FloatToReais(Number(props.prod.Price))
                }
            </Text>
        </View>
    </View>);
}
export const PaymentMethod = {
    MONEY: 'money',
    CREDIT: 'credit',
    DEBIT: 'debit',
    INSTAGRANA: 'instagrana',
}
export const PaymentType = {
    POS: 'pos',
    TEF: 'tef',
    ONLINE: 'online',
}
export const PaymentMethodBR = {
    money: 'Dinheiro',
    credit: 'Crédito',
    debit: 'Débito',
    instagrana: 'instagrana',
}
export const EnumSaleStatus =
{
    Opened: 1,
    Confirmed: 2,
    Denied: 3,
    Delivered: 4,
    ConfirmedByCyberCash: 5,
    Canceled: 6
}
export const EnumSaleStatusBR =
{
    1: 'Aberta',
    2: 'Confirmada',
    3: 'Negada',
    4: 'Entregue',
    5: 'ConfirmadaCyberCash',
    6: 'Cancelada'
}
export const EnumOriginSale =
{
    Site: 1,
    App: 2,
    POS: 3,
    iFood: 4
}
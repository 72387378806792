import Colors from "./Colors";
import { Platform } from 'react-native';

export default {
    textBase: {
        fontFamily: 'Montserrat',
        color: Colors.textColor
    },
    textBaseBold: {
        fontFamily: 'MontserratBold',
        fontWeight: 'bold',
        color: Colors.textColor
    },
    titleText: {
        color: Colors.darkText,
        fontWeight: 'bold', fontFamily: 'MontserratBold',
        fontSize: 16,
        paddingVertical: 6
    },
    defaultButton: {
        flexDirection: 'row', alignItems: 'center', justifyContent: 'center',
        paddingHorizontal: 10, paddingVertical: 6, borderRadius: 5,
        backgroundColor: Colors.themeColor
    },
    defaultButtonText: {
        fontFamily: 'Montserrat',
        fontSize: 14, color: Colors.buttonText
    },
    defaultButtonInverted: {
        flexDirection: 'row', alignItems: 'center', justifyContent: 'center',
        paddingHorizontal: 10, paddingVertical: 6, borderRadius: 5,
        backgroundColor: Colors.bgColor, borderWidth: 1, borderColor: Colors.themeColor
    },
    defaultButtonTextInverted: {
        fontSize: 14, color: Colors.themeColor, fontFamily: 'Montserrat'
    },
    bigButton: {
        paddingVertical: 10
    },
    bigButtonText: {
        fontSize: 15
    },
    defaultLinkButton: {
        paddingHorizontal: 10, paddingVertical: 3
    },
    defaultLinkButtonText: {
        fontSize: 14, color: Colors.themeColor, fontFamily: 'Montserrat',
        textDecorationLine: 'underline'
    },
    leftCol: {
        display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'center',
        maxWidth: 420, minWidth: 280, background: '#fff', borderRight: '1px solid #eee'
    },
    shadow3: {
        shadowColor: "#000",
        shadowOffset: {
            width: 0,
            height: 1,
        },
        shadowOpacity: 0.25,
        shadowRadius: 4,
        elevation: 3,
    },
    cleanLink: Platform.OS === 'web' && {
        textDecorationLine: 'none'
    },
}
import React, { Component } from 'react';
//import Colors from '../constants/Colors'
import Helpers from '../utils/Helpers'
import Colors from '../constants/Colors';
import { View, Text, TouchableOpacity, Platform, Image } from 'react-native';
import { connect } from 'react-redux';
import { getCart, setProductToActiveCart, clearAllCarts } from '../store/cartsReducer';
import GlobalStyles from '../constants/GlobalStyles';
import SosHelpers from '../utils/SosHelpers';
import MaterialIcons from 'react-native-vector-icons/MaterialIcons';
import MaterialCommunityIcons from 'react-native-vector-icons/MaterialCommunityIcons';
//const isImageUrl = require('is-image-url');

class Product extends Component {
    render() {
        var prod = this.props.Product;
        var mywidth = this.props.style && this.props.style.width || 200
        // iniciais do nome do produto pra qdo nao tem imagem
        var prodNameArr = prod.Name.split(' ');
        return <View style={{
            flexDirection: 'column',
            padding: 0, width: mywidth, maxWidth: mywidth, height: 326, maxHeight: 326, borderRadius: 5,
            //boxShadow: '0px 1px 5px #ddd', 
            backgroundColor: '#fff', margin: 10, marginTop: 0,
            // shadowOffset: { width: 2, height: 2 },
            // shadowColor: 'black',
            // shadowOpacity: 0.1,
            ...this.props.style
        }}>
            <TouchableOpacity onPress={this.props.onPress} style={{
                cursor: 'pointer', position: 'relative', flex: 1,
                display: 'flex', flexDirection: 'column', alignSelf: 'stretch',
                justifyContent: 'flex-start', alignItems: 'stretch'
            }}>
                {/* FLYING LABELS */}
                {/* Quantidade no carrinho */}
                {
                    this.props.quantityOnCart > 0 &&
                    <View style={{
                        zIndex: 2,
                        position: 'absolute', top: 0, right: 0,
                        borderWidth: 0, borderColor: Colors.red, borderRadius: 15,
                        borderRightWidth: 0, minWidth: 28, height: 28, paddingHorizontal: 3,
                        backgroundColor: Colors.themeColor,
                        justifyContent: 'center', alignItems: 'center'
                        // shadowOffset: { width: 1, height: 1 },
                        // shadowColor: 'black',
                        // shadowOpacity: 0.1,
                    }}>
                        <Text style={{
                            ...GlobalStyles.textBase,
                            color: '#fff',
                            fontSize: 14,
                            fontWeight: 'bold', fontFamily: 'MontserratBold'
                        }}>{this.props.quantityOnCart}</Text>
                    </View>
                }
                {/* inStock? */}
                {prod.QtyInStock <= 0 && prod.TrackStock ?
                    <View style={{
                        zIndex: 2,
                        position: 'absolute', top: 10, right: 0,
                        borderWidth: 1, borderColor: Colors.red,
                        borderRightWidth: 0,
                        backgroundColor: '#fff', display: 'inline-block',
                        padding: 2, paddingLeft: 6, paddingRight: 6,
                        // shadowOffset: { width: 1, height: 1 },
                        // shadowColor: 'black',
                        // shadowOpacity: 0.1,
                    }}>
                        <Text style={{
                            ...GlobalStyles.textBase,
                            color: Colors.red,
                            fontSize: 12
                        }}>esgotado</Text>
                    </View>
                    : null
                }
                {/* SITE SALE */}
                {/* {prod.SiteSale ?
                    <Text style={{ ...GlobalStyles.textBase,
                        position: 'absolute', top: 5, left: 0, border: '1px solid ' + Colors.themeColor, color: '#fff', borderLeft: 'none',
                        background: Colors.themeColor, display: 'inline-block', padding: '2px 6px', fontSize: 12
                    }}>Preço Site</Text> : null
                } */}


                {/* FOTO DO PRODUTO */}
                {/* <LazyLoad height={154}> */}
                <View style={{
                    backgroundColor: '#fff',
                    width: mywidth - 10, height: mywidth - 10, backgroundImage: `url('${prod.PhotoURL}')`,
                    display: 'flex', borderRadius: 4, overflow: 'hidden',
                    backgroundRepeat: 'no-repeat', backgroundPosition: 'center center', backgroundSize: 'contain', margin: 5
                }}>
                    {Platform.OS != 'web' && <Image style={{ width: mywidth - 10, height: mywidth - 10, resizeMode: 'contain' }}
                        source={{ uri: prod.PhotoURL }} />}
                    {/* {!hasImg ? <Text style={{ ...GlobalStyles.textBase, alignSelf: 'center', width: '100%', textAlign: 'center', fontSize: '1.5em', color: '#93ccf5' }}>{prod.Name}</Text> : null} */}
                    {/* <img src={prod.PhotoURL} style={{ maxWidth: 154, maxHeight: 154 }} /> */}
                </View>
                {/* </LazyLoad> */}

                {/* INFO DO PRODUTO */}
                <View style={{
                    display: 'flex', flexDirection: 'column', flex: 1, alignSelf: 'stretch',
                    justifyContent: 'space-between', padding: 5, alignItems: 'flex-start'
                }}>
                    {/* NAME */}
                    <Text numberOfLines={2} style={[{
                        fontSize: 15, color: Colors.secondaryTextColor, textAlign: 'left',
                        justifyContent: 'flex-start', alignItems: 'center',
                        fontWeight: 'bold', fontFamily: 'MontserratBold',
                    },
                        //Platform.OS == 'web' ? { overflow: 'hidden' } : {}
                    ]}>{prod.Name}</Text>
                    {/* DESCRIPTION */}
                    <Text numberOfLines={2} style={[{
                        fontSize: 12, color: Colors.secondaryTextColor, textAlign: 'left',
                        justifyContent: 'flex-start', alignItems: 'center',
                        fontFamily: 'Montserrat',
                    },
                        //Platform.OS == 'web' ? { overflow: 'hidden' } : {}
                    ]}>{prod.Description}</Text>
                    {/* PRICE */}
                    {prod.Price ?
                        <Text style={{
                            ...GlobalStyles.textBase, paddingVertical: 5, fontWeight: 'bold',
                            fontFamily: 'MontserratBold', zIndex: 2, fontSize: 15
                        }}>
                            {SosHelpers.getProductPriceToDisplay(prod)}
                        </Text> : null
                    }
                    {/* LEAD TIME */}
                    {/* {prod.LeadTimeInHours == -1 ? null // HACK PARA ESCONDER LEAD TIME 
                        :
                        <ProductLeadTimeComponent leadTimeInHours={prod.LeadTimeInHours} />
                    } */}
                </View>


            </TouchableOpacity>
            {/* <View style={{ flexDirection: 'row', justifyContent: 'space-between' }}>
                <TouchableOpacity onPress={() => console.log('Pressed')}>
                    <Text>Ver</Text>
                </TouchableOpacity>
                <TouchableOpacity onPress={() => console.log('Pressed')}>
                    <Text>Comprar</Text>
                </TouchableOpacity>
            </View> */}
        </View>
    }
}

export const ProductLeadTimeComponent = ({ leadTimeInHours, fontSize, iconSize, style }) => {
    return leadTimeInHours == 0 ?
        <View style={{ flexDirection: 'row', alignItems: 'center', ...style }}>
            <MaterialIcons size={iconSize || 15} name="flash-on" style={{ color: Colors.greenStrong }} />
            <Text style={{ ...GlobalStyles.textBase, fontSize: fontSize || 13, color: Colors.greenStrong }}>
                Entrega imediata
        </Text>
        </View>
        :
        <View style={{ flexDirection: 'row', alignItems: 'center', ...style }}>
            <MaterialCommunityIcons size={iconSize || 15} name="calendar-clock" style={{ color: Colors.secondaryTextColor, paddingRight: 3 }} />
            <Text style={{ ...GlobalStyles.textBase, fontSize: fontSize || 13, color: Colors.secondaryTextColor }}>
                Encomenda ({leadTimeInHours < 24 ? leadTimeInHours + 'h' : (leadTimeInHours / 24) + 'd'})
        </Text>
    </View>
}

const tryGetCartProductQuantity = (objCartProducts, id) => {
    //debugger;
    if (id in objCartProducts) {
        return objCartProducts[id].Quantity
    }
    return 0;
}
export default connect(
    (state, ownProps) => ({
        quantityOnCart: tryGetCartProductQuantity(getCart(state.cart.carts, state.cart.activeCartId).products, ownProps.Product.Id),
    }),
    null)(Product);
import React, { Component } from 'react';
import { View, Text, TextInput } from 'react-native';
import Colors from '../constants/Colors';
import GlobalStyles from '../constants/GlobalStyles';
export default class MyTextInput extends React.Component {
    constructor(props) {
        super(props);
        this.state = { focused: false }
    }
    render() {
        return <View style={{ flexDirection: 'column', height: 60, ...this.props.style }}>
            <Text style={{ ...GlobalStyles.textBase, padding: 4, ...this.props.labelStyle }}>{this.props.label}</Text>
            <TextInput 
                onFocus={() => this.setState({ focused: true })}
                onBlur={() => this.setState({ focused: false }, this.props.onBlur ? this.props.onBlur : () => { })}
                style={{
                    ...GlobalStyles.textBase,
                    fontSize: 16, borderBottomColor: this.state.focused ? Colors.themeColor : Colors.darkLines, borderBottomWidth: 2, padding: 4,
                    ...this.props.inputStyle
                }} multiline={this.props.multiline}
                placeholder={this.props.placeholder}
                value={this.props.value}
                type={this.props.type}
                keyboardType={this.props.keyboardType}
                onChangeText={this.props.onChangeText}
            />
        </View>
    }
}
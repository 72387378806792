import React from 'react';
import {
    View, Text
} from 'react-native';
import { Masker } from "./MyInputMask";
import { TextInput, Paragraph, Checkbox, Button, TouchableRipple } from 'react-native-paper';
import { formatCardNumber, cardFromNumber, validateCardNumber } from '../utils/creditcardutils';
import Colors from '../constants/Colors';
import MaterialCommunityIcons from 'react-native-vector-icons/MaterialCommunityIcons';
import MyTextInput from './MyTextInput';
import GlobalStyles from '../constants/GlobalStyles';

export default class CreditCardPaperInput extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            cardNumber: props.cardNumber || '',
            cardHolder: this.props.cardHolder || '',
            cardExp: this.props.cardExp || '',
            cardCVV: this.props.cardCVV || '',
            cardHolderDoc: this.props.cardHolderDoc || '',
            cardFlag: this.props.cardFlag || '',
            cardRemember: true,
            // validation
            cardNumberValid: true,
            cardHolderValid: true,
            cardExpValid: true,
            cardCVVValid: true,
            cardHolderDocValid: true,
        }
        if(props.myRef) props.myRef(this);
    }

    validateCard = () => {
        var stt = this.state;
        var allOk = true;
        // card number
        if (
            //!validateCardNumber(stt.cardNumber)
            stt.cardNumber.length < 14
        ) {
            allOk = false;
            stt.cardNumberValid = false
        } else {
            stt.cardNumberValid = true
        }
        // card expiration date:
        var exp = stt.cardExp;
        var expArr = exp.split('/');
        if (exp.length < 5 || exp.indexOf('/') === -1 || expArr[0] > 12 || expArr[1] < (new Date().getFullYear() - 2000)) {
            allOk = false;
            stt.cardExpValid = false
        } else {
            stt.cardExpValid = true
        }
        // card cvv
        if (stt.cardCVV.length <= 2 || stt.cardCVV.length > 4) {
            allOk = false;
            stt.cardCVVValid = false
        } else {
            stt.cardCVVValid = true
        }
        // card holder name
        if (stt.cardHolder.length <= 2) {
            allOk = false;
            stt.cardHolderValid = false
        } else {
            stt.cardHolderValid = true
        }
        // card holder document
        if (stt.cardHolderDoc.length <= 2) {
            allOk = false;
            stt.cardHolderDocValid = false
        } else {
            stt.cardHolderDocValid = true
        }
        this.setState({ ...stt }, (e) => {
            //console.log('New State:', { ...stt }, 'this.state:', this.state, e);
        });
        return allOk;
    }

    getCard = () => {
        var valid = this.validateCard();
        if (!valid) return null;
        var flag;
        try {
            var card = cardFromNumber(this.state.cardNumber);
            flag = card.cyberCashFlag;
        } catch (e) { }
        return {
            Number: this.state.cardNumber,
            HolderName: this.state.cardHolder,
            ExpirationDate: this.state.cardExp,
            SecurityCode: this.state.cardCVV,
            OwnerDocumentIdentifier: this.state.cardHolderDoc,
            Flag: flag,
            CardRemember: this.state.cardRemember
        }
    }

    render() {
        //console.log('RENDER!!!');
        const a = { ...this.state };
        return <View style={{
            backgroundColor: '#f2f2f2', //borderRadius: 20, padding: 12, paddingHorizontal: 6,
            //width: 420, maxWidth: '100%', 
            //alignSelf: 'center', 
            ...this.props.style
        }}>
            {/* <View style={{ height: 400, width: '100%', position: 'absolute', alignItems: 'center' }}>
                                <Image source={require('./../assets/card-front.png')}
                                    style={{ width: 360, height: 240, minWidth: 252, marginBottom: 25 }} />
                            </View> */}

            <View style={{ marginTop: 0, flexDirection: this.props.slim ? 'column' : 'row', justifyContent: 'space-evenly', flex: 1 }}>
                <View style={{ marginTop: 0, flexDirection: 'row', justifyContent: 'space-evenly', flex: 3 }}>
                    <MyTextInput mode='flat'
                        label={'Número'} //●
                        style={{ paddingLeft: 3, paddingRight: 3, minWidth: 100, marginRight: 8, flex: 1 }}
                        value={this.state.cardNumber}
                        error={!this.state.cardNumberValid}
                        keyboardType={'numbers-and-punctuation'}
                        onChangeText={text => this.setState({ cardNumber: formatCardNumber(text) })}
                    />
                </View>
                <View style={{ marginTop: 0, flexDirection: 'row', justifyContent: 'space-evenly', flex: 2, marginTop: this.props.slim ? 15 : 0 }}>
                    <MyTextInput mode='flat' style={{ maxWidth: 80, width: 56, marginRight: 8, flex: 1 }}
                        label='mm/aa'
                        value={this.state.cardExp}
                        error={!this.state.cardExpValid}
                        keyboardType={'numbers-and-punctuation'}
                        onChangeText={text => this.setState({ cardExp: Masker.toPattern(text, '99/99') })}
                    />
                    <MyTextInput mode='flat' style={{ maxWidth: 46, width: 46, flex: 1 }}
                        label='cvv'
                        value={this.state.cardCVV}
                        error={!this.state.cardCVVValid}
                        keyboardType={'numeric'}
                        onChangeText={text => this.setState({ cardCVV: Masker.toPattern(text, '9999') })}
                    />
                </View>
            </View>
            <View style={{ marginTop: 15, flexDirection: this.props.slim ? 'column' : 'row', justifyContent: 'space-evenly', alignItems: 'center' }}>
                <MyTextInput mode='flat'
                    label='Nome no cartão'
                    style={{ width: 110, marginRight: 8, flex: 1 }}
                    value={this.state.cardHolder}
                    error={!this.state.cardHolderValid}
                    onChangeText={text => this.setState({ cardHolder: text })}
                />
                {this.props.hideCpf ? null :
                    <MyTextInput mode='flat' style={{ maxWidth: 152, width: 120, flex: 1 }}
                        label='CPF'
                        value={this.state.cardHolderDoc}
                        error={!this.state.cardHolderDocValid}
                        type="text"
                        keyboardType={'numbers-and-punctuation'}
                        onChangeText={text => {
                            //console.log('setting cardholderdoc:', Masker.toPattern(text, '999.999.999-99'));
                            this.setState({ cardHolderDoc: Masker.toPattern(text, '999.999.999-99') })
                        }}
                    />}
            </View>
            <View style={{ marginTop: 15, flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }}>
                <TouchableRipple onPress={() => this.setState({ cardRemember: !this.state.cardRemember })}>
                    <View style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', padding: 4 }}>
                        <MaterialCommunityIcons color={Colors.themeColor} name={this.state.cardRemember ? 'checkbox-marked' : 'checkbox-blank-outline'} size={23} />
                        <Text style={{ ...GlobalStyles.textBase, fontSize: 15, marginLeft: 3, letterSpacing: 0 }}>
                            Salvar para próximas compras
                        </Text>
                    </View>
                </TouchableRipple>
            </View>
        </View>
    }
}
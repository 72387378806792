import React from 'react';
import { Platform, Text, View, Image, TouchableOpacity } from 'react-native';
import { Appbar, TouchableRipple } from 'react-native-paper';
import { FloatingColumn } from './../components/ResponsiveColumns';
import Api from './../api/Api';
import Helpers from '../utils/Helpers';
import MaterialCommunityIcons from 'react-native-vector-icons/MaterialCommunityIcons';
import Colors from '../constants/Colors';
import { TabletView, MobileView, DesktopView } from '../components/ResponsiveView';
import LinkXP from '../utils/LinkXP';
import { Event } from 'expo-analytics';
import GlobalStyles from '../constants/GlobalStyles';
import { CartButton } from './CheckoutStrip';
import { GetHistoryHack } from '../App';
import { connect } from 'react-redux';

export const SocialNetworksItems = (props) => {
    var btnStyle = {
        ...GlobalStyles.textBase, display: 'flex', alignItems: 'center', fontSize: 15,
        paddingHorizontal: props.horizontal ? 16 : 8, paddingVertical: 10, color: Colors.themeColor
    };
    if (Platform.OS != 'web') { btnStyle.fontSize = 15; btnStyle.paddingHorizontal = 4; }
    return <View key='lol' style={{
        marginHorizontal: 0, borderRadius: 14, marginBottom: 15,
        alignSelf: 'stretch', flexDirection: props.horizontal ? 'row' : 'column', alignItems: 'flex-start'
        //backgroundColor: Colors.bgColor, 
        //justifyContent: 'space-evenly', alignItems: 'center'
    }}>
        <TouchableRipple key={0} onPress={() => {
            LinkXP.OpenURL('https://www.google.com/maps/place/SOS+Chocolate/@-23.0008128,-43.3962244,15z/data=!4m5!3m4!1s0x0:0xa535e4dece617423!8m2!3d-23.0008128!4d-43.3962244', '_blank')
            Api.Analytics.event(new Event("OpenedLink", 'Maps'));
        }}>
            <Text style={btnStyle}>
                <MaterialCommunityIcons name="map-marker" size={22} />
                {props.hideLabels ? '' : ' Av. Américas 8585, lj. 146'}
            </Text>
        </TouchableRipple>
        <TouchableRipple key={1} onPress={() => {
            LinkXP.OpenURL('https://api.whatsapp.com/send?phone=5521975005115', '_blank')
            Api.Analytics.event(new Event("OpenedLink", 'WhatsApp'));
        }}>
            <Text style={btnStyle}>
                <MaterialCommunityIcons name="whatsapp" size={22} />
                {props.hideLabels ? '' : ' (21)97500-5115'}
            </Text>
        </TouchableRipple>
        <TouchableRipple key={2} onPress={() => {
            LinkXP.OpenURL('https://www.instagram.com/razcau/', '_blank');
            Api.Analytics.event(new Event("OpenedLink", 'Instagram'));
        }}>
            <Text style={btnStyle}>
                <MaterialCommunityIcons name="instagram" size={22} />
                {props.hideLabels ? '' : ' @razcau'}
            </Text>
        </TouchableRipple>
        {/* <TouchableRipple key={3} onPress={() => {
            LinkXP.OpenURL('https://www.facebook.com/soschocolate.com.br/', '_blank');
            Api.Analytics.event(new Event("OpenedLink", 'Facebook'));
        }}>
            <Text style={btnStyle}>
                <MaterialCommunityIcons name="facebook" size={22} />
                {props.hideLabels ? '' : ' /soschocolate.com.br'}
            </Text>
        </TouchableRipple> */}
        <TouchableRipple key={4} onPress={() => {
            LinkXP.OpenURL('mailto:sac@razcau.com', '_blank')
            Api.Analytics.event(new Event("OpenedLink", 'Email'));
        }}>
            <Text style={btnStyle}>
                <MaterialCommunityIcons name="email-outline" size={22} />
                {props.hideLabels ? '' : ' contato por e-mail'}
            </Text>
        </TouchableRipple>
    </View>
}

class Header extends React.Component {

    constructor(props) {
        super(props);
        this.state = {}
    }

    render() {
        var columnWidth = Helpers.Min(300, Helpers.percentW(70))
        console.log('header! route:', this.props.route)
        return <>
            <Appbar.Header style={{ backgroundColor: '#fff' }}>
                <TouchableOpacity onPress={() => { this.setState({ menuOpened: true }) }}>
                    <MaterialCommunityIcons style={{ color: Colors.headerText, position: 'relative' }} size={26} name="menu" />
                </TouchableOpacity>
                <View style={{ flex: 1, justifyContent: 'center', alignItems: 'center' }}>
                    <MobileView>
                        <Image source={require('../assets/logoRazcau.png')}
                            style={{ height: 56, width: 230, resizeMode: 'contain' }} />
                    </MobileView>
                    <TabletView>
                        <Image source={require('../assets/logoRazcau.png')}
                            style={{ height: 56, width: 250, resizeMode: 'contain' }} />
                    </TabletView>
                    <DesktopView style={{ alignSelf: 'stretch', justifyContent: 'center', alignItems: 'center' }}>
                        <Image source={require('../assets/logoRazcau.png')}
                            style={{ height: 56, width: 280, resizeMode: 'contain' }} />
                    </DesktopView>
                </View>
                <View style={{ flexDirection: 'row', alignItems: 'center' }}>
                    {(
                        this.props.route
                        &&
                        (
                            this.props.route.indexOf('/checkout') != -1
                            ||
                            this.props.route.indexOf('cardapio') != -1
                            ||
                            this.props.route.indexOf('selfservice') != -1
                        )
                    )
                        ||
                        this.props.hideCartButton
                        ? null :
                        <CartButton style={{ position: 'absolute', right: 40, minWidth: 80 }} />
                    }
                    <TouchableOpacity style={{ marginLeft: 10 }} onPress={() => {
                        LinkXP.OpenURL('https://api.whatsapp.com/send?phone=5521975005115', '_blank')
                        Api.Analytics.event(new Event("OpenedLink", 'WhatsApp'));
                    }}>
                        <MaterialCommunityIcons style={{ color: Colors.headerText, position: 'relative' }} size={26} name="whatsapp" />
                    </TouchableOpacity>
                </View>
            </Appbar.Header>

            {/* MENU SIDEBAR */}
            <FloatingColumn side='left' style={{ backgroundColor: '#fff' }} width={columnWidth} key="sidemenu"
                windowWidth={Helpers.percentW(100)} windowHeight={Helpers.percentH(100)}
                opened={this.state.menuOpened} onClose={() => this.setState({ menuOpened: false })}>
                <TouchableOpacity key={'a'} style={{ padding: 7, paddingTop: 15 }} onPress={() => { this.setState({ menuOpened: false }) }}>
                    <MaterialCommunityIcons style={{ color: Colors.headerText, position: 'relative' }} size={26} name="menu" />
                </TouchableOpacity>
                <View style={{ alignItems: 'flex-start', padding: 10, paddingTop: 0 }} key={'b'}>
                    <Image source={require('../assets/logoRazcau.png')}
                        style={{ height: 120, width: columnWidth - 30, resizeMode: 'contain', alignSelf: 'center' }} />
                </View>
                {/* <Text key={'c'} style={{ ...GlobalStyles.textBase, fontFamily: 'MontserratBold', alignSelf: 'stretch', textAlign: 'center', fontSize: 15, padding: 8, paddingTop: 0 }}>
                    Delivery de emergência para chocólatras!
                </Text> */}
                <SocialNetworksItems key={'d'} />
            </FloatingColumn>
        </>
    }
}

export default connect(
    (state) => ({
        route: state.route.route,
    }),
    null)(Header)




const SET_CUSTOMER_USER = 'SET_CUSTOMER_USER';

export const setCustomerUser = (auth) => {
    return { type: SET_CUSTOMER_USER, payload: auth }
}

export const logout = () => {
    return { type: SET_CUSTOMER_USER, payload: {} }
}

const initialState = {
}

export const customerUserReducer = (state = initialState,action) => {
    const { type, payload } = action

    switch (type) {
        case SET_CUSTOMER_USER: {
            return {
                ...state,
                ...payload
            }
        }

        default:
            return state
    }
}
import React from 'react';
import { Text, View, Image, TouchableOpacity } from 'react-native';
import Api from './../api/Api';
import Helpers from '../utils/Helpers';
import { addToCart } from '../store/cartsReducer';
import MaterialIcons from 'react-native-vector-icons/MaterialIcons';
import Feather from 'react-native-vector-icons/Feather';
import Colors from '../constants/Colors';
import { Event } from 'expo-analytics';
import store from '../store/store';
import { connect } from 'react-redux';
import { getCart, setProductToActiveCart, clearAllCarts } from '../store/cartsReducer';
import Checkout from './Checkout';
import GlobalStyles from '../constants/GlobalStyles';
import { GetHistoryHack } from '../App';

class CheckoutStrip extends React.Component {
    constructor(props) {
        super(props);
    }
    render() {
        var hist = GetHistoryHack();
        var carOpened = this.props.route.indexOf('/cart') != -1
        return <>
            {this.props.cartCount > 0 && !carOpened &&
                <TouchableOpacity
                    onPress={() => {
                        hist.push(hist.location.pathname + '/cart');
                    }}
                    style={{
                        zIndex: 3, borderTopLeftRadius: 5, borderTopRightRadius: 5,
                        maxWidth: Helpers.percentW(100), width: 420, alignSelf: 'center',
                        backgroundColor: Colors.themeColor, height: 44, position: 'fixed', bottom: 0,
                        flexDirection: 'row', paddingHorizontal: 10, alignItems: 'center', justifyContent: 'space-between'
                    }}>
                    <View style={{ minWidth: 90 }}>
                        <Feather style={{ color: Colors.buttonText }} size={22} name="shopping-cart" />
                        <Text style={{
                            ...GlobalStyles.textBase,
                            color: Colors.themeColor, backgroundColor: Colors.buttonText,
                            left: 12, bottom: -8, width: 18, borderRadius: 11, position: 'absolute',
                            textAlign: 'center', fontWeight: 'bold', fontFamily: 'MontserratBold', fontSize: 12, height: 18, lineHeight: 18,
                        }}>
                            {this.props.cartCount}
                        </Text>
                    </View>
                    <Text style={{ ...GlobalStyles.textBase, color: Colors.buttonText, fontSize: 16 }}>
                        Ver carrinho
                    </Text>
                    <Text style={{ ...GlobalStyles.textBase, color: Colors.buttonText, fontSize: 16, fontWeight: 'bold', fontFamily: 'MontserratBold', minWidth: 90, textAlign: 'right' }}>
                        {Helpers.FloatToReais(
                            this.props.cartSum // somente preco dos produtos
                            // + (this.props.cartShipping || 0))}
                        )}
                    </Text>
                </TouchableOpacity>
            }
        </>
    }
}

export default connect(
    (state) => ({
        cartCount: getCart(state.cart.carts, state.cart.activeCartId).qtd,
        cartSum: getCart(state.cart.carts, state.cart.activeCartId).sum,
        route: state.route.route
        //cartShipping: getCart(state.cart.carts, state.cart.activeCartId).shipping
    }),
    null)(CheckoutStrip)

// MINI CART BUTTON

class _CartButton extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            checkoutOpened: false
        }
    }
    render() {
        return <>
            {this.props.cartCount > 0 && !this.state.checkoutOpened &&
                <TouchableOpacity
                    onPress={() => { 
                        //this.setState({ checkoutOpened: true })
                        //debugger;
                        var hist = GetHistoryHack();
                        hist.push(hist.location.pathname + '/cart');
                    }}
                    style={{
                        zIndex: 3, borderRadius: 5, alignSelf: 'center',
                        backgroundColor: Colors.themeColor, height: 38, minWidth: this.props.cartSum >= 100 ? 118 : 80,
                        flexDirection: 'row', paddingHorizontal: 10, alignItems: 'center', justifyContent: 'space-between',
                        ...this.props.style
                    }}>
                    <View style={{ }}>
                        <Feather style={{ color: Colors.buttonText }} size={18} name="shopping-cart" />
                        <Text style={{
                            ...GlobalStyles.textBase,
                            color: Colors.themeColor, backgroundColor: Colors.buttonText,
                            left: 12, bottom: -8, width: 18, borderRadius: 11, position: 'absolute',
                            textAlign: 'center', fontWeight: 'bold', fontFamily: 'MontserratBold', fontSize: 12, height: 18, lineHeight: 18,
                        }}>
                            {this.props.cartCount}
                        </Text>
                    </View>
                    <Text numberOfLines={1} style={{ ...GlobalStyles.textBase, color: Colors.buttonText, fontSize: 14, fontWeight: 'bold', fontFamily: 'MontserratBold', paddingLeft: 10, textAlign: 'right' }}>
                        {Helpers.FloatToReais(
                            this.props.cartSum // somente preco dos produtos
                            // + (this.props.cartShipping || 0))}
                        )}
                    </Text>
                </TouchableOpacity>
            }
            {/* <ModalCart opened={this.state.checkoutOpened} onClose={() => this.setState({ checkoutOpened: false })} /> */}
        </>
    }
}
export const CartButton = connect(
    (state) => ({
        cartCount: getCart(state.cart.carts, state.cart.activeCartId).qtd,
        cartSum: getCart(state.cart.carts, state.cart.activeCartId).sum,
        //cartShipping: getCart(state.cart.carts, state.cart.activeCartId).shipping
    }),
    null)(_CartButton)


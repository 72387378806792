import React, { Component } from 'react';
import { View, Text, TextInput, TouchableOpacity } from 'react-native';
import Colors from '../constants/Colors';
import GlobalStyles from '../constants/GlobalStyles';
import MaterialCommunityIcons from 'react-native-vector-icons/MaterialCommunityIcons';
var Big = require('big.js');

export default class MyQtdInput extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
        return <View style={{ flexDirection: 'row', alignItems: 'center', justifyContent: 'center', ...this.props.style }}>
            {this.props.readOnly || this.props.value === 0 ? null :
                <TouchableOpacity
                    onPress={() => {
                        let newVal = Number(new Big(this.props.value).minus(this.props.step || 1));
                        this.props.onChangeValue(newVal < this.props.min ? this.props.min : newVal);
                    }}
                    style={{ borderRadius: 15 }}>
                    <MaterialCommunityIcons name={'minus'} size={26} style={{ color: Colors.themeColor, padding: 3 }} />
                </TouchableOpacity>
            }
            {this.props.value === 0 ? null :
                <Text style={{ ...GlobalStyles.textBase, fontSize: 16, width: 56, textAlign: 'center' }}>
                    {this.props.value}<Text style={{ ...GlobalStyles.textBase, fontSize: 12 }}>{this.props.appendText}</Text>
                </Text>
            }
            {this.props.readOnly ? null :
                <TouchableOpacity
                    onPress={() => this.props.onChangeValue(
                        Number(new Big(this.props.value).plus(this.props.step || 1))
                    )}
                    style={{ borderRadius: 15 }}>
                    <MaterialCommunityIcons name={'plus'} size={26} style={{ color: Colors.themeColor, padding: 3 }} />
                </TouchableOpacity>
            }
        </View>
    }
}
import React from 'react';
import { Text, View } from 'react-native';
import { Link } from '../../components/router';

export default function ProductItem() {
  return (
    <View style={{}}>
      <View style={{height:120, width:120, borderWidth: 1}}>
      <Text>foto</Text>
      </View>
      <Text>PRODUTO</Text>
      <Text>R$ 10,00</Text>
      <Link to="/prod">
        <Text>Ver</Text>
      </Link>
    </View>
  );
}



import React from 'react';
import { Platform, Text, View, TouchableOpacity, ScrollView } from 'react-native';
import Helpers from '../utils/Helpers';
import MaterialCommunityIcons from 'react-native-vector-icons/MaterialCommunityIcons';
import Feather from 'react-native-vector-icons/Feather';
import Colors from '../constants/Colors';
import Modal from 'react-native-modalbox';
import GlobalStyles from '../constants/GlobalStyles';
import { Link } from './router';
import Cart, { ConnectedTotalsComponent } from './Cart';

// NODAL CART

export const ModalCart = ({ opened = false, onClose = () => { } }) => {
    var BContent = <TouchableOpacity onPress={() => {
        onClose();
    }} style={{
        margin: 10,
        backgroundColor: "#3B5998",
        padding: 10,
        position: Platform.OS === 'web' ? 'fixed' : "absolute",
        top: 0,
        right: 0,
        width: 50,
        height: 50,
        backgroundColor: "transparent"
    }}></TouchableOpacity>;
    return <Modal isOpen={opened} swipeToClose={false} backdropPressToClose={false}
        onClosed={() => {
            //this.props.onClosed();
        }}
        style={{
            justifyContent: 'flex-start', alignItems: 'stretch',
            //minHeight: 250,
            //height: Helpers.percentH(46),
            height: null,
            width: Helpers.Min(Helpers.percentW(100) - 25, 480),
            maxHeight: Helpers.percentH(85),
            maxWidth: Helpers.Min(Helpers.percentW(100) - 25, 480),
            borderRadius: 10, overflow: 'hidden',
            backgroundColor: '#fff'
        }} position={"center"} backdropContent={BContent}>
        <View style={{
            alignSelf: 'stretch',
            maxHeight: Helpers.percentH(85) - 16
        }}>
            <View style={{ flex: 1 }}>

                <TouchableOpacity style={{
                    flexDirection: 'row', justifyContent: 'space-between', paddingHorizontal: 12, paddingVertical: 12,
                    alignItems: 'center', flex: 1, backgroundColor: Colors.themeColor,
                    borderTopRightRadius: 10, borderTopLeftRadius: 10, maxHeight: 40
                }}
                    onPress={() => onClose()}>
                    <View style={{ flexDirection: 'row', alignItems: 'baseline' }}>
                        <Feather style={{ color: Colors.buttonText }} size={20} name="shopping-cart" />

                        <Text style={{ ...GlobalStyles.textBase, color: Colors.buttonText, fontSize: 20, marginLeft: 4 }}>
                            Carrinho
                    </Text>
                    </View>
                    <View style={{ padding: 15, paddingRight: 0 }}>
                        <MaterialCommunityIcons name="minus" size={23} color={Colors.buttonText} />
                    </View>
                </TouchableOpacity>
                <ScrollView style={{ flex: 1 }} contentContainerStyle={{ padding: 10 }}>
                    <Cart productsOnly />
                    <ConnectedTotalsComponent productsOnly={true} />
                    <View style={{ marginTop: 5, flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}>
                        <TouchableOpacity style={{ ...GlobalStyles.defaultButtonInverted, ...GlobalStyles.bigButton, flex: 1, marginRight: 15 }}
                            onPress={() => onClose()}>
                            <Text style={{ ...GlobalStyles.defaultButtonTextInverted, ...GlobalStyles.bigButtonText }}>
                                Voltar para a loja
                        </Text>
                        </TouchableOpacity>
                        <Link to={`/checkout`} style={{ color: 'transparent', ...GlobalStyles.cleanLink, flex: 1 }}>
                            <View style={{ ...GlobalStyles.defaultButton, ...GlobalStyles.bigButton }}>
                                <Text style={{ ...GlobalStyles.defaultButtonText, ...GlobalStyles.bigButtonText }}>
                                    Concluir pedido
                            </Text>
                            </View>
                        </Link>
                    </View>
                </ScrollView>
            </View>

        </View>
    </Modal>;
};

import React from 'react';
import { ScrollView, StyleSheet, View, Text, Picker, TouchableOpacity } from 'react-native';
import AsyncStorage from '@react-native-async-storage/async-storage';
import { Button, Paragraph, Appbar, TouchableRipple, RadioButton, Portal, Dialog } from 'react-native-paper';
import Api from './../api/Api';
import { connect } from 'react-redux'
import { getCart, setProductToActiveCart, clearAllCarts, getCartFullTotal, getCartFullShippingTotal, isAnyShippingOurOfReach } from '../store/cartsReducer';
import MyAccordion from './../components/MyAccordion';
import MaterialIcons from 'react-native-vector-icons/MaterialIcons';
import MaterialCommunityIcons from 'react-native-vector-icons/MaterialCommunityIcons';
import Feather from 'react-native-vector-icons/Feather';
import Colors from '../constants/Colors';
import Cart, { ConnectedTotalsComponent } from '../components/Cart';
import CreditCardPaperInput from '../components/CreditCardPaperInput';
import CreditCardPaperView from '../components/CreditCardPaperView';
import MyTextInput from './../components/MyTextInput';
import { Masker } from './MyInputMask';
import ConfirmSaleDialog from './ConfirmSaleDialog';
import { CartCountBadge } from '../components/CartCountBadge';
import { Platform } from '@unimodules/core';
import { EnumOriginSale } from '../constants/Enums';
import { addCreditCard, removeCreditCard } from './../store/cardsReducer';
import DropdownXP from './DropdownXP';
import { Analytics, Event } from 'expo-analytics';
import moment from 'moment';
import 'moment/locale/pt';
import MyPanel from './MyPanel';
import GlobalStyles from '../constants/GlobalStyles';
import { ViewportRenderer, ViewportNames } from './ResponsiveView';
import Helpers from './../utils/Helpers';
import { ModalProductWithGet } from './ModalProduct';
var Big = require('big.js');

class Checkout extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      //deliveryMethod: 'Immediate',
      confirmSaleId: 0,
      customerLoaded: {},
      periodoEntrega: '1'
    }
  }

  componentDidMount() {
    this.getLoadedCustomer();
  }

  getCC = () => {
    if (this.props.creditcards.length == 0)
      return this.ccComponent.getCard();
    else
      return this.props.creditcards[0];
  }

  tryMakeOrder = async () => {
    this.setState({ feedbackMsg: '' })

    if (this.props.cartCount == 0) {
      this.setState({ feedbackMsg: 'Ops! Parece que seu carrinho está vazio.' })
      return;
    }
    if (!this.props.customerUser || !this.props.customerUser.address) {
      this.setState({ feedbackMsg: 'Ops! Parece que seu endereço não foi preenchido.' })
      return;
    }
    // if (this.state.deliveryMethod == 'Scheduled') {
    //   try {
    //     console.log('will validate date:', this.state.dataEntrega);
    //     console.log(moment(this.state.dataEntrega, 'DD/MM/YY', true).isValid());
    //     if (!moment(this.state.dataEntrega, 'DD/MM/YY', true).isValid())
    //       throw "invalidDate";
    //   } catch {
    //     this.setState({ feedbackMsg: 'Ops! Parece que a data da entrega está incorreta.' })
    //     return;
    //   }
    // }
    if (!this.props.activeCart || !this.props.activeCartShippingTotal) {
      this.setState({ feedbackMsg: 'Ops! Parece que seu endereço está fora da nossa área de atendimento.' })
      return;
    }
    if (!this.customerForm.isValid()) {
      this.setState({ feedbackMsg: 'Ops! Preencha seus dados de identificação (Nome,Email,CPF,Telefone).' })
      return;
    }
    var cc = this.getCC();
    console.log(cc);
    if (!cc || (!cc.hash && !this.ccComponent.validateCard())) {
      this.setState({ feedbackMsg: 'Ops! Verifique os dados do seu cartão de crédito.' })
      return;
    }


    var customer = this.customerForm.getData();
    var cartProducts = Helpers.ObjToArray(this.props.activeCart.products);//.map(x => { return { Id: x.Id, Quantity: x.Quantity, Price: x.Price } })

    var card = this.getCC();
    if (card.ExpirationDate) card.ExpirationDate = new Date((card.ExpirationDate.split('/')[1] * 1) + 2000, card.ExpirationDate.split('/')[0] - 1, 1);
    if (!card.SecurityCode) card.SecurityCode = card.cvv;

    var sale = {
      Guid: Helpers.NewGuid(),
      Origin: Platform.OS == 'web' ? EnumOriginSale.Site : EnumOriginSale.App,
      Customer: customer,
      Address: this.props.customerUser.address,
      Products: cartProducts,
      Card: card,
      // DeliveryMethod: this.state.deliveryMethod,
      // ScheduledDate: this.state.dataEntrega,
      // ScheduledPeriod: this.state.periodoEntrega,
      GiftSignature: this.state.cartinhaDe,
      GiftName: this.state.cartinhaPara,
      GiftMsg: this.state.msgCartinha,
      ShippingInfo: this.CartRef.getShippingInfo()
    }
    //debugger;
    // save customer offline
    await AsyncStorage.setItem('customer', JSON.stringify(customer));
    if (this.CheckingOut === true) return;
    this.CheckingOut = true;
    this.setState({ loadingPayment: true });
    Api.Analytics.event(new Event("Checkout", 'TentouFecharVenda'));
    console.log('checking out', sale);
    Api.CheckoutMarketPlace(sale, card).then(x => {
      //console.log('VOLTOU! SHOW!', x);
      this.CheckingOut = false;
      if (x.data.success)  // venda concluida
      {
        Api.Analytics.event(new Event("Checkout", 'ConcluiuComSucesso'));
        // salva cartao?
        if (x.data.cardHash && cc.CardRemember) {
          this.props.dispatch(addCreditCard({
            id: 1,
            hash: x.data.cardHash,
            number: Helpers.MaskCreditCard(Helpers.NumbersFromString(cc.Number)),
            holderName: cc.HolderName,
            exp: cc.ExpirationDate,
            cvv: cc.SecurityCode,
            flag: cc.Flag
          }))
        }
        this.setState({ loadingPayment: false, feedbackMsg: '', confirmModalOpened: true, confirmSaleId: x.data.saleId });
      }
      else  // nao concluiu:
      {
        Api.Analytics.event(new Event("Checkout", 'Falhou'));
        this.setState({ loadingPayment: false, feedbackMsg: x.data.Msg });
        if (x.data.products) {
          cartProducts.map(lProd => {
            var srvProd = x.data.products.filter(f => f.Id == lProd.Id)[0];
            if (lProd.Quantity != srvProd.Quantity) {
              lProd.Quantity = Number(new Big(srvProd.Quantity));
              this.props.dispatch(setProductToActiveCart(lProd));
            }
          });
        }
      }
    }).catch(e => {
      this.CheckingOut = false;
      console.log('ERRO!', e)
      this.setState({ loadingPayment: false, feedbackMsg: 'Ops, ocorreu um erro! Verifique seus dados e tente novamente por favor.' });
    });

    //console.log('will checkout!', sale)
  }

  getLoadedCustomer = async () => {
    try {
      //debugger
      const value = await AsyncStorage.getItem('customer');
      console.log('getLoadedCustomer', value);
      if (value !== null) {
        this.setState({ customerLoaded: JSON.parse(value) });
      }
    } catch (e) {
    }
  }

  removeCard = (id) => {
    this.setState({ showRemoveCardDialog: true, cardIdToRemove: id });
  }
  reallyRemoveCard = () => {
    this.props.dispatch(removeCreditCard(this.state.cardIdToRemove));
  }

  render() {
    var feedbackMsg = this.state.feedbackMsg;
    if (!this.state.feedbackMsg && (!this.props.customerUser.address || !this.props.customerUser.address.nomeRua) && this.props.cartCount > 0) {
      feedbackMsg = "Faltou informar o" + '\n' + "endereço de entrega!";
    } else if (!this.state.feedbackMsg && this.props.isAnyShippingOurOfReach === true) {
      feedbackMsg = "Ops, parece que seu endereço está fora da área de entrega. Por favor, reveja seu carrinho acima."
    }
    var customerLoaded = this.state.customerLoaded;


    //console.log('....render:', customerLoaded)
    return (
      <View style={{ flex: 1 }}>
        {/* HEADER CARRINHO */}
        <TouchableOpacity style={{
          flexDirection: 'row', justifyContent: 'space-between', paddingHorizontal: 12, paddingVertical: 12,
          alignItems: 'center', flex: 1, backgroundColor: Colors.themeColor,
          //borderTopRightRadius: 10, borderTopLeftRadius: 10, 
          maxHeight: 40
        }}
          onPress={() => this.props.onClosed()}>
          <View style={{ flexDirection: 'row', alignItems: 'baseline' }}>
            <Feather style={{ color: Colors.buttonText }} size={20} name="arrow-left" />
            {/* <CartCountBadge style={{ position: 'absolute', top: 20, left: 15 }} /> */}
            <Text style={{ ...GlobalStyles.textBase, color: Colors.buttonText, fontSize: 20, marginLeft: 4 }}>
              Checkout
            </Text>
          </View>
          <View style={{ padding: 15, paddingRight: 0 }}>
            {/* <Feather name="x" size={23} color={Colors.buttonText} /> */}
          </View>
        </TouchableOpacity>

        {this.state.openedProduct &&
          <ModalProductWithGet // activeCartId={store.cart.activeCartId} 
            prodId={this.state.openedProduct.Id}
            onClosed={() => { this.setState({ openedProduct: null }) }}
          />
        }
        <ScrollView style={{ flex: 1 }} contentContainerStyle={{flexGrow: 1}}>
          <ViewportRenderer render={(viewport) => {
            let myColumnStyle = {
              flex: 1,
              marginHorizontal: 15,
              padding: 10,
              paddingTop: 0,
              maxWidth: Helpers.Max(640, Helpers.percentW(49))
            }
            let isMobile = viewport == ViewportNames.mobile;
            let myContentMaxWidth = 1280
            let hasProductsAndAdress = this.props.cartCount > 0 && this.props.customerUser.address;
            return (
              <View style={{
                flexDirection: isMobile || this.props.forceMobileViewport ? 'column' : 'row',
                justifyContent: 'center', alignSelf: 'center', flex: 1,
                // alignSelf: hasProductsAndAdress ? 'center' : 'stretch', // quando nao tem produtos nem endereco o checkout fica fullscreen
                maxWidth: Helpers.Min(myContentMaxWidth, Helpers.percentW(100))
              }}>
                <View style={[myColumnStyle,
                  !isMobile ? { flex: 2, maxWidth: Helpers.Max(myContentMaxWidth * .66, Helpers.percentW(66)), paddingRight: 0 } :
                    {}]}>
                  <Cart myref={(r) => this.CartRef = r} onOpenProduct={(prod) => this.setState({ openedProduct: prod })} />
                  {/* <MyAccordion title="Produtos">
            </MyAccordion> */}
                </View>
                {/* COLUNA 2 NO DESKTOP ou RESTO DO CARRINHO NO MOBILE */}
                {this.props.cartCount > 0 &&
                  <View style={[myColumnStyle,
                    !isMobile ? {
                      backgroundColor: '#f3f3f3', marginHorizontal: 0, paddingHorizontal: 15,
                      maxWidth: Helpers.Max(myContentMaxWidth * .33, Helpers.percentW(33)), minWidth: 320
                    } :
                      {}]}>

                    {/* DADOS DO CLIENTE */}
                    <CustomerForm ref={(r) => this.customerForm = r} customer={customerLoaded} />

                    {/* CARTINHA */}
                    {/* <MyPanel //icon={<MaterialCommunityIcons name="email-outline" size={16} color={Colors.secondaryText} />}
                      borderColor={Colors.lines} title={'Cartinha (opcional)'} color={Colors.themeColor}>
                      <View style={{ flex: 1, flexDirection: 'row' }}>
                        <MyTextInput style={{ flex: 1, marginRight: 10 }} label={'De'} value={this.state.cartinhaDe}
                          onChangeText={(t) => this.setState({ cartinhaDe: t })} />
                        <MyTextInput style={{ flex: 1 }} label={'Para'} value={this.state.cartinhaPara}
                          onChangeText={(t) => this.setState({ cartinhaPara: t })} />
                      </View>
                      <MyTextInput style={{}} label={'Mensagem'} value={this.state.msgCartinha}
                        onChangeText={(t) => this.setState({ msgCartinha: t })} multiline={true} />
                    </MyPanel> */}

                    {/* CARTAO DE CREDITO INPUT */}
                    <MyPanel //icon={<MaterialCommunityIcons name="credit-card" size={16} color={Colors.secondaryText} />}
                      borderColor={Colors.lines} title={'Cartão de crédito'} color={Colors.themeColor}>
                      <ViewportRenderer render={(viewportName, width) => (
                        this.props.creditcards.length == 0 ?
                          <CreditCardPaperInput myRef={(r) => this.ccComponent = r}
                            slim={false} hideCpf={false}
                            style={{ backgroundColor: '#fff', padding: 0, borderRadius: 0, flex: 1 }}
                          />
                          :
                          <CreditCardPaperView
                            slim={true}
                            style={{ backgroundColor: '#fff', padding: 0, borderRadius: 0, flex: 1 }}
                            onRemoveCard={() => this.removeCard(this.props.creditcards[0].id)}
                            cardNumber={this.props.creditcards[0].number}
                            cardHolder={this.props.creditcards[0].holderName}
                            cardExp={this.props.creditcards[0].exp}
                            cardFlag={this.props.creditcards[0].flag}
                          />
                      )} />
                    </MyPanel>

                    <ConnectedTotalsComponent />

                    {/* BOTAO FINAL E MSGS DE FEEDBACK */}
                    <View style={{ marginVertical: 5 }}>
                      {feedbackMsg ?
                        <Text style={{ ...GlobalStyles.textBase, marginBottom: 20, color: Colors.red, fontSize: 14, lineHeight: 20, textAlign: 'center', fontWeight: 'bold', fontFamily: 'MontserratBold' }}>{feedbackMsg}</Text>
                        : null}
                      <Button icon="credit-card" loading={this.state.loadingPayment} mode="contained"
                        onPress={() => this.tryMakeOrder()} disabled={this.props.activeCartTotal == 0 || this.props.cartCount == 0}>
                        <Text style={{ ...GlobalStyles.textBase, fontSize: 16, color: Colors.buttonText }}>
                          Pagar {this.props.activeCartTotal > 0 && this.props.cartCount > 0 ? Helpers.FloatToReais(this.props.activeCartTotal) : ''}
                        </Text>
                      </Button>

                      {/* CADEADO */}
                      <View style={{ flexDirection: 'row', justifyContent: 'center', alignItems: 'center', marginTop: 12 }}>
                        <Text style={{ ...GlobalStyles.textBase, color: Colors.greenStrong, fontSize: 12, textAlign: 'center', fontWeight: 'bold', fontFamily: 'MontserratBold' }}>
                          Pagamento seguro
                        </Text>
                        <MaterialIcons style={{ color: Colors.greenStrong, marginHorizontal: 3, top: 0, position: 'relative' }} size={12} name="lock" />
                        <Text style={{ ...GlobalStyles.textBase, color: Colors.greenStrong, fontSize: 12, textAlign: 'center', fontWeight: 'bold', fontFamily: 'MontserratBold' }}>
                          Cybercash.com.br
                        </Text>
                      </View>
                    </View>
                  </View>
                }
              </View>
            );
          }} />
        </ScrollView>

        {/* DIALOG DE CONFIRMACAO */}
        <ConfirmSaleDialog onHideDialog={(saleConfirmed) => {
          if (saleConfirmed) {
            this.props.dispatch(clearAllCarts());
            this.setState({ GiftSignature: '', GiftName: '', GiftMsg: '' });
          }
          this.setState({ confirmSaleId: null, confirmModalOpened: false });
          if (this.props.onClosed) this.props.onClosed();
        }}
          opened={this.state.confirmModalOpened} saleId={this.state.confirmSaleId}
          forcedShippingInfoForReview={this.state.confirmModalOpened && this.CartRef ? this.CartRef.getShippingInfo() : {}} />
        <Portal>
          {/* CONFIRM REMOVE CARD MODAL */}
          <Dialog visible={this.state.showRemoveCardDialog}
            style={{ width: 420, alignSelf: 'center', maxWidth: Helpers.percentW(95), position: 'absolute', top: Helpers.percentH(8) }}
            onDismiss={() => this.setState({ showRemoveCardDialog: false })}>
            <Dialog.Title>Remover cartão</Dialog.Title>
            <Dialog.Content>
              <Paragraph>
                Deseja apagar este cartão de crédito?
              </Paragraph>
            </Dialog.Content>
            <Dialog.Actions style={{ justifyContent: 'space-evenly' }}>
              <Button mode="outlined" onPress={() => this.setState({ showRemoveCardDialog: false })}>
                <Text style={{ ...GlobalStyles.textBase, marginHorizontal: 8, marginVertical: 3 }}>Não</Text>
              </Button>
              <Button mode="contained" style={{ marginLeft: 0 }} onPress={() => {
                this.setState({ showRemoveCardDialog: false });
                this.reallyRemoveCard()
              }}>
                <Text style={{ ...GlobalStyles.textBase, marginHorizontal: 8, marginVertical: 3 }}>Sim</Text>
              </Button>
            </Dialog.Actions>
          </Dialog>
        </Portal>
      </View>
    );
  }
}

export default connect(
  (state) => ({
    cartCount: getCart(state.cart.carts, state.cart.activeCartId).qtd,
    customerUser: state.customerUser,
    creditcards: state.creditcards,
    activeCartTotal: getCartFullTotal(getCart(state.cart.carts, state.cart.activeCartId)) || 0,
    isAnyShippingOurOfReach: isAnyShippingOurOfReach(getCart(state.cart.carts, state.cart.activeCartId)) || false,
    activeCartShippingTotal: getCartFullShippingTotal(getCart(state.cart.carts, state.cart.activeCartId)) || 0,
    activeCart: getCart(state.cart.carts, state.cart.activeCartId),
  }),
  null)(Checkout)


// class _CartCountBadge extends React.Component {
//   render() {
//     return <View style={{ backgroundColor: Colors.headerText, borderRadius: 10, paddingHorizontal: 4, paddingVertical: 1, position: 'relative', ...this.props.style }}>
//       <Text style={{ fontWeight: 'bold', fontFamily: 'MontserratBold' }}>{this.props.cartCount}</Text>
//     </View>
//   }
// }
// const CartCountBadge = connect(
//   (state) => ({
//     cartCount: getCart(state.cart.carts, state.cart.activeCartId).qtd,
//   }),
//   null)(_CartCountBadge);


class CustomerForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      Name: props.customer ? props.customer.Name : '',
      Telephone: props.customer ? props.customer.Telephone : '',
      Document: props.customer ? props.customer.Document : '',
      Email: props.customer ? props.customer.Email : ''
    }
  }
  componentWillReceiveProps(nextProps) {
    if (nextProps.customer) {
      this.setState({
        Name: nextProps.customer.Name && !this.state.Name ? nextProps.customer.Name : this.state.Name,
        Telephone: nextProps.customer.Telephone && !this.state.Telephone ? nextProps.customer.Telephone : this.state.Telephone,
        Document: nextProps.customer.Document && !this.state.Document ? nextProps.customer.Document : this.state.Document,
        Email: nextProps.customer.Email && !this.state.Email ? nextProps.customer.Email : this.state.Email
      })
    }
  }
  getData = () => {
    return this.state;
  }
  isValid = () => this.state.Name && this.state.Telephone && this.state.Document && this.state.Email;
  render() {
    return <MyPanel //icon={<MaterialCommunityIcons name="cellphone-iphone" size={16} color={this.isValid() ? Colors.secondaryText : Colors.themeColor} />}
      borderColor={Colors.lines} title={'Identificação do comprador'} color={Colors.darkText}>
      <View style={{ flex: 1, flexDirection: 'row' }}>
        <MyTextInput style={{ flex: 3, marginRight: 10 }} label={'Nome'} value={this.state.Name}
          onChangeText={(t) => this.setState({ Name: t })} />
        <MyTextInput style={{ flex: 2 }} label={'Telefone'} value={this.state.Telephone}
          onChangeText={(t) => this.setState({ Telephone: t })} />
      </View>
      <View style={{ flex: 1, flexDirection: 'row' }}>
        <MyTextInput style={{ flex: 3 }} label={'Email'} value={this.state.Email}
          onChangeText={(t) => this.setState({ Email: t })} />
        <MyTextInput style={{ flex: 2, marginLeft: 10 }} label={'CPF'} value={this.state.Document}
          onChangeText={(t) => this.setState({ Document: Masker.toPattern(t, '999.999.999-99') })} />
      </View>
    </MyPanel>
  }
}
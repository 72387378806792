import React from 'react';
import { Text, View } from 'react-native';
import { connect } from 'react-redux'
import { getCart } from '../store/cartsReducer';
import Colors from '../constants/Colors';
import GlobalStyles from '../constants/GlobalStyles';

class _CartCountBadge extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            showingTip: false
        }
    }

    componentWillReceiveProps(nextProps) {
        //console.log('show tip', nextProps);
        if (this.props.cartCount == 0 && nextProps.cartCount > 0) {
            this.setState({ showingTip: true });
            setTimeout(() => {
                this.setState({ showingTip: false });
            }, 5000);
        } else if (this.props.cartCount < nextProps.cartCount) {
            this.setState({ showingTip: true });
            setTimeout(() => {
                this.setState({ showingTip: false });
            }, 1000);
        }
    }

    render() {
        return <View style={{ backgroundColor: Colors.headerText, borderRadius: 15, paddingHorizontal: 5, paddingVertical: 1, position: 'relative', ...this.props.style }}>
            <Text style={{ ...GlobalStyles.textBase, fontWeight: 'bold', fontFamily: 'MontserratBold' }}>{Math.ceil(this.props.cartCount)}</Text>
            {
                this.state.showingTip
                    ?
                    <View style={{
                        position: 'absolute', right: 45, top: 16, width: 290, margin: 10, backgroundColor: '#fff',
                        borderRadius: 15, padding: 15, borderTopRightRadius: 0, borderWidth: 1, borderColor: '#ccc'
                    }}>
                        <Text style={{ ...GlobalStyles.textBase, color: Colors.themeColor, fontWeight: 'bold', fontFamily: 'MontserratBold' }}>👏😍 Produto adicionado ao carrinho!</Text>
                    </View>
                    :
                    null
            }
        </View>
    }
}
export const CartCountBadge = connect(
    (state) => ({
        cartCount: getCart(state.cart.carts, state.cart.activeCartId).qtd,
    }),
    null)(_CartCountBadge);
import { Platform } from 'react-native';
import { Linking } from 'expo';
export default {
    OpenURL: (url, target) => {
        if (Platform.OS == 'web') {
            window.open(url, target)
        } else {
            Linking.openURL(url)
        }
    }
};
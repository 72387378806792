const ADD_CREDITCARD = 'ADD_CREDITCARD'
const REMOVE_CREDITCARD = 'REMOVE_CREDITCARD'

export const addCreditCard = (card) => {
    return { type: ADD_CREDITCARD, payload: card }
}
export const removeCreditCard = (id) => {
    return { type: REMOVE_CREDITCARD, payload: {id} }
}

const initialState = []

export const cardsReducer = (state = initialState,action) => {
    const { type, payload } = action
    const { creditcards } = state

    switch (type) {
        case ADD_CREDITCARD: {
            return [
                ...state,
                payload
            ]
        }
        case REMOVE_CREDITCARD: {
            return state.filter(x => x.id != payload.id)
        }

        default:
            return state
    }
}
import React, { useState, useEffect } from 'react';
import { ScrollView, Text, View, TouchableOpacity, Alert, Image } from 'react-native';
import Slideshow from './../components/SlideShow';
import ProductsCarousel from './../components/products/ProductsCarousel';
import { Link } from '../components/router';
import MySearchInput from '../components/MySearchInput';
import Helpers from './../utils/Helpers';
import Colors from '../constants/Colors';
import Api from '../api/Api';
import { ActivityIndicator } from 'react-native-paper';
import { Analytics, Event } from 'expo-analytics';
import Product from '../components/Product';
import GlobalStyles from '../constants/GlobalStyles';
import { MaterialCommunityIcons } from 'react-native-vector-icons';
import DropdownXP from '../components/DropdownXP';
import ScrollViewXP from '../components/ScrollViewXP';
import MyAccordion, { MyAccordionXP } from './../components/MyAccordion';
import { ViewportRenderer, ViewportNames } from './../components/ResponsiveView';
import { SocialNetworksItems } from '../components/Header';
import Feather from 'react-native-vector-icons/Feather';

export default class Home extends React.Component {

    constructor(props, context) {
        super(props);
        this.state = {
            categories: [],
            prodsByCategoryId: {},
            products: [],
            categoryName: ''
        }
    }

    componentDidMount() {
        this.updateStateFromProps(this.props);
        // this.getAllCategories();
        // this.getProducts(true, this.props.params);
        // this.getCategoryInfo(this.props.params.catId);
    }

    componentWillReceiveProps(nextProps) {
        // changed categories? trigger getProducts again!
        if (this.props.params.catId != nextProps.params.catId) {
            this.updateStateFromProps(nextProps);
            // this.getProducts(true, nextProps.params);
            // this.getCategoryInfo(nextProps.params.catId);
        }
    }
    getAllData = (props) => {
        this.getProducts(true, props.params);
        this.getAllCategories();
        this.getCategoryInfo(props.params.catId);
    }
    updateStateFromProps = async (props) => {
        if (props.storeAlias) {
            try {
                var ret = await Api.GetStoreByAlias(props.params.storeAlias);
                if (ret.data.Id > 0)
                    this.setState({ store: ret.data }, () => this.getAllData(props))
            } catch {
                Alert.alert("Ops", "Loja não encontrada");
                this.props.history.push(`/`);
                //this.getAllData(props)
            }
        } else {
            // get main banner from store soschocolate
            var ret = await Api.GetStoreById(1);
            if (ret.data.Id > 0)
                this.setState({ marketplaceStore: { ...ret.data, BannerURL: null } })
            this.getAllData(props)
        }
    }

    getTodayDeliverySchedule = () => this.state.store.DeliveryTimes.filter(x => x.Day == (new Date()).getDay())[0];

    getWeekDayName = (d) => d == 0 ? 'Domingo' :
        d == 1 ? 'Segunda' :
            d == 2 ? 'Terça' :
                d == 3 ? 'Quarta' :
                    d == 4 ? 'Quinta' :
                        d == 5 ? 'Sexta' : 'Sábado';

    renderDeliveryhoursForDay = (deliveryDayInfo) => {
        var dayName = this.getWeekDayName(deliveryDayInfo.Day);
        return <View style={{ flexDirection: 'row', marginBottom: 4 }}>
            <Text style={{ ...GlobalStyles.textBase, width: 68 }}>{dayName}</Text>
            {!deliveryDayInfo.OpenTime1 ? <Text style={{ ...GlobalStyles.textBase }}>Fechado</Text> :
                <Text style={{ ...GlobalStyles.textBase }}>{deliveryDayInfo.OpenTime1} às {deliveryDayInfo.CloseTime1}
                    {deliveryDayInfo.OpenTime2 ? (' | ' + deliveryDayInfo.OpenTime2 + ' às ' + deliveryDayInfo.CloseTime2) : ''}</Text>
            }
        </View>
    }

    isStoreOpened = () => {
        if (this.state.store && this.state.store.DeliveryTimes) {
            try {
                var todaySchedule = this.getTodayDeliverySchedule();
                var now = new Date();
                var arrD1Init = todaySchedule.OpenTime1.split(':');
                var dt1init = new Date(now.getFullYear(), now.getMonth(), now.getDate(), arrD1Init[0], arrD1Init[1], 0);
                var arrD1Fim = todaySchedule.CloseTime1.split(':');
                var dt1Fim = new Date(now.getFullYear(), now.getMonth(), now.getDate(), arrD1Fim[0], arrD1Fim[1], 0);
                if (now >= dt1init && now < dt1Fim)
                    return true;

                var arrD2Init = todaySchedule.OpenTime2.split(':');
                var dt2init = new Date(now.getFullYear(), now.getMonth(), now.getDate(), arrD2Init[0], arrD2Init[1], 0);
                var arrD2Fim = todaySchedule.CloseTime2.split(':');
                var dt2Fim = new Date(now.getFullYear(), now.getMonth(), now.getDate(), arrD2Fim[0], arrD2Fim[1], 0);
                if (now >= dt2init && now < dt2Fim)
                    return true;
                return false;
            }
            catch (e) {
                return false;
            }
        }
        else return false;
    }

    getAllCategories = () => {
        Api.Products.getAllCategories(this.state.store ? this.state.store.Id : 0).then(x => {
            if (x && x.data && x.data.length > 0) {
                this.setState({ categories: x.data },
                    () => x.data.map(x => this.getTopProductsForCategory(x.Id)));
            }
        })
    }

    getTopProductsForCategory = (categoryId) => {
        Api.Products.getProducts(0, this.state.store ? this.state.store.Id : 0, categoryId, 0, '', '', 0, 12).then(x => {
            if (x && x.data && x.data.length > 0) {
                this.setState({ prodsByCategoryId: { ...this.state.prodsByCategoryId, [categoryId]: x.data } });
            }
        })
    }

    getProducts = async (clear = true, params) => {
        // loja?
        //let storeAlias = (params || this.props.params).storeAlias || '';
        //let storeId = (params || this.props.params).storeId || 0;
        let storeId = this.state.store ? this.state.store.Id : 0;
        let catId = (params || this.props.params).catId || 0;
        var pageSize = 50;
        if (clear) this.lastProductIndex = 0;
        this.setState({ loadingProducts: true })
        Api.Products.getProducts(0, storeId, catId, this.state.selectedBrand ? this.state.selectedBrand.Id : 0, this.state.searchText, this.state.orderBy, this.lastProductIndex, pageSize).then(x => {
            this.lastProductIndex += x.data.length;
            this.setState({
                loadingProducts: false,
                hasMoreProdsToLoad: x.data.length == 50, // se voltar menos de 50 eh pq acabou a paginacao
                products: clear ? x.data : [...this.state.products, ...x.data]
            })
        }).catch(e => {

        });
    }

    selectCategory = (id) => {
        this.props.history.push(id == 0 ? this.props.baseUrl : `${this.props.baseUrl}/categoria/${id}`)
    }

    getCategoryInfo = (id) => {
        if (!id) this.setState({ categoryName: '' });
        else Api.GetCategoryInfo(id).then(x => {
            if (x && x.data && x.data.Name) {
                this.setState({ categoryName: x.data.Name });
            }
        });
    }

    onPressProduct = (p) => {
        this.props.history.push(`${this.props.url}/produto/${p.Id}`);
    }

    render() {
        //console.log('PROPSSSSS:', this.props);
        let wBanner = Helpers.Min(Helpers.percentW(100), 1200);
        //console.log('WIDTH:' + Helpers.percentW(100))
        // modal prod aberto
        let modalProdOpened = this.props.params.prodId;
        let openedCategoryId = this.props.params.catId || 0;
        let storeAlias = this.props.params.storeAlias || '';
        let todayDelivery = this.state.store ? this.getTodayDeliverySchedule() : null;
        let sectionTitle = this.state.searchText ? ('Buscando por "' + this.state.searchText + (openedCategoryId == 0 || this.state.categoryName.length == 0 ? '"' : ('" em ' + this.state.categoryName)))
            : openedCategoryId > 0 ? this.state.categoryName
                : this.state.selectedBrand && this.state.selectedBrand.Id > 0 ? this.state.selectedBrand.Name
                    : 'Carregando...';
        return <ScrollView stickyHeaderIndices={[2]} style={{ flex: 1 }} contentContainerStyle={{ backgroundColor: '#fff' }}>

            {/* BANNERS */}
            {(this.state.store && this.state.store.BannerURL
                ||
                this.state.marketplaceStore && this.state.marketplaceStore.BannerURL
            ) ?
                <View style={{ alignItems: 'center' }}>
                    <Image style={{ width: wBanner, minHeight: wBanner * .33, resizeMode: 'cover' }}
                        source={{ uri: this.state.marketplaceStore ? this.state.marketplaceStore.BannerURL : this.state.store.BannerURL }} />
                </View>
                : null}
            {/* <Slideshow
                dataSource={this.state.store && this.state.store.BannerURL ? [{ url: this.state.store.BannerURL }] :
                    [
                        { url: 'http://placeimg.com/640/480/1' },
                        { url: 'http://placeimg.com/640/480/2' },
                        { url: 'http://placeimg.com/640/480/3' }
                    ]} /> */}

            {/* PAGINA DE LOJA */}
            {
                this.state.store ? // mostra info sobre a loja
                    <View style={{ alignSelf: 'center', padding: 0 }}>
                        <View style={{ flexDirection: 'row', alignItems: 'flex-start' }}>
                            <Image style={{ width: 120, height: 120, resizeMode: 'contain' }} source={{ uri: this.state.store.LogoURL }} />
                            <View style={{ marginLeft: 15 }}>
                                <Text style={{ ...GlobalStyles.titleText }}>{this.state.store.Name}</Text>
                                <Text style={{ ...GlobalStyles.textBase, marginVertical: 6, marginBottom: 10 }}>{this.state.store.Description}</Text>
                                <View style={{ flexDirection: 'row', alignItems: 'center' }}>
                                    <View style={{ height: 12, width: 12, borderRadius: 9, backgroundColor: this.isStoreOpened() ? Colors.success : Colors.red }}></View>
                                    <Text style={{ ...GlobalStyles.textBase, marginLeft: 5, marginRight: 8 }}>{this.isStoreOpened() ? 'Loja aberta' : 'Somente entregas agendadas'}</Text>
                                </View>
                                <View style={{ marginLeft: 10 }}>
                                    <MyAccordionXP header={<View style={{ flexDirection: 'row', alignItems: 'center', padding: 8 }}>
                                        {this.renderDeliveryhoursForDay(todayDelivery)}
                                        <MaterialCommunityIcons name="chevron-down" size={16} style={{ marginBottom: 4 }} color={GlobalStyles.textBase.color} />
                                    </View>}>
                                        <View style={{ flexDirection: 'column', padding: 8 }}>
                                            {this.state.store.DeliveryTimes.map(x => this.renderDeliveryhoursForDay(x))}
                                        </View>
                                    </MyAccordionXP>
                                </View>
                            </View>
                        </View>
                    </View>
                    : 
                    // Pagina Home do Marketplace: mostra stories das marcas
                    <BrandsStories key='brandsstories' selectedBrand={this.state.selectedBrand}
                        onChangeBrand={(b) => {
                            if(b && b.Name) Api.Analytics.event(new Event("FilteredBrand", b.Name));
                            this.setState({ selectedBrand: b, openedCategoryId: 0, searchText: '' }, this.getProducts)
                        }} />
            }

            {/* BUSCA DE TEXTO E TAB BAR DE CATEGORIAS */}
            {this.state.selectedBrand ? <View><Text style={{ height: 1 }}> </Text></View> : // se ta com marca selecionada esconde a busca de texto e as tabs (por enquanto)
                <View>
                    {/* BUSCA TEXTO, FILTROS E ORDENACAO */}
                    <View style={{ padding: 12, backgroundColor: '#fff' }}>
                        {/* TEXT SEARCH */}
                        <View style={{ alignItems: 'center', alignSelf: 'center', maxWidth: Helpers.percentW(94), width: 420 }}>
                            <MySearchInput onChangeText={(q) => this.setState({ searchText: q }, () => { this.getProducts() })}
                                delay={250} style={{
                                    maxWidth: Helpers.percentW(94), width: 420, flex: 1, marginHorizontal: 18, paddingHorizontal: 6, borderWidth: 1, borderColor: Colors.darkLines,
                                    paddingVertical: 2, backgroundColor: '#fff', flexDirection: 'row', alignItems: 'center', borderRadius: 5, height: 38
                                }}
                            />
                        </View>
                    </View>

                    {/* TAB BAR CATEGORIAS */}
                    {this.state.categories.length > 0 &&
                        <ScrollViewXP horizontal wrapperClassName="coolScrollWrapper" style={{ height: 44, alignSelf: 'stretch', backgroundColor: Colors.bgColor, ...GlobalStyles.shadow3, shadowOffset: { width: 0, height: 3 } }}
                            contentContainerStyle={{ justifyContent: 'center', flexGrow: 1, alignItems: 'stretch' }}>
                            {[{ Id: 0, Name: 'Todos' }, ...this.state.categories].map(c => <TouchableOpacity key={c.Id}
                                // TAB CLICK
                                onPress={() => this.selectCategory(c.Id)}
                                style={[{ padding: 12, justifyContent: 'center', borderBottomWidth: 3 },
                                // SELECTED TAB
                                openedCategoryId == c.Id ? { borderBottomColor: Colors.themeColor } : { borderBottomColor: 'transparent' }
                                ]}>
                                <Text style={{
                                    ...GlobalStyles.textBaseBold, fontSize: 18,
                                    // SELECTED TAB
                                    color: openedCategoryId == c.Id ? Colors.themeColor : Colors.secondaryTextColor
                                }}>
                                    {c.Name}</Text>
                            </TouchableOpacity>)}
                        </ScrollViewXP>
                    }

                </View>
            }


            {/* HOME MOSTRANDO PRODUTOS EM GRID */}
            {
                (openedCategoryId || this.state.searchText || this.state.selectedBrand)
                    ?
                    /* PRODUCTS */
                    <View>
                        {/* TITULO DA SECAO  */}
                        <ViewportRenderer render={(viewport) => {
                            return <View style={{ flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', padding: 12, marginTop: 8 }}>
                                <View style={{ flexDirection: 'row', alignItems: 'center' }}>
                                    {this.state.selectedBrand && <TouchableOpacity onPress={() => this.setState({ selectedBrand: null })}>
                                        <Feather color={Colors.themeColor} size={20} name="arrow-left" style={{ paddingRight: 5 }} /></TouchableOpacity>}
                                    <Text style={{ ...GlobalStyles.titleText, fontSize: 24, color: Colors.themeColor }}>{sectionTitle}</Text>
                                    {this.state.loadingProducts && <ActivityIndicator color={Colors.themeColor} style={{ marginHorizontal: 12 }} />}
                                </View>
                                {/* ORDER BY */}
                                <View style={{
                                    maxWidth: Helpers.percentW(47), width: 250,
                                    flexDirection: 'row', justifyContent: 'center', alignItems: 'center',
                                }}>
                                    {viewport != ViewportNames.mobile &&
                                        <Text style={{ ...GlobalStyles.textBase, fontSize: 16 }}>Ordernar por</Text>
                                    }
                                    <DropdownXP selectedValue={this.state.orderBy}
                                        style={{
                                            ...GlobalStyles.textBase,
                                            height: 40, flex: 1, display: 'flex', flexDirection: 'row', borderWidth: 1, marginLeft: 5,
                                            borderStyle: 'solid', borderRadius: 4, borderColor: Colors.darkLines, padding: 8
                                        }}
                                        onValueChange={(itemValue) => this.setState({
                                            orderBy: itemValue
                                        }
                                            , () => this.getProducts())}
                                        data={[
                                            { label: "Destaques", value: "-Priority" },
                                            { label: "Nome A-Z", value: "Name" },
                                            { label: "Nome Z-A", value: "-Name" },
                                            { label: "Menor preço", value: "Price" },
                                            { label: "Maior preço", value: "-Price" },
                                        ]} />
                                </View>
                            </View>
                        }} />
                        <ViewportRenderer render={(viewport) => {
                            return <View style={{
                                display: 'flex',
                                flexDirection: 'row', flexWrap: 'wrap', //placeContent: 'center',
                                justifyContent: 'center', alignItems: 'flex-start', alignContent: 'flex-start',
                                backgroundColor: '#fff', marginBottom: 45, minHeight: Helpers.percentH(61)
                            }}
                            >
                                {/* PRODUCTS GRID */}
                                {this.state.products.map((p) =>
                                    <Product style={{ width: viewport == ViewportNames.mobile ? Helpers.percentW(50) - 25 : 200 }} key={p.Id} Product={p} onPress={() => { this.onPressProduct(p) }} />)}

                                {/* SEM PRODUTOS? */}
                                {!this.state.loadingProducts && (!this.state.products || this.state.products.length == 0) ?
                                    <View style={{ minHeight: Helpers.percentH(61), alignItems: 'center', justifyContent: 'center' }}>
                                        <Text style={{ ...GlobalStyles.textBaseBold, color: Colors.secondaryText }}>Nenhum produto encontrado</Text>
                                    </View>
                                    : null}

                                {/* BOTAO CARREGAR MAIS PRODUTOS */}
                                {this.state.hasMoreProdsToLoad && <View style={{ flex: 1, alignItems: 'center', alignSelf: 'stretch', minWidth: Helpers.percentW(92) }} key={-11}>
                                    <TouchableOpacity onPress={() => {
                                        //this.setState({ showingProds: this.state.showingProds + 40 });
                                        this.getProducts(false);
                                    }}>
                                        <View style={{
                                            flexDirection: 'column', backgroundColor: '#fff1f1',
                                            padding: 0, width: Helpers.Min(Helpers.percentW(100), 420), height: 40, borderRadius: 5,
                                            boxShadow: '0px 1px 5px #ddd', margin: 10, marginTop: 0
                                        }}>
                                            <Text style={{
                                                ...GlobalStyles.textBase,
                                                textAlign: 'center', alignSelf: 'center', marginTop: 10, paddingHorizontal: 6,
                                                fontWeight: 'bold', fontFamily: 'MontserratBold', color: Colors.themeColor
                                            }}>
                                                Carregar mais produtos
                                    </Text>
                                        </View>
                                    </TouchableOpacity>
                                </View>}
                            </View>
                        }} />
                    </View>
                    /* HOME LOADING E CAROUSEIS DE PRODUTOS */
                    :
                    (this.state.categories.length == 0 ?
                        <View style={{ margin: 120, alignItems: 'center', justifyContent: 'center' }}>
                            <ActivityIndicator color={Colors.themeColor} style={{ margin: 15 }} />
                            <Text style={{ ...GlobalStyles.textBase, fontSize: 16 }}>Carregando...</Text>
                        </View>
                        :
                        this.state.categories.map(c => <ProductsCarousel key={c.Id} arrowBgColor={Colors.themeColor}
                            title={<View style={{ flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', padding: 12 }}>
                                <Text style={{ ...GlobalStyles.titleText, fontSize: 24, color: Colors.themeColor }}>{c.Name}</Text>
                                <Link to={`${this.props.baseUrl}/categoria/${c.Id}`} style={{ color: 'transparent' }}>
                                    <View style={{ ...GlobalStyles.defaultButton, flexDirection: 'row', alignItems: 'center' }}>
                                        <Text style={{ fontSize: 14, ...GlobalStyles.defaultButtonText, textDecorationLine: 'none', paddingRight: 2, ...GlobalStyles.cleanLink }}>
                                            ver mais
                                        </Text>
                                        <MaterialCommunityIcons name="chevron-right" size={16} color={GlobalStyles.defaultButtonText.color} />
                                    </View>
                                </Link>
                            </View>}
                            products={c.Id in this.state.prodsByCategoryId ? this.state.prodsByCategoryId[c.Id] : []}
                            renderProduct={(p) =>
                                <Product Product={p} key={p.Id} onPress={() => { this.onPressProduct(p); }} />
                            }
                        />)
                    )
            }
            <Footer />
        </ScrollView >
    }
}

const BrandsStories = ({ selectedBrand, onChangeBrand }) => {
    const [brands, setBrands] = useState([]);
    const getAllBrands = async () => {
        console.log('get brands');
        try {
            var ret = await Api.GetBrands();
            if (ret.data && ret.data.length > 0)
                setBrands(ret.data);
        } catch {
            setBrands(null);
        }
    };
    useEffect(() => {
        getAllBrands()
    }, []);
    const w = 68
    var l = 2
    return brands == null || brands.length == 1 ? null : <View>
        {/* <Text style={{ ...GlobalStyles.titleText, fontSize: 24, color: Colors.themeColor }}>Destaques</Text> */}
        <ScrollViewXP horizontal wrapperClassName="coolScrollWrapper" style={{ height: w + 8, alignSelf: 'stretch', backgroundColor: Colors.bgColor, marginTop: 10 }}
            contentContainerStyle={{ justifyContent: 'center', flexGrow: 1, alignItems: 'stretch' }}>
            {brands.length > 0 ? brands.map(x => <TouchableOpacity key={x.Id} onPress={() => {
                onChangeBrand(x == selectedBrand ? null : x);
            }}>
                <View style={{
                    width: w, height: w, borderRadius: w / 2,
                    backgroundColor: selectedBrand && selectedBrand.Id == x.Id ? Colors.lines : Colors.themeColor,
                    justifyContent: 'center', alignItems: 'center', marginHorizontal: 3
                }}>
                    <View style={{
                        width: w - (l * 2), height: w - (l * 2),
                        borderRadius: w / 2, backgroundColor: Colors.lines, borderWidth: l, borderColor: '#fff',
                        justifyContent: 'center', alignItems: 'center', overflow: 'hidden'
                    }}>
                        {/* <Text>{x.Name}</Text> */}
                        <Image style={{ width: w - (l * 2), height: w - (l * 2), resizeMode: 'cover' }}
                            source={{ uri: x.LogoURL }} />
                    </View>
                </View>
            </TouchableOpacity>) : <View style={{ alignItems: 'center', justifyContent: 'center', height: 200, flex: 1 }}>
                    <ActivityIndicator color={Colors.themeColor} />
                </View>}
        </ScrollViewXP>
    </View>
}

const Footer = () => <ViewportRenderer render={(viewport) => {
    return <View style={{ alignSelf: 'stretch', marginBottom: 45, paddingVertical: 15, borderTopColor: Colors.lines, borderTopWidth: 1, alignItems: 'center' }}>
        <Image source={require('./../assets/LOGO_SOS_2.png')} style={{ height: 50, width: 125, resizeMode: 'contain', marginBottom: 0 }} />
        <View style={{ flexDirection: viewport == ViewportNames.mobile ? 'row' : 'row', paddingTop: 15 }}>
            <SocialNetworksItems hideLabels horizontal />
        </View>
    </View>
}} />
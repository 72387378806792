var themeColor = '#ae3027';// '#002e82'; //,
export default {
    bgColor: '#fff',
    themeColor: themeColor,
    lines: '#eeeeee',
    darkText: '#3f3e3e',
    secondaryText: '#717171',
    darkLines: '#bbb',
    success: '#87d068',
    greenStrong: '#00a650',
    textColor: '#3f3e3e',
    secondaryTextColor: 'rgba(0, 0, 0, 0.65)',
    red: '#f04134',
    headerText: themeColor,
    buttonText: '#fff',
}
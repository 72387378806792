import React, { useState, useEffect } from 'react';
import { SafeAreaView, ScrollView, Text, View, TouchableOpacity, Alert, Image } from 'react-native';
import Slideshow from './../components/SlideShow';
import ProductsCarousel from './../components/products/ProductsCarousel';
import { Link } from '../components/router';
import MySearchInput from '../components/MySearchInput';
import Helpers from './../utils/Helpers';
import Colors from '../constants/Colors';
import Api from '../api/Api';
import { ActivityIndicator } from 'react-native-paper';
import { Analytics, Event } from 'expo-analytics';
import Product from '../components/Product';
import GlobalStyles from '../constants/GlobalStyles';
import { Ionicons, MaterialCommunityIcons } from 'react-native-vector-icons';
import DropdownXP from '../components/DropdownXP';
import ScrollViewXP from '../components/ScrollViewXP';
import MyAccordion, { MyAccordionXP } from './../components/MyAccordion';
import { ViewportRenderer, ViewportNames } from './../components/ResponsiveView';
import { SocialNetworksItems } from '../components/Header';
import Feather from 'react-native-vector-icons/Feather';
import ProductRow from '../components/ProductRow';
import AlertXp from './../components/AlertXP';

export default class SelfService extends React.Component {

    constructor(props, context) {
        super(props);
        this.state = {
            products: [],
            quickCartProds: {

            },
            catProdsList: [],
            checkout: false,
            mesaId: props.params ? props.params.mesaId : 0,
        }
        try { // macete doido 
            if ((this.state.mesaId + '').indexOf('_') === 0) this.state.mesaId = atob(this.state.mesaId)
        } catch { }
    }

    componentDidMount() {
        this.getAllData(this.props);
    }

    componentWillReceiveProps(nextProps) {
        // debugger;
        // if (nextProps.params && nextProps.params.mesaId && nextProps.params.mesaId != this.state.mesaId) {
        //     this.setState({ mesaId: nextProps.params.mesaId })
        // }
    }

    getAllData = (props) => {
        this.getProducts(true, props.params);
    }

    getProducts = async (clear = true, params) => {
        // loja?
        //let storeAlias = (params || this.props.params).storeAlias || '';
        //let storeId = (params || this.props.params).storeId || 0;
        let storeId = this.state.store ? this.state.store.Id : 0;
        let catId = (params || this.props.params).catId || 0;
        if (clear) this.lastProductIndex = 0;
        this.setState({ loadingProducts: true })
        Api.Products.getProductsSelfService(0, storeId, 0, 0, '', null, 0, 1000).then(x => {
            var _products = clear ? x.data : [...this.state.products, ...x.data];
            if (Helpers.Any(_products, 'Category')) {
                var generatedCategories = Helpers.GroupBy(_products, 'Category');
                var auxArr = Helpers.ObjToArray(generatedCategories);
                var auxCats = auxArr.map(x => ({
                    Id: x[0].CategoryId,
                    Name: x[0].Category, Order: x[0].CategoryOrder,
                    Products: Helpers.OrderBy(x, 'Priority', true)
                }));
                var cats = Helpers.OrderBy(auxCats, 'Order');
            }

            var groupedProds = Helpers.GroupBy(_products, 'Category');
            this.setState({
                loadingProducts: false,
                hasMoreProdsToLoad: x.data.length == 50, // se voltar menos de 50 eh pq acabou a paginacao
                products: _products,
                catProdsList: cats,
                groupedProducts: groupedProds
            })

        }).catch(e => {

        });
    }

    onPressProduct = (p) => {
        this.props.history.push(`${this.props.url}/produto/${p.Id}`);
    }

    doConfirm = () => {
        var sale = {
            Customer: {
                Name: this.state.mesaId + "",
            },
            Products: Object.keys(this.state.quickCartProds).map(k => ({ Id: k, Quantity: this.state.quickCartProds[k] }))
        };
        this.setState({ checkoutLoading: true })
        Api.CheckoutSelfService(sale).then(x => {
            if (x && x.data && x.data.ok === true) {
                AlertXp.alert("Confirmado!", "Seu pedido foi recebido no balcão e você ja será atendido(a).")
                this.setState({ checkoutLoading: false, checkout: false, quickCartProds: {} })
            } else {
                throw { msg: -1 }
            }
        }).catch(e => {
            AlertXp.alert("Ops!", "ocorreu um erro...")
            this.setState({ checkoutLoading: false })
        })
    }

    render() {
        //console.log('PROPSSSSS:', this.props);
        let wBanner = Helpers.Min(Helpers.percentW(100), 1200);

        return <View style={{ flex: 1, backgroundColor: '#fff' }}>
            <ScrollView>
                <ViewportRenderer render={(viewport, screenWidth) => {
                    let containerStyle = {
                        minWidth: 260,
                        maxWidth: 540,
                        width: screenWidth - 30
                    };
                    return <View style={{
                        display: 'flex',
                        //flexDirection: 'row', flexWrap: 'wrap', //placeContent: 'center',
                        justifyContent: 'flex-start', alignItems: 'center',// alignContent: 'flex-start',
                        backgroundColor: '#fff', marginBottom: 55, //minHeight: Helpers.percentH(30)
                    }}
                    >
                        <View style={{ flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', alignSelf: 'center', ...containerStyle, marginVertical: 15 }}>
                            <Text style={{ ...GlobalStyles.titleText, fontSize: 24, color: Colors.themeColor }}>
                                {this.state.checkout ? 'Novo pedido' : 'Cardápio'}
                            </Text>
                            {!this.state.mesaId ? <View></View> :
                                <View style={{ flexDirection: 'row', alignItems: 'center' }}>
                                    <MaterialCommunityIcons name="file-document-edit-outline" color={Colors.themeColor} size={22} style={{ marginRight: 5 }} />
                                    <Text style={{ ...GlobalStyles.titleText, fontSize: 20, color: Colors.themeColor }}>{this.state.mesaId}</Text>
                                </View>}
                        </View>

                        <View style={{ ...containerStyle, marginVertical: 10 }}>
                            <Text style={{ ...GlobalStyles.textBase, fontSize: 12, textAlign: 'justify' }}>
                                <Text style={GlobalStyles.textBaseBold}>Bebidas: Todas as nossas bebidas* vem acompanhadas de um chocolate</Text> para adoçar seu dia!
                                Você pode escolher: <Text style={GlobalStyles.textBaseBold}>Biscoito de Leite coberto com Chocolate</Text> ou
                                <Text style={GlobalStyles.textBaseBold}> Trufa (ao Leite ou Zero Açúcar)</Text>
                                {'\n'}* Exceto para refrigerantes, água, mate e chá gelado
                                {'\n\n'}<Text style={GlobalStyles.textBaseBold}>Salgados:</Text> Todos os nossos salgados são preparados na hora e levam de 5 a 7 minutos para ficarem prontos.
                            </Text>
                        </View>

                        {/* PRODUCTS GRID */}
                        {this.state.catProdsList ? this.state.catProdsList.map(kat => (
                            this.state.checkout && kat.Products.filter(p => this.state.quickCartProds[p.Id]).length == 0 ?
                                null
                                :
                                <View key={kat.Name}>
                                    <MyAccordionXP header={(opened) => (
                                        <View style={{ flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}>
                                            <Text style={{ ...GlobalStyles.titleText, marginVertical: 10, fontSize: 20, paddingLeft: 4, color: Colors.darkText }}>{kat.Name}</Text>
                                            <MaterialCommunityIcons name={opened ? "chevron-up" : "chevron-down"} color={Colors.darkText} size={32} style={{ marginRight: 5 }} />
                                        </View>
                                    )}>
                                        {kat.Products.map((prod) => {
                                            let qty = this.state.quickCartProds[prod.Id] || 0;
                                            return ((this.state.checkout && qty > 0) || !this.state.checkout ?
                                                <ProductRow key={prod.Id} showPriceOnEveryRow hideImage
                                                    style={{ ...containerStyle }}
                                                    onOpenProduct={(p) => { }}
                                                    setProductQty={(prodQtd) => {
                                                        if (prodQtd.Quantity == 0) {
                                                            var qProds = { ...this.state.quickCartProds }
                                                            delete qProds[prod.Id];
                                                            this.setState({ quickCartProds: qProds });
                                                        } else {
                                                            this.setState({
                                                                quickCartProds: { ...this.state.quickCartProds, [prodQtd.Id]: prodQtd.Quantity }
                                                            })
                                                        }
                                                    }}
                                                    outOfStock={prod.QtyInStock <= 0 && prod.TrackStock}
                                                    reviewMode={this.state.checkout || !this.state.mesaId}
                                                    prod={{ ...prod, Quantity: qty }}
                                                /> : null)
                                        })
                                        }
                                    </MyAccordionXP>
                                </View>
                        ))
                            : null
                        }

                        {/* SEM PRODUTOS? */}
                        {!this.state.loadingProducts && (!this.state.products || this.state.products.length == 0) ?
                            <View style={{ minHeight: Helpers.percentH(61), alignItems: 'center', justifyContent: 'center' }}>
                                <Text style={{ ...GlobalStyles.textBaseBold, color: Colors.secondaryText }}>Nenhum produto encontrado</Text>
                            </View>
                            : null}

                        {/* LOADING */}
                        {this.state.loadingProducts && <ActivityIndicator color={Colors.themeColor} style={{ margin: 60 }} />}

                    </View>
                }} />

                {this.state.checkout && // FECHAMENTO DE PEDIDO:
                    <SafeAreaView style={{
                        flexDirection: 'row', justifyContent: 'center', alignItems: 'center',
                        //position: 'fixed', bottom: 0, paddingVertical: 12, left: 0, right: 0, 
                        //backgroundColor: '#fff', borderTopColor: Colors.themeColor, borderTopWidth: 2
                    }}>
                        <TouchableOpacity style={{ ...GlobalStyles.defaultButtonInverted, ...GlobalStyles.bigButton, marginRight: 25 }}
                            onPress={() => this.setState({ checkout: false })}>
                            <Text style={{ ...GlobalStyles.defaultButtonTextInverted, fontSize: 20 }}>Voltar</Text>
                        </TouchableOpacity>
                        <TouchableOpacity style={{ ...GlobalStyles.defaultButton, ...GlobalStyles.bigButton }}
                            onPress={() => this.doConfirm()}>
                            {
                                this.state.checkoutLoading ?
                                    <ActivityIndicator color={GlobalStyles.defaultButtonText.color} style={{ paddingHorizontal: 40 }} />
                                    :
                                    <Text style={{ ...GlobalStyles.defaultButtonText, fontSize: 20 }}>Confirmar pedido</Text>
                            }
                        </TouchableOpacity>
                    </SafeAreaView>
                }
            </ScrollView>

            {Helpers.Any(Helpers.ObjToArray(this.state.quickCartProds)) && !this.state.checkout &&
                <SafeAreaView style={{
                    position: 'fixed', bottom: 0, paddingVertical: 12, left: 0, right: 0, alignItems: 'center',
                    backgroundColor: '#fff', borderTopColor: Colors.themeColor, borderTopWidth: 2
                }}>
                    <TouchableOpacity style={{ ...GlobalStyles.defaultButton, ...GlobalStyles.bigButton }}
                        onPress={() => this.setState({ checkout: true })}>
                        <Text style={{ ...GlobalStyles.defaultButtonText, fontSize: 20 }}>Fazer pedido</Text>
                    </TouchableOpacity>
                </SafeAreaView>
            }

        </View>
    }
}

const Footer = () => <ViewportRenderer render={(viewport) => {
    return <View style={{ alignSelf: 'stretch', marginBottom: 45, paddingVertical: 15, borderTopColor: Colors.lines, borderTopWidth: 1, alignItems: 'center' }}>
        <Image source={require('./../assets/LOGO_SOS_2.png')} style={{ height: 50, width: 125, resizeMode: 'contain', marginBottom: 0 }} />
        <View style={{ flexDirection: viewport == ViewportNames.mobile ? 'row' : 'row', paddingTop: 15 }}>
            <SocialNetworksItems hideLabels horizontal />
        </View>
    </View>
}} />
import React, { useState, useEffect } from 'react';
import { View, Dimensions } from 'react-native';

export const ViewportNames = {
  mobile: 'mobile',
  tablet: 'tablet',
  desktop: 'desktop'
}
export const getViewportName = width =>
  width < 768 ? ViewportNames.mobile : width < 992 ? ViewportNames.tablet : ViewportNames.desktop;

export default class ResponsiveView extends React.Component {
  constructor(props) {
    super(props);
    var _window = Dimensions.get('window');
    var cview = getViewportName();
    this.state = {
      windowWidth: _window.width,
      ...this.getScreenStateFromViewport(cview),
    };
    Dimensions.addEventListener('change', e => {
      const { width, height } = e.window;
      if (this.state.lastWindowWidth != width) {
        this.setState({
          ...this.getScreenStateFromViewport(getViewportName(width)),
          windowWidth: width,
        });
      }
    });
    if (props.myref) props.myref(this);
  }
  getScreenStateFromViewport = currentViewport => {
    return { currentViewport: currentViewport };
  };
  render() {
    var ww = this.state.windowWidth;
    var viewport = this.state.currentViewport;
    if (
      (this.props && this.props.minWidth !== null
        ? ww >= this.props.minWidth
        : true) &&
      (this.props && this.props.maxWidth !== null
        ? ww <= this.props.maxWidth
        : true)
    ) {
      return <View {...this.props}>{this.props.children}</View>;
    } else {
      return null;
    }
  }
}

export const MobileView = props => (
  <ResponsiveView minWidth={0} maxWidth={768} {...props}>
    {props.children}
  </ResponsiveView>
);
export const TabletView = props => (
  <ResponsiveView minWidth={769} maxWidth={992} {...props}>
    {props.children}
  </ResponsiveView>
);
export const DesktopView = props => (
  <ResponsiveView minWidth={992} maxWidth={null} {...props}>
    {props.children}
  </ResponsiveView>
);

export const ViewportRenderer = props => {
  const [screenWidth, setScreenWidth] = useState(Dimensions.get('window').width);
  useEffect(() => {
    const onChange = result => {
      setScreenWidth(result.window.width);
    };
    Dimensions.addEventListener('change', onChange);
    return () => Dimensions.removeEventListener('change', onChange);
  });
  return props.render(getViewportName(screenWidth), screenWidth);
}

export class ViewportRendererReact extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      screenWidth: Dimensions.get('window').width
    }
  }
  componentDidMount() {
    Dimensions.addEventListener('change', this.onChangeDimensions);
  }
  componentWillUnmount() {
    Dimensions.removeEventListener('change', this.onChange)
  }
  onChangeDimensions = (result) => {
    this.setState({ screenWidth: result.window.width });
  }
  render() {
    return this.props.render(getViewportName(this.state.screenWidth), this.state.screenWidth);
  }
}
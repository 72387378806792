const SET_PRODUCTS = 'SET_PRODUCTS'

export const setProducts = prods => ({
  type: SET_PRODUCTS,
  payload: prods
})

const initialState = {
  products: []
}

export function catalogReducer(state = initialState, action) {
  const { type, payload } = action

  switch (type) {
    case SET_PRODUCTS:
      var newState = {...state};
      newState.products = payload;
      return newState;
    default:
      return state
  }
}

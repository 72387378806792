import React from 'react';
import { Text, View, StyleSheet } from 'react-native';
import ProductItem from './ProductItem'
import { ProductsScrollView } from './ProductsScrollView';

export default function ProductsCarousel(props) {
  return (
    <View style={{ marginVertical: 8, ...props.style}}>
      {typeof props.title === 'string' ? <Text>{props.title}</Text> : props.title}
      <ProductsScrollView style={styles.row} arrowBgColor={props.arrowBgColor}>
        {props.products && props.products.map((p,i) => props.renderProduct ? props.renderProduct(p) : <ProductItem key={p.Id} product={p} />)}
      </ProductsScrollView>
    </View>
  );
}

const styles = StyleSheet.create({
  row: {
    //borderWidth: 1, 
  },
  innerList: {
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
  }
});
